import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";

const ConfirmModal = (props) => {
  const {
    data: { text, handleSubmit, handleCancel },
    show,
    children,
  } = props;

  return (
    <React.Fragment>
      <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <h1>Confirm</h1>
        </Modal.Header>
        <Modal.Body>
          <Stack gap="2">
            <span>{text}</span>
            {children}
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup className="mt-5">
            <Button variant="primary" size="lg" onClick={handleSubmit}>
              CONFIRM
            </Button>
            <Button variant="danger" size="lg" onClick={handleCancel}>
              CANCEL
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmModal;
export const ConfirmModalHelper = (function () {
  const name = "confirm";

  const empty = {
    text: "",
    handleSubmit: () => {},
    handleCancel: () => {},
  };

  const confirmModalDataImpl = (text, handleSubmit, handleCancel) => {
    return {
      text,
      handleSubmit,
      handleCancel,
    };
  };

  const handleCancelImpl = (setState) => {
    setState({ modal: null, confirmModalData: empty });
  };

  const handleShowConfirmImpl = (setState, text, handleSubmit, handleCancel) => {
    setState({
      modal: name,
      confirmModalData: confirmModalDataImpl(
        text,
        () => {
          handleCancelImpl(setState);
          handleSubmit();
        },
        () => {
          handleCancelImpl(setState);
          if (handleCancel) handleCancel();
        }
      ),
    });
  };

  return {
    Name: name,
    Empty: empty,
    confirmModalData: confirmModalDataImpl,
    handleCancel: handleCancelImpl,
    handleShowConfirm: handleShowConfirmImpl,
  };
})();
