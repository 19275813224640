import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import FadeIn from "react-fade-in";

import { DualProgressBar3 } from "./progressBars";
import { Number, Text, Trophy } from "./utils";

class TournamentPage extends React.Component {
  state = {};

  render() {
    const {
      page,
      data: { count, matches, biggestWin, biggestLoss, nemesis, trophies },
      type,
      fadeTimeout,
    } = this.props;

    const optionalRows = [];

    if (biggestWin) {
      optionalRows.push(
        <Row className="mt-3" key="biggestWin">
          <Col>
            Biggest Win: {<Number value={biggestWin.val} />} vs {<Text value={biggestWin.name} />}
          </Col>
        </Row>
      );
    }

    if (biggestLoss) {
      optionalRows.push(
        <Row className="mt-3" key="biggestLoss">
          <Col>
            Biggest Lesson Learnt: {<Number value={biggestLoss.val} />} by {<Text value={biggestLoss.name} />}
          </Col>
        </Row>
      );
    }

    if (nemesis) {
      optionalRows.push(
        <React.Fragment key="nemesis">
          <Row className="mt-3">
            <Col>
              Nemesis: {<Text value={nemesis.name} />}, played {<Number value={nemesis.outcome.refVal} />} times
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              Won {<Number value={nemesis.outcome.val1} />} and lost {<Number value={nemesis.outcome.val2} />}
              <DualProgressBar3 value={nemesis.outcome} />
            </Col>
          </Row>
        </React.Fragment>
      );
    }

    if (trophies && trophies.length > 0) {
      optionalRows.push(
        <Row className="mt-3" key="medals">
          <Col>
            Medals won:
            {trophies.map((t) => (
              <Trophy key={t.name} {...t} />
            ))}
          </Col>
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Container className="mt-5">
          <Row>
            <Col>
              <h1>{type} Tournaments</h1>
            </Col>
          </Row>
          <FadeIn delay={fadeTimeout} key={page}>
            <Row className="mt-4">
              <Col>Participated in {<Number value={count} />}</Col>
            </Row>
            <Row className="mt-3">
              <Col>Played {<Number value={matches.refVal} />} matches in total</Col>
            </Row>
            <Row className="mt-3">
              <Col>
                Won {<Number value={matches.val1} />} and lost {<Number value={matches.val2} />}
                <DualProgressBar3 value={matches} />
              </Col>
            </Row>
            {optionalRows.map((r) => r)}
          </FadeIn>
        </Container>
      </React.Fragment>
    );
  }
}

export default TournamentPage;
