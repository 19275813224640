import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import MainMenu from "../components/mainMenu";

const Offline = () => {
  return (
    <React.Fragment>
      <MainMenu />
      <Container fluid="md">
        <Row>
          <Col>
            <h1>Offline</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>You seem to be offline, please check your wifi or mobile connection.</h3>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Offline;
