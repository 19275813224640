import React from "react";
import MainMenu from "../../components/mainMenu";
import { useQueryParams } from "../../common/queryParams";
import ProcessingModal from "../../common/processingModal";
import { Helmet } from "react-helmet-async";
import Find from "./find";
import { Page } from "./joinLinkBuilder";
import Request from "./request";
import StripeSettingsContext from "../../contexts/stripeContext";
import { withSettings } from "../../common/withSettings";
import { loadStripe } from "@stripe/stripe-js";
import Join from "./join";

class JoinApp extends React.Component {
  state = {
    processing: false,
    isLoading: true,

    stripePromise: null,
  };

  async componentDidMount() {
    this.setState({ isLoading: false });

    const { stripePublishableKey } = this.props.settings;
    const stripePromise = await loadStripe(stripePublishableKey);

    this.setState({ stripePromise });
  }

  render() {
    const { loading, processing, stripePromise } = this.state;

    return (
      <StripeSettingsContext.Provider value={stripePromise}>
        <Helmet>
          <title>Find a league to join - ATS</title>
        </Helmet>
        <MainMenu hideMenu={true} />
        <GetPage loading={loading} processing={processing} />
      </StripeSettingsContext.Provider>
    );
  }
}

function GetPage(props) {
  const { loading, processing } = props;
  const queryParams = useQueryParams();

  if (processing || loading) return <ProcessingModal text="Loading..." />;

  if ("page" in queryParams) {
    if (queryParams.page === Page.Find) return <Find postcode={queryParams.postcode} searchId={queryParams.searchId} />;
    if (queryParams.page === Page.Request) return <Request data={queryParams} />;
    if (queryParams.page === Page.Join) return <Join data={queryParams} />;
  }

  return <Find postcode={queryParams.postcode} searchId={queryParams.searchId} />;
}

export default withSettings(JoinApp);
