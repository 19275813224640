import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import _ from "lodash";

import { toast } from "react-toastify";

import EventsApi from "../../api/eventsApi";
import ScoreApi from "../../api/scoreApi";

import { handleCopyNo, handleCopyAz } from "../../common/copyRegistrations";

import EventInfo from "./eventInfo";
import EventRegistrations from "./eventRegistrations";
import EventStatus from "./eventStatus";
import Matches from "./matches";

class Championship extends React.Component {
  state = {
    loaded: false,

    state: "",
    startDate: new Date().toDateString(),
    endDate: new Date().toDateString(),

    groupStatuses: [],
    matches: [],

    plannedEventRegistrations: [],
    registrationFields: [],
  };

  async componentDidMount() {
    await this.refreshData();
  }

  refreshData = async () => {
    const { eventId, plannedEventId } = this.props;
    const {
      data: { state, startDate, endDate, plannedEventRegistrations, registrationFields },
    } = await EventsApi.getEvent(eventId, plannedEventId);

    let groupStatuses = [];
    let matches = [];

    if (eventId) {
      const { data } = await EventsApi.getEventStatus(eventId);
      groupStatuses = data.groupStatuses;
      matches = data.matches;
    }

    this.setState({
      loaded: true,

      state,
      startDate,
      endDate,

      groupStatuses,
      matches,

      plannedEventRegistrations,
      registrationFields,
    });
  };

  handleDeleteRegistration = async (registrationId) => {
    const { plannedEventRegistrations } = this.state;
    const { success } = await EventsApi.unregister(registrationId);

    if (success) {
      _.remove(plannedEventRegistrations, (pe) => pe.id === registrationId);

      this.setState({ plannedEventRegistrations });
      this.refreshData();
    } else {
      toast.error("Unable to delete registration, please try again...");
    }
  };

  handleSetMatchScore = async (matchId, score) => {
    const gameScores = score.trim().split(" ");

    if (gameScores.length !== 2 && gameScores.length !== 3) {
      toast.error("Invalid score");
      return;
    }

    const request = { matchId, set1: gameScores[0], set2: gameScores[1] };
    if (gameScores.length === 3) request.tieBreak = gameScores[2];

    const updatedMatches = _.map(this.state.matches, (m) => {
      if (m.Id === matchId) {
        return { id: m.Id, group: m.group, side1: m.side1, side2: m.side2, score: score, scoreTypeId: m.scoreTypeId };
      } else {
        return m;
      }
    });
    this.setState({ matches: updatedMatches });

    const toastId = toast.info("Updating score...", { autoClose: false });
    const { success, message } = await ScoreApi.setScoreOld(request);

    if (success) {
      toast.update(toastId, { render: "Score updated", autoClose: 5000, type: toast.TYPE.SUCCESS });
    } else {
      toast.update(toastId, { render: message, autoClose: 5000, type: toast.TYPE.ERROR });
    }

    await this.refreshData();
  };

  handleSpecialScore = async (matchId, action, score, description) => {
    const updatedMatches = _.map(this.state.matches, (m) => {
      if (m.id === matchId) {
        return { id: m.Id, group: m.group, side1: m.side1, side2: m.side2, score: score, scoreTypeId: m.scoreTypeId };
      } else {
        return m;
      }
    });
    this.setState({ matches: updatedMatches });

    const toastId = toast.info(`${description}...`, { autoClose: false });
    const { success, message } = await action(matchId);

    if (success) {
      toast.update(toastId, { render: `${description}...done`, autoClose: 5000, type: toast.TYPE.SUCCESS });
    } else {
      toast.update(toastId, { render: message, autoClose: 5000, type: toast.TYPE.ERROR });
    }

    await this.refreshData();
  };

  render() {
    const {
      loaded,

      state,
      startDate,
      endDate,

      groupStatuses,
      matches,

      plannedEventRegistrations,
      registrationFields,
    } = this.state;

    const canDeleteRegistrations = state.includes("Registration");

    return (
      <>
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h1>{this.props.name}</h1>
            </Col>
          </Row>
          {!loaded && <Spinner animation="border" role="status" />}
          {loaded && (
            <Row className="justify-content-flex-start">
              <Col lg="auto">
                <EventInfo
                  state={state}
                  startDate={startDate}
                  endDate={endDate}
                  participantCount={plannedEventRegistrations.length}
                  onCopyNo={() => handleCopyNo(plannedEventRegistrations)}
                  onCopyAz={() => handleCopyAz(plannedEventRegistrations)}
                />
              </Col>
            </Row>
          )}
        </Container>
        <Container>
          <Row className="justify-content-flex-start">
            <Col lg="auto">{groupStatuses.length > 0 && <EventStatus groupStatuses={groupStatuses} />}</Col>
          </Row>
        </Container>
        <Container>
          {plannedEventRegistrations.length > 0 && (
            <Row>
              <Col>
                <EventRegistrations
                  onDelete={canDeleteRegistrations ? this.handleDeleteRegistration : null}
                  plannedEventRegistrations={plannedEventRegistrations}
                  registrationFields={registrationFields}
                />
              </Col>
            </Row>
          )}
        </Container>
        <Container>
          {matches.length > 0 && (
            <Row>
              <Col>
                <Matches
                  matches={matches}
                  onSetScore={this.handleSetMatchScore}
                  onCoinToss={(matchId) => this.handleSpecialScore(matchId, ScoreApi.coinToss, "WIN or LOSS", "Tossing a coin")}
                  onDefaultOne={(matchId) => this.handleSpecialScore(matchId, ScoreApi.defaultOne, "DEF", "Defaulting the match")}
                  onLossInjury={(matchId) => this.handleSpecialScore(matchId, ScoreApi.lossInjury, "LOSS INJURY", "Setting as Loss by injury")}
                  onLossConcede={(matchId) => this.handleSpecialScore(matchId, ScoreApi.lossConcede, "LOSS CONCEDE", "Setting as Loss by conceding")}
                  onLossNoShow={(matchId) => this.handleSpecialScore(matchId, ScoreApi.lossNoShow, "LOSS NO SHOW", "Setting as Loss bt no show")}
                  onWinInjury={(matchId) => this.handleSpecialScore(matchId, ScoreApi.winInjury, "WIN INJURY", "Setting as Win by Injury")}
                  onWinConcede={(matchId) => this.handleSpecialScore(matchId, ScoreApi.winConcede, "WIN CONCEDE", "Setting as Win by conceding")}
                  onWinNoShow={(matchId) => this.handleSpecialScore(matchId, ScoreApi.winNoShow, "WIN NO SHOW", "Setting as Win by no show")}
                  onDefault={this.handleDefaultMatch}
                />
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

export default Championship;
