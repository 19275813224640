import React, { Component } from "react";

import _ from "lodash";

import { getSideName } from "../../common/sideNameBuilder";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

class Matches extends Component {
  render() {
    const { twoColumn, matches } = this.props;

    const roundMatches = _.chain(matches)
      .groupBy("round")
      .map((value, key) => ({ round: key, matches: value }))
      .orderBy("round", "desc")
      .value();

    let matchesColumns = [roundMatches];
    if (twoColumn) {
      if (roundMatches.length > 1) matchesColumns = [[roundMatches[0]], [roundMatches[1]]];
      else if (roundMatches.length > 0) matchesColumns = [[roundMatches[0]]];
      else matchesColumns = [];
    }

    return (
      <React.Fragment>
        <Row>
          <Col>
            <h2 className="text-center">Matches:</h2>
          </Col>
        </Row>
        <Row>
          {matchesColumns.map((sc, i) => (
            <Col key={i}>
              <GetMatchesColumn roundMatches={sc} />
            </Col>
          ))}
        </Row>
      </React.Fragment>
    );
  }
}

function GetMatchesColumn(props) {
  return (
    <table className="table table-hover table-sm">
      <tbody>
        {props.roundMatches.map((roundMatch) => {
          return (
            <React.Fragment key={roundMatch.round}>
              <tr key={roundMatch.round}>
                <th colSpan="4" className="text-center table-primary">
                  Round {roundMatch.round} matches
                </th>
              </tr>
              {roundMatch.matches.map((m) => {
                return (
                  <tr key={m.matchId}>
                    <th>{m.court}:</th>
                    <td className="text-end">{getSideName(m.side1)}</td>
                    <td className="text-center align-middle">{m.score || "vs"}</td>
                    <td className="text-start">{getSideName(m.side2)}</td>
                  </tr>
                );
              })}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

export default Matches;
