import React from "react";

import Table from "react-bootstrap/Table";
import Stack from "react-bootstrap/Stack";

class LeagueFields extends React.Component {
  render() {
    const { name, address, courts, originalPrice, price, discount, leagueName, players, startDate, endDate, registerByDate, registered } = this.props;

    return (
      <Table striped>
        <tbody>
          <tr>
            <td className="fw-bold">Club</td>
            <td>
              <Stack gap={1}>
                <div className="fw-bold"> {name}</div>
                <div>{address}</div>
                <div>{courts}</div>
              </Stack>
            </td>
          </tr>
          <tr>
            <td className="fw-bold">League Name</td>
            <td>{leagueName}</td>
          </tr>
          <tr>
            <td className="fw-bold">Level of Play</td>
            <td>Improver to Advance</td>
          </tr>
          {players > 0 && (
            <tr>
              <td className="fw-bold">Players</td>
              <td>{players}</td>
            </tr>
          )}
          <tr>
            <td className="fw-bold">Start Date</td>
            <td>
              {startDate.toLocaleTimeString()} {startDate.toLocaleDateString()}
            </td>
          </tr>
          <tr>
            <td className="fw-bold">End Date</td>
            <td>
              {endDate.toLocaleTimeString()} {endDate.toLocaleDateString()}
            </td>
          </tr>
          <tr>
            <td className="fw-bold">Register&nbsp;By&nbsp;Date</td>
            <td>
              {registerByDate.toLocaleTimeString()} {registerByDate.toLocaleDateString()}
            </td>
          </tr>
          {!registered && (
            <React.Fragment>
              <tr>
                <td className="fw-bold">Price</td>
                <td>
                  <GetPrice originalPrice={originalPrice} price={price} />
                </td>
              </tr>
              <tr>
                <td className="fw-bold">Discount</td>
                <td>Lifetime discount of {discount * 100}% for all ATS events in this club</td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </Table>
    );
  }
}

function GetPrice(props) {
  const { originalPrice, price } = props;

  if (originalPrice === price) {
    return <span>£{price}</span>;
  }

  return (
    <React.Fragment>
      <span className="text-decoration-line-through text-danger">£{originalPrice}</span> £{price}
    </React.Fragment>
  );
}

export default LeagueFields;
