import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

const MenuCard = (props) => {
  const { title, text, url } = props;
  return (
    <Col className="border border-primary m-0 pt-2">
      <Card border="light">
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{text}</Card.Text>
          <Button variant="primary" href={url}>
            Open
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default MenuCard;
