import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export function FieldRow(props) {
  const { id, label, children } = props;

  return (
    <tr key={id}>
      <td className="fw-bold">{label}</td>
      <td>{children}</td>
    </tr>
  );
}

export function Section(props) {
  const { id, label, children } = props;

  return (
    <React.Fragment>
      <Row className="mt-2" key={`${id}-label`}>
        <Col className="fw-bold">{label}</Col>
      </Row>
      <Row className="mt-2" key={`${id}-value`}>
        <Col>{children}</Col>
      </Row>
    </React.Fragment>
  );
}

export function getVariantForMessageType(type) {
  switch (type) {
    case "Info":
    case "Confirm":
      return "success";
    case "Warning":
      return "warning";
    case "Error":
      return "danger";
    default:
      return "";
  }
}
