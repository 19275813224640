import { PlayerApi } from "./atsApi";

class TournamentApi {
  constructor() {
    this.api = new PlayerApi("tournament");
  }

  getTournaments = () => this.api.getRequest("");

  getTournamentStaticData = (eventId) => this.api.getRequest(`${eventId}/static-data`);

  getTournament = (eventId, version) => this.api.getRequest(`${eventId}/${version}`);

  postNewRound = (eventId, data) => this.api.postRequest(`${eventId}/new-round`, data);

  postNewScore = (eventId, data) => this.api.postRequest(`${eventId}/new-score`, data);
}

const tournamentApi = new TournamentApi();
export default tournamentApi;
