export function getLink(link) {
  return `${process.env.PUBLIC_URL}/${link ?? ""}`;
}

export function getAppLink(app) {
  return `${process.env.PUBLIC_URL}/?app=${app}`;
}

export function getPageLink(app, page, args) {
  return `${process.env.PUBLIC_URL}/?app=${app}&page=${page}${Object.keys(args ?? {})
    .map((a) => `${a}=${args[a]}`)
    .reduce((a, c) => `${a}&${c}`, "")}`;
}
