import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { Helmet } from "react-helmet-async";

import MainMenu from "../../components/mainMenu";
import Register from "../../common/register";

class PlayerLoginApp extends React.Component {
  render() {
    const { inPlace = false } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>Sign In - ATS</title>
        </Helmet>
        <MainMenu />
        <Container fluid="md">
          <Row>
            <Col>
              <h1>Sign In</h1>
              <Register inPlace={inPlace} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default PlayerLoginApp;
