import React from "react";

import { useQueryParams } from "../../common/queryParams";

import MainMenu from "../../components/mainMenu";

import { toast } from "react-toastify";

import _ from "lodash";

import Match from "./match";
import MatchSelector from "./matchSelector";
import { LinkBuilder, Page } from "./rallyRecordLinkBuilder";
import { isAuthenticated } from "../../common/auth";
import ParentLogin from "./parentLogin";
import RallyRecordApi from "../../api/rallyRecordApi";
import ChildEdit from "./childEdit";
import ChildSelector from "./childSelector";
import MatchEdit from "./matchEdit";

import ProcessingModal from "../../common/processingModal";

class RallyRecordApp extends React.Component {
  state = {
    processing: false,
    isLoading: true,

    children: [],
  };

  async componentDidMount() {
    if (!isAuthenticated()) return;

    this.setState({ processing: true });

    const { success, data: children, message } = await RallyRecordApi.getChildren();

    if (!success) {
      this.setState({ processing: false, isLoading: false });
      toast.error(message);
      return;
    }

    this.setState({ children, processing: false, isLoading: false });
  }

  render() {
    const menuItems = [
      { key: "matches", title: "Matches", url: LinkBuilder.getMatchesLink() },
      { key: "analytics", title: "Analytics", url: LinkBuilder.getAnalyticsLink() },
    ];

    const { children, loading, processing } = this.state;

    return (
      <React.Fragment>
        <MainMenu appItems={menuItems} hideMenu={true} />
        <GetPage children={children} loading={loading} processing={processing} />
      </React.Fragment>
    );
  }
}

function GetPage(props) {
  const { children, loading, processing } = props;
  const queryParams = useQueryParams();

  if (processing || loading) return <ProcessingModal text="Loading..." />;

  if (!isAuthenticated()) return <ParentLogin />;

  if (children.length === 0) return <ChildEdit />;
  if (children.length > 1 && !queryParams.childId) return <ChildSelector children={children} />;

  const childId = children.length > 1 ? queryParams.childId : children[0].id;
  const child = _.find(children, (c) => c.id === childId);

  if ("page" in queryParams) {
    if (queryParams.page === Page.Match) return <Match childId={child.id} />;
    if (queryParams.page === Page.Matches) return <MatchSelector child={child} />;
    if (queryParams.page === Page.MatchEdit) return <MatchEdit />;
  }

  return <MatchSelector child={child} />;
}

export default RallyRecordApp;
