import { getPageLink } from "../../common/appLinkBuilder";

const appName = "find-opponent";

export const Page = {
  FindPlayer: "findPlayer",
  FindMatch: "findMatch",
  MySlots: "mySlots",
  MySlot: "mySlot",
  MyMatches: "myMatches",
  MyMatch: "myMatch",
};

class FindOpponentLinkBuilder {
  getFindPlayerLink = () => getPageLink(appName, Page.FindPlayer);
  getFindMatchLink = () => getPageLink(appName, Page.FindMatch);
  getMySlotsLink = () => getPageLink(appName, Page.MySlots);
  getMyMatchesLink = () => getPageLink(appName, Page.MyMatches);

  getMySlotLink = (availablePlayerId) => getPageLink(appName, Page.MySlot, { availablePlayerId: availablePlayerId });
  getMyMatchLink = (availableMatchId) => getPageLink(appName, Page.MyMatch, { availableMatchId: availableMatchId });
}

export const LinkBuilder = new FindOpponentLinkBuilder();
