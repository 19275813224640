import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { toast } from "react-toastify";

import FindOpponentApi from "../../api/findOpponentApi";

import { withStaticData } from "../../common/staticData";
import { LinkBuilder } from "./findOpponentLinkBuilder";

class MySlot extends React.Component {
  state = {
    mySlot: {
      availablePlayerId: "",
      currentState: "Active",
      matchTypeId: "",
      dayOfWeekId: "",
      time: "",
      timeToleranceId: "",
      courtTypeId: "",
      courtSurfaceId: "",
    },
  };

  async componentDidMount() {
    const { availablePlayerId } = this.props;

    if (!availablePlayerId) return;

    const {
      success,
      data: { currentState, matchTypeId, dayOfWeekId, time, timeToleranceId, courtTypeId, courtSurfaceId },
      message,
    } = await FindOpponentApi.getMySlot(availablePlayerId);

    if (!success) {
      toast.error(message);
      return;
    }

    this.setState({ mySlot: { availablePlayerId, currentState, matchTypeId, dayOfWeekId, time, timeToleranceId, courtTypeId, courtSurfaceId } });
  }

  handleChange = (e) => {
    const mySlot = { ...this.state.mySlot };
    mySlot[e.currentTarget.id] = e.currentTarget.value;
    this.setState({ mySlot });
  };

  handleSubmit = async () => {
    const { mySlot } = this.state;

    const { success, data, message } = await FindOpponentApi.setMySlot(mySlot);
    if (!success) {
      toast.error(message);
      return;
    }

    this.setState({ mySlot: data });
    toast.success("Slot saved");
    window.location.replace(LinkBuilder.getMySlotsLink());
  };

  handleDelete = async () => {
    const { availablePlayerId } = this.state.mySlot;

    const { success, message } = await FindOpponentApi.deleteMySlot(availablePlayerId);
    if (!success) {
      toast.error(message);
      return;
    }

    this.setState({
      mySlot: {
        availablePlayerId: "",
        currentState: "Active",
        matchTypeId: "",
        dayOfWeekId: "",
        time: "",
        timeToleranceId: "",
        courtTypeId: "",
        courtSurfaceId: "",
      },
    });
    toast.success("Slot deleted");
    window.location.replace(LinkBuilder.getMySlotsLink());
  };

  render() {
    const { availablePlayerId, currentState, matchTypeId, dayOfWeekId, time, timeToleranceId, courtTypeId, courtSurfaceId } = this.state.mySlot;
    const { staticData } = this.props;

    return (
      <>
        <Container>
          <Row className="justify-content-flex-start">
            <Col>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group mt-3">
                  <label htmlFor="currentState">Current State:</label>
                  <select id="currentState" className="form-select" value={currentState} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select State
                    </option>
                    {Object.keys(staticData.slotStates).map((d) => (
                      <option key={d} value={d}>
                        {staticData.slotStates[d]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="matchTypeId">Match type:</label>
                  <select id="matchTypeId" className="form-select" value={matchTypeId} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select Match Type
                    </option>
                    {Object.keys(staticData.matchTypes).map((d) => (
                      <option key={d} value={d}>
                        {staticData.matchTypes[d]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="dayOfWeekId">Day of week:</label>
                  <select id="dayOfWeekId" className="form-select" value={dayOfWeekId} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select Day of Week
                    </option>
                    {Object.keys(staticData.dayOfWeeks).map((d) => (
                      <option key={d} value={d}>
                        {staticData.dayOfWeeks[d]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="time">Time:</label>
                  <select id="time" className="form-select" value={time} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select time
                    </option>
                    {Object.keys(staticData.times).map((t) => (
                      <option key={t} value={t}>
                        {staticData.times[t]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="timeToleranceId">Tolerance:</label>
                  <select id="timeToleranceId" className="form-select" value={timeToleranceId} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select Tolerance
                    </option>
                    {Object.keys(staticData.tolerances).map((d) => (
                      <option key={d} value={d}>
                        {staticData.tolerances[d]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="courtTypeId">Court Type:</label>
                  <select id="courtTypeId" className="form-select" value={courtTypeId} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select Court Type
                    </option>
                    {Object.keys(staticData.courtTypes).map((ct) => (
                      <option key={ct} value={ct}>
                        {staticData.courtTypes[ct]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="courtSurfaceId">Court Surface:</label>
                  <select id="courtSurfaceId" className="form-select" value={courtSurfaceId} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select Court Surface
                    </option>
                    {Object.keys(staticData.courtSurfaces).map((ct) => (
                      <option key={ct} value={ct}>
                        {staticData.courtSurfaces[ct]}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mt-3">
                  <ButtonGroup>
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={this.handleSubmit}
                      disabled={!matchTypeId || !dayOfWeekId || !time || !timeToleranceId || !courtTypeId || !courtSurfaceId}
                    >
                      Save
                    </Button>
                    <Button variant="danger" size="lg" onClick={this.handleDelete} disabled={!availablePlayerId}>
                      Delete
                    </Button>
                  </ButtonGroup>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withStaticData(MySlot);
