import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import _ from "lodash";

import { toast } from "react-toastify";

import FindOpponentApi from "../../api/findOpponentApi";

import { withStaticData } from "../../common/staticData";
import { LinkBuilder } from "./findOpponentLinkBuilder";

class MyMatch extends React.Component {
  state = {
    myMatch: {
      availableMatchId: "",
      currentState: "Active",
      matchTypeId: "",
      date: "",
      time: "",
      courtId: "",
      comment: "",
    },
  };

  async componentDidMount() {
    const { availableMatchId } = this.props;

    if (!availableMatchId) return;

    const {
      success,
      data: { currentState, matchTypeId, date, time, courtId, comment },
      message,
    } = await FindOpponentApi.getMyMatch(availableMatchId);

    if (!success) {
      toast.error(message);
      return;
    }

    this.setState({ myMatch: { availableMatchId, currentState, matchTypeId, date, time, courtId, comment } });
  }

  handleChange = (e) => {
    const myMatch = { ...this.state.myMatch };
    myMatch[e.currentTarget.id] = e.currentTarget.value;
    this.setState({ myMatch });
  };

  handleTextChange = (e) => {
    const { myMatch } = this.state;
    myMatch.comment = _.truncate(e.target.value, { length: 20, omission: "" });
    this.setState({ myMatch });
  };

  handleSubmit = async () => {
    const { myMatch } = this.state;

    const { success, data, message } = await FindOpponentApi.setMyMatch(myMatch);
    if (!success) {
      toast.error(message);
      return;
    }

    this.setState({ myMatch: data });
    toast.success("Match saved");
    window.location.replace(LinkBuilder.getMyMatchesLink());
  };

  handleDelete = async () => {
    const { availableMatchId } = this.state.myMatch;

    const { success, message } = await FindOpponentApi.deleteMyMatch(availableMatchId);
    if (!success) {
      toast.error(message);
      return;
    }

    this.setState({ myMatch: { availableMatchId: "", matchTypeId: "", date: "", time: "", courtId: "" } });
    toast.success("Match deleted");
    window.location.replace(LinkBuilder.getMyMatchesLink());
  };

  render() {
    const { availableMatchId, currentState, matchTypeId, date, time, courtId, comment } = this.state.myMatch;
    const { staticData } = this.props;

    return (
      <React.Fragment>
        <Container>
          <Row className="justify-content-flex-start">
            <Col>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group mt-3">
                  <label htmlFor="currentState">Current State:</label>
                  <select id="currentState" className="form-select" value={currentState} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select State
                    </option>
                    {Object.keys(staticData.matchStates).map((d) => (
                      <option key={d} value={d}>
                        {staticData.matchStates[d]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="matchTypeId">Match type:</label>
                  <select id="matchTypeId" className="form-select" value={matchTypeId} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select Match Type
                    </option>
                    {Object.keys(staticData.matchTypes).map((d) => (
                      <option key={d} value={d}>
                        {staticData.matchTypes[d]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="date">Date:</label>
                  <select id="date" className="form-select" value={date} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select date
                    </option>
                    {Object.keys(staticData.dates).map((d) => (
                      <option key={d} value={d}>
                        {staticData.dates[d]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="time">Time:</label>
                  <select id="time" className="form-select" value={time} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select time
                    </option>
                    {Object.keys(staticData.times).map((t) => (
                      <option key={t} value={t}>
                        {staticData.times[t]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="courtId">Court:</label>
                  <select id="courtId" className="form-select" value={courtId} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select Court
                    </option>
                    {Object.keys(staticData.courts).map((ct) => (
                      <option key={ct} value={ct}>
                        {staticData.courts[ct]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="comment">Comment:</label>
                  <Form.Control id="comment" size="sm" type="text" placeholder="Comment" value={comment ?? ""} onChange={this.handleTextChange} />
                </div>

                <div className="form-group mt-3">
                  <ButtonGroup>
                    <Button variant="primary" size="lg" onClick={this.handleSubmit} disabled={!date || !time || !matchTypeId || !courtId}>
                      Save
                    </Button>
                    <Button variant="danger" size="lg" onClick={this.handleDelete} disabled={!availableMatchId}>
                      Delete
                    </Button>
                  </ButtonGroup>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStaticData(MyMatch);
