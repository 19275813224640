import React from "react";

import ListGroup from "react-bootstrap/ListGroup";

import { toast } from "react-toastify";

import PlannedTournamentApi from "../../api/plannedTournamentApi";

import ConfirmModal from "../../common/confirmModal";
import ProcessingModal from "../../common/processingModal";
import { withQueryParams } from "../../common/queryParams";
import { getVariantForMessageType } from "../../common/viewControls";

import PlannedTournamentEdit from "./plannedTournamentEdit";
import PlannedTournamentView from "./plannedTournamentView";
import { LinkBuilder } from "./tournamentLinkBuilder";
import AddRegistrationModal from "./addRegistrationModal";
import AddRegistrationsModal from "./addRegistrationsModal";
import { withSettings } from "../../common/withSettings";

const ModalConfirm = "conform";
const ModalStart = "start";
const ModalRegistration = "registration";
const ModalRegistrations = "registrations";

class PlannedTournament extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isEditMode: props.queryParams.edit,

      processing: false,

      modal: "",
      confirmModalData: { text: "", handleSubmit: () => {}, handleCancel: () => {} },

      plannedTournament: {
        plannedTournamentId: props.queryParams.id,
        state: "",
        name: "",
        date: "",
        startTime: "",
        endTime: "",
        eventInfoId: "",
        scoringFormatId: "",
        eventVisibilityId: "",
        courtInfo: "",
        registrations: [],
        registrationsSummary: "Registrations",
        registrationUrl: "",
        isRegistrationOpen: false,
        canStart: false,
        canDelete: false,
        messages: [],
        whatsAppGroupLink: "",
        rules: [],
      },

      uploadMessages: [],
    };
  }

  async componentDidMount() {
    const { plannedTournamentId } = this.state.plannedTournament;

    if (!plannedTournamentId) {
      this.setState({
        isLoading: false,
        isEditMode: true,
      });
      return;
    }

    this.setState({ processing: true });

    const {
      success,
      data: {
        state,
        name,
        date,
        startTime,
        endTime,
        eventInfoId,
        scoringFormatId,
        eventVisibilityId,
        courtInfo,
        registrations,
        registrationsSummary,
        registrationUrl,
        isRegistrationOpen,
        canStart,
        canDelete,
        messages,
        whatsAppGroupLink,
        rules,
      },
      message,
    } = await PlannedTournamentApi.getOne(plannedTournamentId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({
      isLoading: false,
      processing: false,
      plannedTournament: {
        plannedTournamentId,
        state,
        name,
        date,
        startTime,
        endTime,
        eventInfoId,
        scoringFormatId,
        eventVisibilityId,
        courtInfo,
        registrations,
        registrationsSummary,
        registrationUrl,
        isRegistrationOpen,
        canStart,
        canDelete,
        messages,
        whatsAppGroupLink,
        rules,
      },
    });
  }

  handleSubmit = async (plannedTournament) => {
    this.setState({ processing: true });

    const { success, data, message } = await PlannedTournamentApi.setOne(plannedTournament);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    if (!this.state.plannedTournament.plannedTournamentId) {
      window.location.replace(LinkBuilder.getViewPlannedTournamentLink(data.plannedTournamentId));
    } else {
      this.setState({ isEditMode: false, plannedTournament: data, processing: false });
      toast.success("Planned Tournament saved");
    }
  };

  handleDelete = async () => {
    const { plannedTournamentId } = this.state.plannedTournament;

    this.setState({ processing: true });

    const { success, message } = await PlannedTournamentApi.delete(plannedTournamentId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({
      plannedTournament: {
        plannedTournamentId: "",
        state: "",
        name: "",
        date: "",
        startTime: "",
        endTime: "",
        eventInfoId: "",
        scoringFormatId: "",
        eventVisibilityId: "",
        courtInfo: { "-1": "" },
        registrations: [],
        registrationsSummary: "Registrations",
        registrationUrl: "",
        isRegistrationOpen: false,
        canStart: false,
        canDelete: false,
        messages: [],
        whatsAppGroupLink: "",
        rules: [],
      },
      processing: false,
    });
    toast.success("Planned Tournament deleted");
    window.location.replace(LinkBuilder.getTournamentsLink());
  };

  handleCloseRegistration = async () => {
    const { plannedTournamentId } = this.state.plannedTournament;

    this.setState({ processing: true });

    const { success, data, message } = await PlannedTournamentApi.closeRegistration(plannedTournamentId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedTournament: data, processing: false });
    toast.success("Registration is now closed");
  };

  handleOpenRegistration = async () => {
    const { plannedTournamentId } = this.state.plannedTournament;

    this.setState({ processing: true });

    const { success, data, message } = await PlannedTournamentApi.openRegistration(plannedTournamentId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedTournament: data, processing: false });
    toast.success("Registration is now open");
  };

  handleUnregister = async (plannedEventRegistrationId) => {
    const { plannedTournamentId } = this.state.plannedTournament;

    this.setState({ processing: true });

    const { success, data, message } = await PlannedTournamentApi.unregister(plannedTournamentId, plannedEventRegistrationId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedTournament: data, processing: false });
    toast.success("Registration removed");
  };

  handleStart = async () => {
    const { plannedTournamentId } = this.state.plannedTournament;

    this.setState({ processing: true });

    const { success, message } = await PlannedTournamentApi.start(plannedTournamentId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ processing: false });
    toast.success("Tournament started");
    window.location.replace(LinkBuilder.getTournamentsLink());
  };

  handleEdit = () => {
    this.setState({ isEditMode: true });
  };

  handleCancel = () => {
    const { plannedTournamentId } = this.state.plannedTournament;

    if (!plannedTournamentId) {
      window.location.replace(LinkBuilder.getTournamentsLink());
      return;
    }

    this.setState({ isEditMode: false });
  };

  handleTierChange = async (plannedEventRegistrationId, tierId) => {
    const { plannedTournamentId } = this.state.plannedTournament;

    this.setState({ processing: true });

    const { success, data, message } = await PlannedTournamentApi.setTier(plannedTournamentId, plannedEventRegistrationId, tierId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedTournament: data, processing: false });
    toast.success("Tier set");
  };

  handleCheckIn = async (plannedEventRegistrationId, checkedIn) => {
    const { plannedTournamentId } = this.state.plannedTournament;

    this.setState({ processing: true });

    const { success, data, message } = await PlannedTournamentApi.checkIn(plannedTournamentId, plannedEventRegistrationId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedTournament: data, processing: false });
    toast.success(checkedIn ? "Player checked out" : "Player checked in");
  };

  handleShowConfirm = (text, action) => {
    this.setState({
      modal: ModalConfirm,
      confirmModalData: {
        text: text,
        handleSubmit: () => {
          this.handleHideConfirm();
          action();
        },
        handleCancel: this.handleHideConfirm,
      },
    });
  };

  handleHideConfirm = () => {
    this.setState({ modal: "", confirmModalData: { text: "", handleSubmit: () => {}, handleCancel: () => {} } });
  };

  handleUploadBulk = async (file) => {
    const { plannedTournamentId } = this.state.plannedTournament;

    this.setState({ processing: true });

    const { success, data, message } = await PlannedTournamentApi.uploadRegistrations(plannedTournamentId, file);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedTournament: data.plannedTournament, uploadMessages: data.uploadMessages, processing: false });
  };

  handleUploadOne = async (name) => {
    const { plannedTournamentId } = this.state.plannedTournament;

    this.setState({ processing: true });

    const { success, data, message } = await PlannedTournamentApi.uploadRegistration(plannedTournamentId, name);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedTournament: data.plannedTournament, uploadMessages: data.uploadMessages, processing: false });
  };

  render() {
    const {
      settings: {
        staticData: { registerPlayerHelp },
      },
    } = this.props;
    const { isLoading, isEditMode, modal, confirmModalData, plannedTournament, processing, uploadMessages } = this.state;

    return (
      <React.Fragment>
        {processing && <ProcessingModal show={true} />}
        {modal === ModalConfirm && <ConfirmModal show={true} data={confirmModalData} />}
        {modal === ModalStart && (
          <ConfirmModal show={true} data={confirmModalData}>
            <ListGroup>
              {plannedTournament.messages
                .filter((e) => e.type === "Confirm")
                .map((e, i) => {
                  return (
                    <ListGroup.Item key={i} variant={getVariantForMessageType(e.type)}>
                      {e.message}
                    </ListGroup.Item>
                  );
                })}
            </ListGroup>
          </ConfirmModal>
        )}
        {modal === ModalRegistration && (
          <AddRegistrationModal
            uploadMessages={uploadMessages}
            handleUpload={this.handleUploadOne}
            handleCancel={() => this.setState({ modal: "", uploadMessages: [] })}
            helpText={registerPlayerHelp[plannedTournament.eventInfoId].name}
          />
        )}
        {modal === ModalRegistrations && (
          <AddRegistrationsModal
            uploadMessages={uploadMessages}
            handleUpload={this.handleUploadBulk}
            handleCancel={() => this.setState({ modal: "", uploadMessages: [] })}
          />
        )}
        {!isLoading && !isEditMode && (
          <PlannedTournamentView
            handleCloseRegistration={this.handleCloseRegistration}
            handleOpenRegistration={this.handleOpenRegistration}
            handleUnregister={(plannedEventRegistration) =>
              this.handleShowConfirm(`Are you sure you would like to remove ${plannedEventRegistration.name} from the tournament?`, () =>
                this.handleUnregister(plannedEventRegistration.plannedEventRegistrationId)
              )
            }
            handleStart={() =>
              this.setState({
                modal: ModalStart,
                confirmModalData: {
                  text: "Please confirm you are happy to start the tournament:",
                  handleSubmit: () => {
                    this.handleHideConfirm();
                    this.handleStart();
                  },
                  handleCancel: this.handleHideConfirm,
                },
              })
            }
            handleEdit={this.handleEdit}
            handleTierChange={this.handleTierChange}
            handleCheckIn={this.handleCheckIn}
            handleAddRegistration={() => this.setState({ modal: ModalRegistration })}
            handleAddRegistrations={() => this.setState({ modal: ModalRegistrations })}
            plannedTournament={plannedTournament}
          />
        )}
        {!isLoading && isEditMode && (
          <PlannedTournamentEdit plannedTournament={plannedTournament} handleSubmit={this.handleSubmit} handleCancel={this.handleCancel} />
        )}
      </React.Fragment>
    );
  }
}

export default withSettings(withQueryParams(PlannedTournament));
