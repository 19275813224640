import React from "react";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

class EventInfo extends React.Component {
  state = {};

  render() {
    const { state, startDate, endDate, participantCount, onCopyNo, onCopyAz } = this.props;

    return (
      <Table>
        <tbody>
          <tr>
            <td className="fw-bold">State:</td>
            <td>{state}</td>
          </tr>
          <tr>
            <td className="fw-bold">Start Date:</td>
            <td>{new Date(startDate).toLocaleDateString()}</td>
          </tr>
          <tr>
            <td className="fw-bold">End Date:</td>
            <td>{new Date(endDate).toLocaleDateString()}</td>
          </tr>
          <tr>
            <td className="fw-bold">Participants:</td>
            <td>
              <span className="pe-4">{participantCount}</span>
              {onCopyNo && (
                <Button size="sm" variant="outline-primary" className="me-1" onClick={() => onCopyNo()}>
                  COPY #
                </Button>
              )}
              {onCopyAz && (
                <Button size="sm" variant="outline-primary" onClick={() => onCopyAz()}>
                  COPY A-Z
                </Button>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default EventInfo;
