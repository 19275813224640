import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { getEventSeriesName } from "../../common/eventSeries";
import { DateEdit, SelectEdit, TextEdit } from "../../common/editControls";

import { withSettings } from "../../common/withSettings";

class PlannedLeagueEdit extends React.Component {
  state = {
    plannedLeague: { ...this.props.plannedLeague },
    handleSubmit: this.props.handleSubmit,
    handleCancel: this.props.handleCancel,
  };

  handleChange = (e) => {
    const { plannedLeague } = this.state;
    plannedLeague[e.target.id] = e.target.value;
    this.setState({ plannedLeague });
  };

  render() {
    const {
      plannedLeague: { eventSeriesId, name, startDate, endDate, registerByDate, registerByTime, whatsAppGroupLink },
      handleSubmit,
      handleCancel,
    } = this.state;

    const { staticData } = this.props.settings;

    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col>
              <Form>
                <TextEdit id="eventSeriesId" label="League Type" value={getEventSeriesName(eventSeriesId)} disabled={true} />
                <TextEdit id="name" label="Name" value={name} onChange={this.handleChange} />
                <DateEdit id="startDate" label="Start Date" value={startDate} onChange={this.handleChange} />
                <DateEdit id="endDate" label="End Date" value={endDate} onChange={this.handleChange} />
                <DateEdit id="registerByDate" label="Register By Date" value={registerByDate} onChange={this.handleChange} />
                <SelectEdit id="registerByTime" label="Register By Time" items={staticData.times} value={registerByTime} onChange={this.handleChange} />
                <TextEdit id="whatsAppGroupLink" label="WhatsApp Group Link" value={whatsAppGroupLink ?? ""} onChange={this.handleChange} />
                <ButtonGroup className="mt-5">
                  <Button
                    variant="outline-primary"
                    onClick={() => handleSubmit(this.state.plannedLeague)}
                    disabled={!name || !startDate || !endDate || !registerByDate || !registerByTime}
                  >
                    SAVE
                  </Button>
                  <Button variant="outline-danger" onClick={handleCancel}>
                    CANCEL
                  </Button>
                </ButtonGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withSettings(PlannedLeagueEdit);
