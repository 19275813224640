import { PlayerApi } from "./atsApi";

class ScoreCastApi {
  constructor() {
    this.api = new PlayerApi("score-cast");
  }

  getAll = () => this.api.getRequest("");
  getOneEdit = (scoreCastId) => this.api.getRequest(`${scoreCastId}`);
  getOne = (scoreCastId, version, viewerId) => this.api.getRequest(`${scoreCastId}/${version}/${viewerId}`);
  setOne = (scoreCast) => this.api.postRequest("", scoreCast);
  deleteOne = (scoreCastId) =>
    this.api.deleteRequest(`${scoreCastId}`, { 400: "Cannot delete match stream with existsing frames, please contact the administrator." });

  newFrame = (scoreCastId, scoreCastFrame) => this.api.postRequest(`${scoreCastId}/new-frame`, scoreCastFrame);
  newUmpireFrame = (scoreCastId, scoreCastFrame) => this.api.postRequest(`${scoreCastId}/new-umpire-frame`, scoreCastFrame);
  newCommentFrame = (scoreCastId, scoreCastFrame) => this.api.postRequest(`${scoreCastId}/new-comment-frame`, scoreCastFrame);
}

const scoreCastApi = new ScoreCastApi();
export default scoreCastApi;
