import React from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { getLink } from "../common/appLinkBuilder";

import { logout, isAuthenticated } from "../common/auth";
import { withSettings } from "../common/withSettings";

class MainMenu extends React.Component {
  state = {
    canLogout: false,
    canEdit: false,
  };

  componentDidMount() {
    const canLogout = isAuthenticated();

    this.setState({ canLogout });
  }

  render() {
    const { canLogout } = this.state;
    const {
      settings: { siteWhitelabel },
      appItems = [],
      hideMenu,
    } = this.props;

    return (
      <>
        <Navbar collapseOnSelect expand="sm">
          <Container>
            <Navbar.Brand href={window.location.origin}>
              <img src={getLink(`${siteWhitelabel}.logo.png`)} height="40" alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarCollapse" />
            <Navbar.Collapse id="navbarCollapse">
              <Nav>
                {!hideMenu && <Nav.Link href={getLink()}>Menu</Nav.Link>}
                {appItems.map((ai) => {
                  return (
                    <Nav.Link key={ai.key} href={ai.url}>
                      {ai.title}
                    </Nav.Link>
                  );
                })}
                {!canLogout && <Nav.Link href={getLink("?signin")}>Sign In</Nav.Link>}
                {canLogout && (
                  <Nav.Link href="#" onClick={() => logout()}>
                    Sign Out
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default withSettings(MainMenu);
