import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import { toast } from "react-toastify";

//import { withSettings } from "../../common/withSettings";
import { login } from "../common/auth";

import PlayerLoginApi from "../api/playerLoginApi";
import { TextEdit } from "../common/editControls";
import ProcessingModal from "../common/processingModal";
import { withQueryParams } from "./queryParams";

const phasePhone = 0;
const phaseCode = 1;
const phaseName = 2;
const phaseVerify = 3;

class Register extends React.Component {
  state = {
    processing: false,
    phase: phasePhone,
    authData: { phone: "", token: "", name: "" },
  };

  handleAuthChange = (e) => {
    const authData = { ...this.state.authData };
    authData[e.currentTarget.id] = e.currentTarget.value;
    this.setState({ authData });
  };

  find = async (e) => {
    if (e !== undefined) e.preventDefault();

    this.setState({ processing: true });

    const { success, status, message } = await PlayerLoginApi.find(this.state.authData.phone);
    if (!success && status !== 404) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ processing: false, phase: status === 404 ? phaseName : phaseCode });
  };

  login = async (e) => {
    if (e !== undefined) e.preventDefault();

    this.setState({ processing: true });

    const { phone, token } = this.state.authData;

    const { success, data } = await PlayerLoginApi.login(phone, token);
    if (!success) {
      toast.error("Unable to sign in with provided verification code, please try again");
      this.setState({ processing: false, phase: phasePhone, authData: { phone: "", token: "", name: "" } });
      return;
    }

    const {
      inPlace,
      queryParams: { targetUrl },
    } = this.props;
    login(data.sessionId, data.isAdmin, data.myPage, data.tag, data.permissions, inPlace ?? true, targetUrl);
  };

  register = async (e) => {
    if (e !== undefined) e.preventDefault();

    this.setState({ processing: true });

    const { phone, name } = this.state.authData;

    const { success, message } = await PlayerLoginApi.register(phone, name);
    if (!success) {
      this.setState({ processing: false, phase: phasePhone, authData: { phone: "", token: "", name: "" } });
      toast.error(message);
      return;
    }

    this.setState({ processing: false, phase: phaseVerify });
  };

  verify = async (e) => {
    if (e !== undefined) e.preventDefault();

    this.setState({ processing: true });

    const { phone, token } = this.state.authData;

    const { success, message, data } = await PlayerLoginApi.verify(phone, token);
    if (!success) {
      this.setState({ processing: false, phase: phasePhone, authData: { phone: "", token: "", name: "" } });
      toast.error(message);
      return;
    }

    const {
      inPlace,
      queryParams: { targetUrl },
    } = this.props;
    login(data.sessionId, data.isAdmin, data.myPage, data.tag, data.permissions, inPlace ?? true, targetUrl);
  };

  render() {
    const {
      processing,
      phase,
      authData: { phone, token, name },
    } = this.state;

    const {
      inPlace,
      queryParams: { targetUrl },
    } = this.props;

    console.log("Register.render", { inPlace, targetUrl });
    return (
      <React.Fragment>
        <ProcessingModal show={processing} />
        <Container fluid="md">
          <Row>
            <Col>
              {phase === phasePhone && (
                <Form onSubmit={this.find}>
                  <TextEdit
                    id="phone"
                    label="Phone number"
                    placeholder="07123456789"
                    value={phone}
                    helpText="Please enter as 07123456789: 07 followed by 9 digits"
                    onChange={this.handleAuthChange}
                  />
                  <Button className="mt-3" variant="primary" size="lg" type="submit" disabled={!phone}>
                    Continue
                  </Button>
                </Form>
              )}
              {phase === phaseCode && (
                <Form onSubmit={this.login}>
                  <TextEdit
                    id="token"
                    label={`A verification code has been sent to ${phone} via text, please enter it below:`}
                    placeholder="Verification code"
                    value={token}
                    helpText="Please enter 6 digits without spaces"
                    onChange={this.handleAuthChange}
                  />
                  <Button className="mt-3 me-1" variant="secondary" size="lg" onClick={() => this.setState({ phase: phasePhone })}>
                    Back
                  </Button>
                  <Button className="mt-3 me-3" variant="secondary" size="lg" onClick={() => this.find()}>
                    Resend code
                  </Button>
                  <Button className="mt-3" type="submit" size="lg" variant="primary" disabled={!token}>
                    Continue
                  </Button>
                </Form>
              )}
              {phase === phaseName && (
                <Form onSubmit={this.register}>
                  <Form.Label className="mt-3 fw-bold">You do not appear to have an account with us. Let's create one:</Form.Label>
                  <TextEdit id="phone" label="Phone number" value={phone} disabled={true} />
                  <TextEdit id="name" label="Full name" value={name} onChange={this.handleAuthChange} />
                  <Form.Label className="mt-3 mb-3">
                    By clicking Continue, you confirm that you have read and agree to the terms of our{" "}
                    <a href="https://ats.tennis/privacy-policy.pdf" target="_blank" rel="noreferrer">
                      Privacy Policy
                    </a>
                  </Form.Label>
                  <Button className="me-3" variant="secondary" size="lg" onClick={() => this.setState({ phase: phasePhone })}>
                    Back
                  </Button>
                  <Button variant="primary" size="lg" type="submit" disabled={!name || !phone}>
                    Continue
                  </Button>
                </Form>
              )}
              {phase === phaseVerify && (
                <Form onSubmit={this.verify}>
                  <TextEdit id="phone" label="Phone number" value={phone} disabled={true} />
                  <TextEdit
                    id="token"
                    label={`A verification code has been sent to ${phone} via text, please enter it below:`}
                    placeholder="Verification code"
                    value={token}
                    helpText="Please enter 6 digits without spaces"
                    onChange={this.handleAuthChange}
                  />
                  <Form.Label className="mt-3 mb-3">
                    By clicking Continue, you confirm that you have read and agree to the terms of our{" "}
                    <a href="https://ats.tennis/privacy-policy.pdf" target="_blank" rel="noreferrer">
                      Privacy Policy
                    </a>
                  </Form.Label>
                  <Button className="me-3" variant="secondary" size="lg" onClick={() => this.setState({ phase: phaseName })}>
                    Back
                  </Button>
                  <Button className="me-3" variant="secondary" size="lg" onClick={() => this.register()}>
                    Resend code
                  </Button>
                  <Button variant="primary" size="lg" type="submit" disabled={!name || !phone}>
                    Continue
                  </Button>
                </Form>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withQueryParams(Register);
