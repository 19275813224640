import React from "react";

import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { SelectEdit } from "../../common/editControls";
import ConfirmModal, { ConfirmModalHelper } from "../../common/confirmModal";

class SetAlert extends React.Component {
  statusBadge = {
    Draft: "info",
    Active: "success",
    Fired: "danger",
    Expired: "warning",
  };

  state = {
    modal: "",
    confirmModalData: ConfirmModalHelper.Empty,
  };

  render() {
    const {
      courtAlert: { currentState, date, time, tolerance, courtTypeId, courtSurfaceId },
      staticData,
      onChange,
      onSetAlert,
    } = this.props;

    const { modal, confirmModalData } = this.state;

    return (
      <React.Fragment>
        <ConfirmModal show={modal === ConfirmModalHelper.Name} data={confirmModalData} />
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h1>
                Court Alerts - Set Alert <Badge bg={this.statusBadge[currentState]}>{currentState}</Badge>
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-flex-start">
            <Col>
              <form onSubmit={this.handleSubmit}>
                <SelectEdit id="date" label="Date" items={staticData.dates} value={date} onChange={onChange} />
                <SelectEdit id="time" label="Time" items={staticData.times} value={time} onChange={onChange} />
                <SelectEdit id="tolerance" label="Tolerance" items={staticData.tolerances} value={tolerance} onChange={onChange} />
                <SelectEdit id="courtTypeId" label="Court Type" items={staticData.courtTypes} value={courtTypeId} onChange={onChange} />
                <SelectEdit id="courtSurfaceId" label="Court Surface" items={staticData.courtSurfaces} value={courtSurfaceId} onChange={onChange} />

                <div className="form-group mt-3">
                  <Button
                    className="btn btn-primary btn-large"
                    onClick={(e) => {
                      e.preventDefault();
                      if (currentState === "Active")
                        ConfirmModalHelper.handleShowConfirm(
                          (s) => this.setState(s),
                          "Are you sure you want to override your previous alert?",
                          () => onSetAlert(),
                          () => window.location.reload()
                        );
                      else onSetAlert();
                    }}
                    disabled={!date || !time || !tolerance || !courtTypeId || !courtSurfaceId}
                  >
                    Set Alert
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default SetAlert;
