function getEnv(hostname) {
  const instance = hostname.slice(0, hostname.indexOf("."));

  if (instance === "ayk") return { instance, type: "dev" };
  return { instance, type: "edge" };
}

export function getPlayerApiUrl() {
  var { hostname } = document.location;

  if (hostname === "localhost") return "http://localhost:5001/ayk/player";

  const { instance, type } = getEnv(hostname);

  return `https://api.${type}.ats.tennis/${instance}/player`;
}

export function getAdminApiUrl() {
  var { hostname } = document.location;

  if (hostname === "localhost") return "http://localhost:5000/ayk/admin";

  const { instance, type } = getEnv(hostname);

  return `https://api.${type}.ats.tennis/${instance}/admin`;
}

export function getS3ApiUrl() {
  var { hostname } = document.location;

  if (hostname === "localhost") return "http://localhost:4000";

  return `https://${hostname}`;
}
