import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { withSettings } from "../common/withSettings";
import { isAdmin, isAuthenticated } from "../common/auth";

import MainMenu from "../components/mainMenu";
import MenuCard from "../components/menuCard";
import { getAppLink } from "../common/appLinkBuilder";

class Home extends React.Component {
  state = {};

  render() {
    const { settings } = this.props;
    const showClubStream = false;

    return (
      <React.Fragment>
        <MainMenu />
        <Container fluid="md">
          <Row xs={1} md={2}>
            {isAdmin() && <Admin />}
            {settings.tournamentsEnabled && <Tournament />}
            {isAuthenticated() && settings.courtAlertsEnabled && <CourtAlerts />}
            {isAuthenticated() && settings.findOpponentEnabled && <FindOpponent />}
            {settings.umpireCastEnabled && <UmpireCast />}
            {settings.clubStreamEnabled && showClubStream && <ClubStream />}
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

function Admin() {
  return (
    <MenuCard
      title="Admin Dashboard"
      text="View the status of leagues, championships etc., set and confirm scores for matches, open/close registration for new events."
      url={getAppLink("admin")}
    />
  );
}

function Tournament() {
  return (
    <MenuCard title="Tournament" text="View results or participate in one of the tournaments available, create your own one." url={getAppLink("tournament")} />
  );
}

function CourtAlerts() {
  return (
    <MenuCard
      title="Court Alerts"
      text="Get notified when a court becomes available or notify others of a court you just cancelled."
      url={getAppLink("court-alerts")}
    />
  );
}

function FindOpponent() {
  return (
    <MenuCard
      title="Find Opponent"
      text="Set times when you are available and get notified if someone is looking for a partner to play. Have a court? See if anyone is available to play."
      url={getAppLink("find-opponent")}
    />
  );
}

function UmpireCast() {
  return (
    <MenuCard
      title="Score Cast"
      text="Follow a match point-by-point or stream the score of your match for others to follow..."
      url={getAppLink("umpire-cast")}
    />
  );
}

function ClubStream() {
  return (
    <MenuCard
      title="Club Stream"
      text="See the latest scores, events and news at the club, follow matches of the tournament that runs right now..."
      url={getAppLink("club-stream")}
    />
  );
}

export default withSettings(Home);
