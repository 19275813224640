import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import Nav from "react-bootstrap/Nav";

import { LinkBuilder } from "./rallyRecordLinkBuilder";

class ChildSelector extends React.Component {
  state = {};

  render() {
    const { children } = this.props;

    return (
      <React.Fragment>
        <Container fluid="md">
          <Row>
            <Col>
              <h3>Whose matches are you interested in today?</h3>
              <ListGroup>
                {children.map((child) => {
                  return (
                    <ListGroup.Item action key={child.id} as="div">
                      <Nav.Link href={LinkBuilder.getMatchesLink(child.id)}>{child.name}</Nav.Link>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default ChildSelector;
