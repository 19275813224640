import React from "react";

import { Helmet } from "react-helmet-async";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useQueryParams } from "../../common/queryParams";

import MainMenu from "../../components/mainMenu";

import StaticDataContext from "../../contexts/staticDataContext";

import FindPlayer from "./findPlayer";
import FindMatch from "./findMatch";
import MySlot from "./mySlot";
import MySlots from "./mySlots";
import MyMatches from "./myMatches";
import MyMatch from "./myMatch";
import MenuCard from "../../components/menuCard";
import FindOpponentApi from "../../api/findOpponentApi";
import { LinkBuilder } from "./findOpponentLinkBuilder";

const MenuItems = [
  {
    key: "findPlayer",
    title: "Find a Player",
    url: LinkBuilder.getFindPlayerLink(),
    text: "You have a court booked and looking for a player? See if anyone is availabe...",
  },
  {
    key: "findMatch",
    title: "Find a Match",
    url: LinkBuilder.getFindMatchLink(),
    text: "You don't have a court booked but still looking to play? See if anyone is looking for a partner or an opponent...",
  },
  {
    key: "mySlots",
    title: "My Tennis Slots",
    url: LinkBuilder.getMySlotsLink(),
    text: "If you are looking to join more matches, define your schedule and let others with court bookings to find you...",
  },
  {
    key: "myMatches",
    title: "My Matches",
    url: LinkBuilder.getMyMatchesLink(),
    text: "If you are looking for opponent or a partner, enter your matches and let others whoa re looking to join a match to find you...",
  },
];

class FindOpponentApp extends React.Component {
  state = {
    staticData: {
      courts: {},
      courtSurfaces: {},
      courtTypes: {},
      dates: {},
      times: {},
      tolerances: {},
      matchTypes: {},
      matchStates: {},
      slotStates: {},
      dayOfWeeks: {},
    },
  };

  async componentDidMount() {
    const { data } = await FindOpponentApi.getStaticData();
    this.setState({ staticData: data });
  }

  render() {
    const { staticData } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Find Opponent - ATS</title>
        </Helmet>
        <MainMenu appItems={MenuItems} />
        <StaticDataContext.Provider value={staticData}>
          <GetPage />
        </StaticDataContext.Provider>
      </React.Fragment>
    );
  }
}

function GetPage() {
  const queryParams = useQueryParams();

  if ("page" in queryParams) {
    if (queryParams.page === "findPlayer") return <FindPlayer />;
    if (queryParams.page === "findMatch") return <FindMatch />;
    if (queryParams.page === "mySlots") return <MySlots />;
    if (queryParams.page === "mySlot") return <MySlot availablePlayerId={queryParams.availablePlayerId} />;
    if (queryParams.page === "myMatches") return <MyMatches />;
    if (queryParams.page === "myMatch") return <MyMatch availableMatchId={queryParams.availableMatchId} />;
  }

  return (
    <React.Fragment>
      <Container fluid="md">
        <Row xs={1} md={2}>
          {MenuItems.map((mi) => (
            <MenuCard key={mi.key} title={mi.title} url={mi.url} text={mi.text} />
          ))}
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default FindOpponentApp;
