import { AdminApi } from "./atsApi";

class PlannedLeagueApi {
  constructor() {
    this.api = new AdminApi("planned-league");
  }

  getStaticData = () => this.api.getRequest("static-data");

  getOne = (plannedLeagueId) => this.api.getRequest(`${plannedLeagueId}`);
  setOne = (plannedLeague) => this.api.postRequest("", plannedLeague);

  createdefaultGroups = (plannedLeagueId) => this.api.postRequest(`${plannedLeagueId}/create-default-groups`);

  copy = (plannedLeagueId) => this.api.postRequest(`${plannedLeagueId}/copy`);

  openRegistration = (plannedLeagueId) => this.api.postRequest(`${plannedLeagueId}/open`);
  closeRegistration = (plannedLeagueId) => this.api.postRequest(`${plannedLeagueId}/close`);
  unregister = (plannedLeagueId, plannedEventRegistrationId) => this.api.postRequest(`${plannedLeagueId}/unregister/${plannedEventRegistrationId}`);

  assignGroup = (plannedLeagueId, plannedEventRegistrationId, groupId) =>
    this.api.postRequest(`${plannedLeagueId}/set-group/${plannedEventRegistrationId}/${groupId}`);

  start = (plannedLeagueId) => this.api.postRequest(`${plannedLeagueId}/start`, { 400: "Event with the same name already exists." });
}

const plannedLeagueApi = new PlannedLeagueApi();
export default plannedLeagueApi;
