import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import FadeIn from "react-fade-in";

import { DualProgressBar4, DualProgressBar3 } from "./progressBars";
import { Number } from "./utils";
import { withQueryParams } from "./../../common/queryParams";

class SummaryPage extends React.Component {
  state = {};

  render() {
    const {
      page,
      name,
      data: { matchesByType, matchesByOutcome, leagueRounds, tournaments, championships },
      fadeTimeout,
    } = this.props;

    const { shared } = this.props.queryParams;

    const optionalRows = [];

    if (leagueRounds.sgl > 0) {
      optionalRows.push(
        <Row className="mt-3" key="sglLeague">
          <Col>Participated in {<Number value={leagueRounds.sgl} />} singles league rounds</Col>
        </Row>
      );
    }

    if (leagueRounds.dbl > 0) {
      optionalRows.push(
        <Row className="mt-3" key="dblLeague">
          <Col>Participated in {<Number value={leagueRounds.dbl} />} doubles league rounds</Col>
        </Row>
      );
    }

    if (tournaments.sgl > 0) {
      optionalRows.push(
        <Row className="mt-3" key="sglTournament">
          <Col>Played in {<Number value={tournaments.sgl} />} singles tournaments</Col>
        </Row>
      );
    }

    if (tournaments.dbl > 0) {
      optionalRows.push(
        <Row className="mt-3" key="dblTournament">
          <Col>Played in {<Number value={tournaments.dbl} />} doubles tournaments</Col>
        </Row>
      );
    }

    if (championships > 0) {
      optionalRows.push(
        <Row className="mt-3" key="championship">
          <Col>Competed in {<Number value={championships} />} championships</Col>
        </Row>
      );
    }

    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <h1>{shared ? `${name}'s ` : "Your"} tennis year in numbers</h1>
          </Col>
        </Row>
        <FadeIn delay={fadeTimeout} key={page}>
          <Row className="mt-4">
            <Col>{<Number value={matchesByType.refVal} />} Matches played</Col>
          </Row>
          <Row className="mt-3">
            <Col>
              {<Number value={matchesByType.val1} />} singles and {<Number value={matchesByType.val2} />} doubles
              <DualProgressBar4 value={matchesByType} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              Won {<Number value={matchesByOutcome.val1} />} and lost {<Number value={matchesByOutcome.val2} />}
              <DualProgressBar3 value={matchesByOutcome} />
            </Col>
          </Row>
          {optionalRows.map((r) => r)}
        </FadeIn>
      </Container>
    );
  }
}

export default withQueryParams(SummaryPage);
