import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";

import _ from "lodash";

import { toast } from "react-toastify";

import ScoreCastApi from "../../api/scoreCastApi";

import { hasPermission, isAdmin, Permission } from "../../common/auth";
import ProcessingModal from "../../common/processingModal";

import { LinkBuilder } from "./umpireCastLinkBuilder";

class MatchSelector extends React.Component {
  state = {
    loading: true,

    isParent: hasPermission(Permission.ParentCast),
    isAdmin: isAdmin(),

    scoreCasts: [],
  };

  async componentDidMount() {
    await this.loadScoreCasts();
  }

  loadScoreCasts = async () => {
    const { success, data: scoreCasts, message } = await ScoreCastApi.getAll();

    if (!success) {
      toast.error(message);
      return;
    }

    this.setState({ scoreCasts, loading: false });
  };

  render() {
    const { loading, isParent, isAdmin } = this.state;

    const scoreCasts = _.chain(this.state.scoreCasts).orderBy("startAt", "desc").value();

    return (
      <React.Fragment>
        <Container fluid="md">
          <Row>
            <Col>
              <h1>
                Score-Cast - Matches:{" "}
                {(isParent || isAdmin) && (
                  <Button variant="outline-primary" href={LinkBuilder.getNewMatchLink()}>
                    NEW
                  </Button>
                )}
              </h1>
              {loading && <ProcessingModal text="Loading..." />}
              <ListGroup>
                {scoreCasts.map((scoreCast) => {
                  return (
                    <ListGroup.Item action key={scoreCast.matchStreamId} as="div">
                      <Stack gap="2">
                        <Nav.Link href={LinkBuilder.getMatchLink(scoreCast.matchStreamId)}>
                          <div>{scoreCast.eventName}</div>
                          <div>{`${scoreCast.side1Name} vs ${scoreCast.side2Name}`}</div>
                        </Nav.Link>
                        {(scoreCast.canEdit || scoreCast.canUmpire || scoreCast.canComment) && (
                          <Stack direction="horizontal" gap="1">
                            {scoreCast.canEdit && (
                              <Button variant="outline-primary" size="sm" href={LinkBuilder.getEditMatchLink(scoreCast.matchStreamId)}>
                                EDIT
                              </Button>
                            )}
                            {scoreCast.canEdit && (
                              <Button variant="outline-primary" size="sm" href={LinkBuilder.getParentMatchLink(scoreCast.matchStreamId)}>
                                STREAM
                              </Button>
                            )}
                            {scoreCast.canComment && (
                              <Button variant="outline-primary" size="sm" href={LinkBuilder.getCommentMatchLink(scoreCast.matchStreamId)}>
                                COMMENT
                              </Button>
                            )}
                            {scoreCast.canUmpire && (
                              <Button variant="outline-primary" size="sm" href={LinkBuilder.getUmpireMatchLink(scoreCast.matchStreamId)}>
                                UMPIRE
                              </Button>
                            )}
                          </Stack>
                        )}
                      </Stack>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default MatchSelector;
