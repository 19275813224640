import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { Helmet } from "react-helmet-async";
import ProcessingModal from "../../common/processingModal";
import joinApi from "../../api/joinApi";
import { toast } from "react-toastify";
import Rules from "../tournament/rules";
import { getSessionId, isAuthenticated } from "../../common/auth";
import Register from "../../common/register";
import JoinEventFields from "./joinEventFields";
import { withStripePromise } from "../../common/withStripeSettings";
import { Elements } from "@stripe/react-stripe-js";
import { getPlayerApiUrl } from "../../api/apiUrlBuilder";
import LeagueFields from "./leagueFields";
import ConfirmModal, { ConfirmModalHelper } from "../../common/confirmModal";
import { getLink } from "../../common/appLinkBuilder";
import { IoIosCopy, IoMdShare } from "react-icons/io";
import share from "./../../common/share";
import texts from "./joinTexts";

class Join extends React.Component {
  state = {
    processing: false,
    loaded: false,

    modal: "",
    confirmModalData: ConfirmModalHelper.Empty,

    plannedEventId: this.props.data.plannedEventId,
    clubId: "",
    name: "",
    address: "",
    courts: "",
    originalPrice: 5,
    price: 5,
    discount: 0,
    leagueName: "",
    registered: false,
    players: 0,
    startDate: new Date("2024-01-01"),
    endDate: new Date("2024-01-01"),
    registerByDate: new Date("2024-01-01"),
    clientSecret: "",
    eventRules: [],
    registrationFields: [],
  };

  async componentDidMount() {
    this.setState({ processing: true });

    const { success, data, message } = await joinApi.getJoinData(this.state.plannedEventId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    const {
      clubId,
      name,
      address,
      courts,
      originalPrice,
      price,
      discount,
      leagueName,
      registered,
      players,
      startDate,
      endDate,
      registerByDate,
      clientSecret,
      eventRules,
      registrationFields,
    } = data;

    this.setState({
      processing: false,
      loaded: true,
      clubId,
      name,
      address,
      courts,
      originalPrice,
      price,
      discount,
      leagueName,
      registered,
      players,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      registerByDate: new Date(registerByDate),
      clientSecret,
      eventRules,
      registrationFields: registrationFields.fields ?? [],
    });
  }

  handleLeaveEvent = async () => {
    this.setState({ processing: true });

    const { success, message } = await joinApi.leaveEvent(this.state.plannedEventId);
    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    window.location.reload();
  };

  handleSubmit = async (stripe, elements, data) => {
    if (!stripe || !elements) {
      return;
    }

    const auth = getSessionId();
    const dataB64 = btoa(JSON.stringify(data));

    this.setState({ processing: true });

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${getPlayerApiUrl()}/join/stripe-join-event/${this.state.plannedEventId}?AtsSessionId=${auth}&data=${dataB64}`,
      },
    });

    this.setState({ processing: false });

    if (error.type === "card_error" || error.type === "validation_error") {
      toast.error(error.message);
    } else {
      toast.error("An unexpected error occurred.");
    }
  };

  render() {
    const {
      processing,
      loaded,

      modal,
      confirmModalData,

      plannedEventId,
      clubId,
      name,
      address,
      courts,
      originalPrice,
      price,
      discount,
      leagueName,
      registered,
      players,
      startDate,
      endDate,
      registerByDate,
      clientSecret,
      eventRules,
      registrationFields,
    } = this.state;

    const { stripePromise } = this.props;

    const isAuth = isAuthenticated();

    if (!loaded) return <ProcessingModal show={true} text="Loading..." />;

    return (
      <React.Fragment>
        <Helmet>
          <title>Join a league - {name}</title>
        </Helmet>
        <ProcessingModal show={processing} />
        <ConfirmModal show={modal === ConfirmModalHelper.Name} data={confirmModalData} />
        <Container fluid="md">
          <Row>
            <Col>
              <h1>Join the league</h1>
              <LeagueFields
                name={name}
                address={address}
                courts={courts}
                originalPrice={originalPrice}
                price={price}
                discount={discount}
                leagueName={leagueName}
                players={players}
                startDate={startDate}
                endDate={endDate}
                registerByDate={registerByDate}
                registered={registered}
              />
            </Col>
          </Row>
          {!isAuth && (
            <Row className="mt-3 mb-5">
              <Col>
                <h2>Sign in to join the league:</h2>
                <Register inPlace={true} />
              </Col>
            </Row>
          )}
          {isAuth && !registered && (
            <React.Fragment>
              <Row className="mt-3">
                <Col>
                  <h2>Fill in the form to join the league:</h2>
                  {clientSecret && (
                    <Elements options={{ clientSecret, appearance: { theme: "stripe" } }} stripe={stripePromise}>
                      <JoinEventFields
                        buttonText="JOIN NOW"
                        clubId={clubId}
                        registrationFields={registrationFields}
                        price={price}
                        handleSubmit={(stripe, elements, data) =>
                          ConfirmModalHelper.handleShowConfirm(
                            (s) => this.setState(s),
                            texts.confirmCanUseCourts(name, registerByDate),
                            () => this.handleSubmit(stripe, elements, data)
                          )
                        }
                      />
                    </Elements>
                  )}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>{texts.cancellationPolicy(registerByDate)}</Col>
              </Row>
              <Row className="text-muted mt-2 mb-5">
                <Col>{texts.confirmPrivacy("JOIN NOW")}</Col>
              </Row>
            </React.Fragment>
          )}
          {isAuth && registered && (
            <React.Fragment>
              <Row>
                <Col>
                  <h4>You are now registered for this league</h4>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>{texts.share(name, discount)}</Col>
              </Row>
              <Row className="mt-3 mb-5" xs={1} md={3}>
                <Col className="mt-2">
                  <Button style={{ width: "180px" }} href={share.whatsApp(name, discount, plannedEventId)} target="_blank" rel="noreferrer">
                    <img src={getLink(`WhatsApp.png`)} height="33" alt="" />
                  </Button>
                </Col>
                <Col className="mt-2">
                  <Button
                    className="fs-5 fw-bold"
                    style={{ width: "180px" }}
                    onClick={async () => await navigator.share(share.navigateData(name, discount, plannedEventId))}
                  >
                    <IoMdShare size="30" className="me-2" />
                    Share Link
                  </Button>
                </Col>
                <Col className="mt-2">
                  <Button
                    className="fs-5 fw-bold"
                    style={{ width: "180px" }}
                    onClick={() => {
                      navigator.clipboard.writeText(share.copyLink(plannedEventId));
                      toast.info("Link copied", { delay: 2000 });
                    }}
                  >
                    <IoIosCopy size="30" className="me-2" />
                    Copy Link
                  </Button>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <h2>Manage your registration:</h2>
                  <Button
                    className="mt-2"
                    variant="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      ConfirmModalHelper.handleShowConfirm(
                        (s) => this.setState(s),
                        "Are you sure you want to leave this league?",
                        () => this.handleLeaveEvent()
                      );
                    }}
                  >
                    LEAVE THE LEAGUE
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2 mb-5">
                <Col>{texts.cancellationPolicy(registerByDate)}</Col>
              </Row>
            </React.Fragment>
          )}
          <Row>
            <Col>
              <h2>League Rules:</h2>
              <Rules rules={eventRules} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStripePromise(Join);
