import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import _ from "lodash";

import { getSideName } from "../../common/sideNameBuilder";

class Standings extends React.Component {
  state = {};

  render() {
    const { twoColumn, standings } = this.props;

    const groups = _(standings)
      .map((s) => s.group)
      .uniq()
      .value();

    if (groups.length === 1) {
      const half = Math.ceil(standings.length / 2);
      const standingsColumns = twoColumn ? [_.take(standings, half), _.takeRight(standings, standings.length - half)] : [standings];

      return (
        <React.Fragment>
          <Row>
            <h2 className="text-center">Standings:</h2>
          </Row>
          <Row>
            {standingsColumns.map((sc, i) => (
              <Col key={i}>
                <GetStandingsColumn standings={sc} />
              </Col>
            ))}
          </Row>
        </React.Fragment>
      );
    } else {
      const group1 = _.filter(standings, (s) => s.group === groups[0]);
      const group2 = _.filter(standings, (s) => s.group === groups[1]);

      return (
        <React.Fragment>
          <Row>
            <h2 className="text-center">Standings {groups[0]}:</h2>
          </Row>
          <Row>
            <Col>
              <GetStandingsColumn standings={group1} />
            </Col>
          </Row>
          <Row>
            <h2 className="text-center">Standings {groups[1]}:</h2>
          </Row>
          <Row>
            <Col>
              <GetStandingsColumn standings={group2} />
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}

function GetStandingsColumn(props) {
  return (
    <table className="table table-hover table-sm">
      <thead className="table-primary">
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col">W</th>
          <th scope="col">PT</th>
          <th scope="col">TB</th>
          <th scope="col">MP</th>
        </tr>
      </thead>
      <tbody>
        {props.standings.map((s) => {
          return (
            <tr key={s.rank}>
              <th>{s.rank}</th>
              <td className="pr-4 pl-4">{getSideName(s.sideName)}</td>
              <td className="text-right">{`${s.wins}${(s.bye && "*") || ""}`}</td>
              <td className="text-right">{s.points}</td>
              <td className="text-right">{s.tieBreak}</td>
              <td className="text-right">{s.played}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Standings;
