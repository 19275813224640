import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { toast } from "react-toastify";

import RallyRecordApi from "../../api/rallyRecordApi";

class MatchSelector extends React.Component {
  state = { processing: false, isLoading: true, matches: [] };

  async componentDidMount() {
    this.setState({ processing: true });

    const { success, data: matches, message } = await RallyRecordApi.getMatches(this.props.child.id);

    if (!success) {
      this.setState({ processing: false, isLoading: false });
      toast.error(message);
      return;
    }

    this.setState({ matches, processing: false, isLoading: false });
  }

  render() {
    const { child } = this.props;

    return (
      <React.Fragment>
        <Container fluid="md">
          <Row>
            <Col>
              Match selector: {child.id} {child.name}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default MatchSelector;
