import ProgressBar from "react-bootstrap/ProgressBar";

const DualProgressBar = (props) => {
  const {
    value: { refVal, val1, perc1, val2, perc2 },
    colour11,
    colour12,
    colour21,
    colour22,
  } = props;

  return (
    <ProgressBar>
      <ProgressBar
        now={val1}
        min={0}
        max={refVal}
        style={{ background: `-webkit-linear-gradient(left, ${colour11} 0%, ${colour12} 100%)` }}
        label={`${perc1}%`}
      />
      <ProgressBar
        now={val2}
        min={0}
        max={refVal}
        style={{ background: `-webkit-linear-gradient(left, ${colour21} 0%, ${colour22} 100%)` }}
        label={`${perc2}%`}
      />
    </ProgressBar>
  );
};

export const DualProgressBar1 = (props) => <DualProgressBar {...props} colour11="#36ae6a" colour12="#67c295" colour21="#672b89" colour22="#7c4299" />;

export const DualProgressBar2 = (props) => <DualProgressBar {...props} colour11="#c779b2" colour12="#dbb0d2" colour21="#f69e2c" colour22="#ee478a" />;

export const DualProgressBar3 = (props) => <DualProgressBar {...props} colour11="#36ae6a" colour12="#67c295" colour21="#f69e2c" colour22="#ee478a" />;

export const DualProgressBar4 = (props) => <DualProgressBar {...props} colour11="#c779b2" colour12="#dbb0d2" colour21="#672b89" colour22="#7c4299" />;

// export const DualProgressBar1 = (props) => <DualProgressBar {...props} colour11="#36ae6a" colour12="#67c295" colour21="#672b89" colour22="#7c4299" />;

// export const DualProgressBar2 = (props) => <DualProgressBar {...props} colour11="#c779b2" colour12="#dbb0d2" colour21="#f69e2c" colour22="#ee478a" />;

// export const DualProgressBar3 = (props) => <DualProgressBar {...props} colour11="#36ae6a" colour12="#67c295" colour21="#f69e2c" colour22="#ee478a" />;

// export const DualProgressBar4 = (props) => <DualProgressBar {...props} colour11="#c779b2" colour12="#dbb0d2" colour21="#672b89" colour22="#7c4299" />;
