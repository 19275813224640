import { PlayerApi } from "./atsApi";

class RallyRecordApi {
  constructor() {
    this.api = new PlayerApi("rally-record");
  }

  getChildren = () => this.api.getRequest("children");

  updateChildren = (children) => this.api.postRequest("children", { children });

  getMatches = (childId) => this.api.getRequest(`matches/${childId}`);
}

const rallyRecordApi = new RallyRecordApi();
export default rallyRecordApi;
