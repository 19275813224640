import React from "react";

import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

import { IoMdShare } from "react-icons/io";
import { LinkBuilder } from "./umpireCastLinkBuilder";

const MatchName = (props) => {
  const { eventName, scoreCastId } = props;

  const shareData = {
    title: eventName,
    text: `Check out this match: ${eventName}`,
    url: LinkBuilder.getMatchLink(scoreCastId),
  };

  return (
    <Stack direction="horizontal" gap="2">
      <h2>{eventName}</h2>
      <Button variant="outline-primary" onClick={async () => await navigator.share(shareData)}>
        <IoMdShare size="25" />
      </Button>
    </Stack>
  );
};

export default MatchName;
