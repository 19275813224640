import React from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Stack from "react-bootstrap/Stack";

import _ from "lodash";

import { IoMdCreate, IoMdCloseCircle, IoMdArrowUp, IoMdArrowDown, IoMdCopy, IoMdMove } from "react-icons/io";

import { FieldRow } from "../../common/viewControls";

class GroupView extends React.Component {
  state = { viewData: { sortBy: "number" } };

  handleChange = (e) => {
    const { viewData } = this.state;
    viewData[e.target.id] = e.target.value;
    this.setState({ viewData });
  };

  render() {
    const { sortBy } = this.state.viewData;
    const {
      group: { id, name, whatsAppGroupLink },
      registrations,
      handleEdit,
      handleDelete,
      handleNumberChange,
      handleDeleteRegistration,
      handleAssignRegistration,
    } = this.props;

    const filteredRegistrations = _.chain(registrations)
      .filter((r) => r.groupId === id)
      .orderBy([
        (r) => {
          switch (sortBy) {
            case "name":
              return r.name;
            case "number":
              return r.number;
            default:
              return r.fields[sortBy];
          }
        },
      ])
      .value();

    const fields = new Set();
    filteredRegistrations.forEach((r) => {
      Object.keys(r.fields).forEach((f) => fields.add(f));
    });

    const sortByOptions = {
      number: "Registration Order",
      name: "Name",
    };

    fields.forEach((f) => (sortByOptions[f] = f));

    return (
      <Col className="border border-primary m-0 pt-2">
        <Card border="light">
          <Card.Title>
            <Stack direction="horizontal" gap="1">
              {name}
              <Button size="sm" variant="outline-warning" onClick={handleEdit}>
                <IoMdCreate size="25" />
              </Button>
              <Button size="sm" variant="outline-danger" onClick={handleDelete}>
                <IoMdCloseCircle size="25" />
              </Button>
              <Button size="sm" variant="outline-primary" onClick={() => handleNumberChange(-1)}>
                <IoMdArrowUp size="25" />
              </Button>
              <Button size="sm" variant="outline-primary" onClick={() => handleNumberChange(1)}>
                <IoMdArrowDown size="25" />
              </Button>
            </Stack>
          </Card.Title>
          <Card.Body>
            <Table>
              <tbody>
                <FieldRow id="participants" label="Participants">
                  {filteredRegistrations.length}
                </FieldRow>
                <FieldRow id="whatsAppGroupLink" label="WhatsApp Group Link">
                  <React.Fragment>
                    <Stack direction="horizontal" gap="1">
                      {whatsAppGroupLink && (
                        <a href={whatsAppGroupLink} target="_blank" rel="noreferrer">
                          Open
                        </a>
                      )}
                      {!whatsAppGroupLink && "Not Set"}
                      <Button
                        size="sm"
                        variant="outline-primary"
                        disabled={!whatsAppGroupLink}
                        onClick={() => navigator.clipboard.writeText(whatsAppGroupLink)}
                      >
                        <IoMdCopy size="25" />
                      </Button>
                    </Stack>
                  </React.Fragment>
                </FieldRow>
                <FieldRow id="sortBy" label="Sort By">
                  <Form>
                    <Form.Select id="sortBy" onChange={this.handleChange} value={sortBy}>
                      {Object.keys(sortByOptions).map((d) => (
                        <option key={d} value={d}>
                          {sortByOptions[d]}
                        </option>
                      ))}
                    </Form.Select>
                  </Form>
                </FieldRow>
              </tbody>
            </Table>
            <Table>
              <tbody>
                {filteredRegistrations.map((r) => {
                  return (
                    <tr key={r.plannedEventRegistrationId}>
                      <td className="fw-bold" key="name">
                        <Stack direction="horizontal" gap="1">
                          <Button size="sm" variant="outline-danger">
                            <IoMdCloseCircle size="25" onClick={() => handleDeleteRegistration(r)} />
                          </Button>
                          <Button size="sm" variant="outline-primary" onClick={() => handleAssignRegistration(r)}>
                            <IoMdMove size="25" />
                          </Button>
                          {r.name}
                        </Stack>
                      </td>
                      {Object.keys(r.fields).map((k) => (
                        <td key={k}>{r.fields[k]}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    );
  }
}

export default GroupView;
