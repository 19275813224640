import { getPlayerApiUrl } from "../api/apiUrlBuilder";

const share = {
  whatsApp: function (name, discount, plannedEventId) {
    const nameMessage = `Hey, I've just signed up for a tennis league at *${name.replace("&", "and")}* and thought you might be interested!`;
    const discountMessage =
      discount > 0 ? ` Plus, anyone who joins this league round gets a *lifetime discount of ${discount * 100}%*  on all ATS events here.` : "";
    const url = `${getPlayerApiUrl()}/go/join-event/${plannedEventId}`;
    const urlMessage = ` It’s a great way to enjoy some competitive fun. Check it out and register here: ${url} . Would be awesome to play together!`;

    return `https://wa.me/?text=${encodeURI(`${nameMessage}${discountMessage}${urlMessage}`)}`;
  },

  navigateData: function (name, discount, plannedEventId) {
    const nameMessage = `Hey, I've just signed up for a tennis league at *${name.replace("&", "and")}* and thought you might be interested!`;
    const discountMessage =
      discount > 0 ? ` Plus, anyone who joins this league round gets a *lifetime discount of ${discount * 100}%*  on all ATS events here.` : "";
    const urlMessage = ` It’s a great way to enjoy some competitive fun. Check it out and register, would be awesome to play together!`;

    return {
      title: "Check out this league!",
      text: `${nameMessage}${discountMessage}${urlMessage}`,
      url: `${getPlayerApiUrl()}/go/join-event/${plannedEventId}`,
    };
  },

  copyLink: (plannedEventId) => `${getPlayerApiUrl()}/go/join-event/${plannedEventId}`,
};

export default share;
