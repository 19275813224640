import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/esm/Stack";

import { toast } from "react-toastify";

import scheduledJobsApi from "../../api/scheduledJobsApi";
import ProcessingModal from "../../common/processingModal";

class ScheduleJobs extends React.Component {
  state = {
    processing: false,
    isLoading: true,
    scheduledJobs: [],
  };

  async componentDidMount() {
    const { success, data: scheduledJobs, message } = await scheduledJobsApi.getAll();

    if (!success) {
      this.setState({ isLoading: false });
      toast.error(message);
      return;
    }

    this.setState({ isLoading: false, scheduledJobs });
  }

  handleExecute = async (jobId, jobName) => {
    this.setState({ processing: true });

    const { success, message } = await scheduledJobsApi.executeOne(jobId);
    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ processing: false });
    toast.info(`Job '${jobName}' executed.`);
  };

  render() {
    const { processing, isLoading, scheduledJobs } = this.state;

    return (
      <React.Fragment>
        <ProcessingModal isOpen={processing} />
        <Container fluid="md">
          <Row>
            <Col>
              <h1>Scheduled Jobs</h1>
              <ProcessingModal isOpen={isLoading} text="Loading..." />
              <ListGroup>
                {scheduledJobs.map((job) => (
                  <ListGroup.Item action key={job.id} as="div">
                    <Stack direction="horizontal" gap="2">
                      <Button variant="outline-primary" size="sm" onClick={(e) => this.handleExecute(job.id, job.name)}>
                        EXEC
                      </Button>
                      <Stack direction="vertical" gap="2">
                        <div>{job.name}</div>
                        <div>{job.cron}</div>
                      </Stack>
                    </Stack>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default ScheduleJobs;
