import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import _ from "lodash";

import { toast } from "react-toastify";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { autoSizeAll } from "../../common/agGridUtils";

import FindOpponentApi from "../../api/findOpponentApi";
import { withStaticData } from "../../common/staticData";

class FindMatch extends React.Component {
  state = {
    loading: true,
    filter: {
      date: new Date().toISOString().slice(0, 10),
      matchTypeId: "any",
    },
    matches: [],
  };

  async componentDidMount() {
    await this.refresh(this.state.filter);
  }

  handleChange = async (e) => {
    const filter = { ...this.state.filter };

    filter[e.currentTarget.id] = e.currentTarget.value;
    this.setState({ loading: true, filter });
    await this.refresh(filter);
  };

  refresh = async (filter) => {
    const { success, data: matches, message } = await FindOpponentApi.findAvailableMatches(filter);

    if (!success) {
      toast.error(message);
      this.setState({ loading: false });
      return;
    }

    const orderedMatches = _.chain(matches).orderBy(["date", "time"], ["asc", "asc"]).value();

    this.setState({ loading: false, matches: orderedMatches });
  };

  handleAskToJoin = async (e, availableMatchId, organizerName) => {
    e.stopPropagation();

    const { success, message } = await FindOpponentApi.askToJoinAvailableMatch(availableMatchId);
    if (!success) {
      toast.error(message);
      return;
    }

    toast.success(`${organizerName} was notified that you are available to play and will contact you if they are yet to find another player.`);
  };

  render() {
    const { loading, filter, matches } = this.state;
    const { staticData } = this.props;

    const buttonRenderer = (props) => (
      <>
        <Button size="sm" variant="info" onClick={(e) => this.handleAskToJoin(e, props.data.availableMatchId, props.data.organizerName)}>
          I CAN PLAY
        </Button>
      </>
    );

    return (
      <Container fluid className="ag-theme-alpine">
        <Row>
          <Col>
            <h1>Find Opponent - Find a Match</h1>
            <br />
            {loading && <Spinner animation="border" role="status" />}
            {!loading && (
              <>
                <div className="form-group mt-3">
                  <label htmlFor="date">Date:</label>
                  <select id="date" className="form-select" value={filter.date} onChange={this.handleChange}>
                    {Object.keys(staticData.dates).map((d) => (
                      <option key={d} value={d}>
                        {staticData.dates[d]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="matchTypeId">Match type:</label>
                  <select id="matchTypeId" className="form-select" value={filter.matchTypeId} onChange={this.handleChange}>
                    {Object.keys(staticData.matchTypes).map((d) => (
                      <option key={d} value={d}>
                        {staticData.matchTypes[d]}
                      </option>
                    ))}
                  </select>
                </div>
                <br />
                <AgGridReact
                  rowData={matches}
                  rowStyle={{ cursor: "pointer" }}
                  domLayout="autoHeight"
                  onGridSizeChanged={autoSizeAll}
                  onFirstDataRendered={autoSizeAll}
                  frameworkComponents={{ buttonRenderer: buttonRenderer }}
                  overlayNoRowsTemplate="No matches found for specified filters"
                >
                  <AgGridColumn field="date" headerName="Date" valueFormatter={(p) => staticData.dates[p.data.date]}></AgGridColumn>
                  <AgGridColumn field="time" headerName="Time"></AgGridColumn>
                  <AgGridColumn field="courtId" headerName="Court" valueFormatter={(p) => staticData.courts[p.data.courtId]}></AgGridColumn>
                  <AgGridColumn field="matchType" headerName="MatchType" valueFormatter={(p) => staticData.matchTypes[p.data.matchTypeId]}></AgGridColumn>
                  <AgGridColumn field="organizerName" headerName="Name"></AgGridColumn>
                  <AgGridColumn headerName="" cellRenderer="buttonRenderer" />
                </AgGridReact>
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStaticData(FindMatch);
