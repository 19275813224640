import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Stack from "react-bootstrap/Stack";
import Table from "react-bootstrap/Table";

import { IoIosTennisball, IoMdAdd, IoMdRemove, IoMdRefresh, IoMdTennisball } from "react-icons/io";

import { getSide1Score, getSide2Score } from "../../common/formatGameScore";

class Score extends React.Component {
  state = {};

  setServe = (side2Serve, serverName) => {
    const { scoreCastFrame } = this.props;
    scoreCastFrame.side2Serve = side2Serve;
    scoreCastFrame.serverName = serverName;
    this.setState({ scoreCastFrame });
    return scoreCastFrame;
  };

  setSet1 = (side1, up) => {
    const { scoreCastFrame } = this.props;
    if (side1 && up) scoreCastFrame.side1Set1 = scoreCastFrame.side1Set1 + 1;
    if (side1 && !up) scoreCastFrame.side1Set1 = scoreCastFrame.side1Set1 - 1;
    if (!side1 && up) scoreCastFrame.side2Set1 = scoreCastFrame.side2Set1 + 1;
    if (!side1 && !up) scoreCastFrame.side2Set1 = scoreCastFrame.side2Set1 - 1;
    this.setState({ scoreCastFrame });
    return scoreCastFrame;
  };

  setSet1Win = (side1) => {
    const { scoreCastFrame } = this.props;
    if (side1) scoreCastFrame.side1Set1 = scoreCastFrame.side1Set1 + 1;
    if (!side1) scoreCastFrame.side2Set1 = scoreCastFrame.side2Set1 + 1;
    scoreCastFrame.side1Game = 0;
    scoreCastFrame.side2Game = 0;
    scoreCastFrame.side1Tiebreak = 0;
    scoreCastFrame.side2Tiebreak = 0;
    this.setState({ scoreCastFrame });
    return scoreCastFrame;
  };

  setSet2 = (side1, up) => {
    const { scoreCastFrame } = this.props;
    if (side1 && up) scoreCastFrame.side1Set2 = scoreCastFrame.side1Set2 + 1;
    if (side1 && !up) scoreCastFrame.side1Set2 = scoreCastFrame.side1Set2 - 1;
    if (!side1 && up) scoreCastFrame.side2Set2 = scoreCastFrame.side2Set2 + 1;
    if (!side1 && !up) scoreCastFrame.side2Set2 = scoreCastFrame.side2Set2 - 1;
    this.setState({ scoreCastFrame });
    return scoreCastFrame;
  };

  setSet2Win = (side1) => {
    const { scoreCastFrame } = this.props;
    if (side1) scoreCastFrame.side1Set2 = scoreCastFrame.side1Set2 + 1;
    if (!side1) scoreCastFrame.side2Set2 = scoreCastFrame.side2Set2 + 1;
    scoreCastFrame.side1Game = 0;
    scoreCastFrame.side2Game = 0;
    scoreCastFrame.side1Tiebreak = 0;
    scoreCastFrame.side2Tiebreak = 0;
    this.setState({ scoreCastFrame });
    return scoreCastFrame;
  };

  setSet3 = (side1, up) => {
    const { scoreCastFrame } = this.props;
    if (side1 && up) scoreCastFrame.side1Set3 = scoreCastFrame.side1Set3 + 1;
    if (side1 && !up) scoreCastFrame.side1Set3 = scoreCastFrame.side1Set3 - 1;
    if (!side1 && up) scoreCastFrame.side2Set3 = scoreCastFrame.side2Set3 + 1;
    if (!side1 && !up) scoreCastFrame.side2Set3 = scoreCastFrame.side2Set3 - 1;
    this.setState({ scoreCastFrame });
    return scoreCastFrame;
  };

  setSet3Win = (side1) => {
    const { scoreCastFrame } = this.props;
    if (side1) scoreCastFrame.side1Set3 = scoreCastFrame.side1Set3 + 1;
    if (!side1) scoreCastFrame.side2Set3 = scoreCastFrame.side2Set3 + 1;
    scoreCastFrame.side1Game = 0;
    scoreCastFrame.side2Game = 0;
    scoreCastFrame.side1Tiebreak = 0;
    scoreCastFrame.side2Tiebreak = 0;
    this.setState({ scoreCastFrame });
    return scoreCastFrame;
  };

  setGame = (side1, up) => {
    const { scoreCastFrame } = this.props;
    if (side1 && up) scoreCastFrame.side1Game = scoreCastFrame.side1Game + 1;
    if (side1 && !up) scoreCastFrame.side1Game = scoreCastFrame.side1Game - 1;
    if (!side1 && up) scoreCastFrame.side2Game = scoreCastFrame.side2Game + 1;
    if (!side1 && !up) scoreCastFrame.side2Game = scoreCastFrame.side2Game - 1;
    this.setState({ scoreCastFrame });
    return scoreCastFrame;
  };

  setTiebreak = (side1, up) => {
    const { scoreCastFrame } = this.props;
    if (side1 && up) scoreCastFrame.side1Tiebreak = scoreCastFrame.side1Tiebreak + 1;
    if (side1 && !up) scoreCastFrame.side1Tiebreak = scoreCastFrame.side1Tiebreak - 1;
    if (!side1 && up) scoreCastFrame.side2Tiebreak = scoreCastFrame.side2Tiebreak + 1;
    if (!side1 && !up) scoreCastFrame.side2Tiebreak = scoreCastFrame.side2Tiebreak - 1;
    this.setState({ scoreCastFrame });
    return scoreCastFrame;
  };

  resetGame = () => {
    const { scoreCastFrame } = this.props;
    scoreCastFrame.side1Game = 0;
    scoreCastFrame.side2Game = 0;
    this.setState({ scoreCastFrame });
    return scoreCastFrame;
  };

  resetTiebreak = () => {
    const { scoreCastFrame } = this.props;
    scoreCastFrame.side1Tiebreak = 0;
    scoreCastFrame.side2Tiebreak = 0;
    this.setState({ scoreCastFrame });
    return scoreCastFrame;
  };

  render() {
    const {
      side1Name,
      side2Name,

      viewersCount,

      scoreCastFrame: {
        side2Serve,
        serverName,
        side1Set1,
        side2Set1,
        side1Set2,
        side2Set2,
        side1Set3,
        side2Set3,
        side1Game,
        side2Game,
        side1Tiebreak,
        side2Tiebreak,
      },
      handlePushNewFrame,
    } = this.props;

    return (
      <Table>
        <thead>
          <tr>
            <th>
              <h4>STR: {viewersCount}</h4>
            </th>
            <th colSpan={2}>
              <Stack gap="1" direction="horizontal" as="span">
                <ButtonGroup>
                  {side1Name.split("&").map((name, i) => (
                    <Button
                      key={i}
                      variant={serverName === name ? "info" : "outline-primary"}
                      onClick={async () => await handlePushNewFrame(this.setServe(false, name))}
                    >
                      {name}
                    </Button>
                  ))}
                </ButtonGroup>
                {!side2Serve && <IoIosTennisball size="30" color="magenta" />}
              </Stack>
            </th>
            <th colSpan={2}>
              <Stack gap="1" direction="horizontal">
                <ButtonGroup>
                  {side2Name.split("&").map((name, i) => (
                    <Button
                      key={i}
                      variant={serverName === name ? "info" : "outline-primary"}
                      onClick={async () => await handlePushNewFrame(this.setServe(true, name))}
                    >
                      {name}
                    </Button>
                  ))}
                </ButtonGroup>
                {side2Serve && <IoIosTennisball size="30" color="magenta" />}
              </Stack>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              <h4>Set 1</h4>
            </th>
            <td>
              <h4>{side1Set1}</h4>
            </td>
            <td>
              <ButtonGroup className="ms-1">
                <Button variant="outline-primary" onClick={async () => await handlePushNewFrame(this.setSet1(true, true))}>
                  <IoMdAdd size="20" />
                </Button>
                <Button variant="outline-danger" disabled={side1Set1 === 0} onClick={async () => await handlePushNewFrame(this.setSet1(true, false))}>
                  <IoMdRemove size="20" />
                </Button>
                <Button variant="outline-warning" onClick={async () => await handlePushNewFrame(this.setSet1Win(true))}>
                  <IoMdTennisball size="20" />
                </Button>
              </ButtonGroup>
            </td>
            <td>
              <h4>{side2Set1}</h4>
            </td>
            <td>
              <ButtonGroup className="ms-1">
                <Button variant="outline-primary" onClick={async () => await handlePushNewFrame(this.setSet1(false, true))}>
                  <IoMdAdd size="20" />
                </Button>
                <Button variant="outline-danger" disabled={side2Set1 === 0} onClick={async () => await handlePushNewFrame(this.setSet1(false, false))}>
                  <IoMdRemove size="20" />
                </Button>
                <Button variant="outline-warning" onClick={async () => await handlePushNewFrame(this.setSet1Win(false))}>
                  <IoMdTennisball size="20" />
                </Button>
              </ButtonGroup>
            </td>
          </tr>
          <tr>
            <th>
              <h4>Set 2</h4>
            </th>
            <td>
              <h4>{side1Set2}</h4>
            </td>
            <td>
              <ButtonGroup className="ms-1">
                <Button variant="outline-primary" onClick={async () => await handlePushNewFrame(this.setSet2(true, true))}>
                  <IoMdAdd size="20" />
                </Button>
                <Button variant="outline-danger" disabled={side1Set2 === 0} onClick={async () => await handlePushNewFrame(this.setSet2(true, false))}>
                  <IoMdRemove size="20" />
                </Button>
                <Button variant="outline-warning" onClick={async () => await handlePushNewFrame(this.setSet2Win(true))}>
                  <IoMdTennisball size="20" />
                </Button>
              </ButtonGroup>
            </td>
            <td>
              <h4>{side2Set2}</h4>
            </td>
            <td>
              <ButtonGroup className="ms-1">
                <Button variant="outline-primary" onClick={async () => await handlePushNewFrame(this.setSet2(false, true))}>
                  <IoMdAdd size="20" />
                </Button>
                <Button variant="outline-danger" disabled={side2Set2 === 0} onClick={async () => await handlePushNewFrame(this.setSet2(false, false))}>
                  <IoMdRemove size="20" />
                </Button>
                <Button variant="outline-warning" onClick={async () => await handlePushNewFrame(this.setSet2Win(false))}>
                  <IoMdTennisball size="20" />
                </Button>
              </ButtonGroup>
            </td>
          </tr>
          <tr>
            <th>
              <h4>Set 3</h4>
            </th>
            <td>
              <h4>{side1Set3}</h4>
            </td>
            <td>
              <ButtonGroup className="ms-1">
                <Button variant="outline-primary" onClick={async () => await handlePushNewFrame(this.setSet3(true, true))}>
                  <IoMdAdd size="20" />
                </Button>
                <Button variant="outline-danger" disabled={side1Set3 === 0} onClick={async () => await handlePushNewFrame(this.setSet3(true, false))}>
                  <IoMdRemove size="20" />
                </Button>
                <Button variant="outline-warning" onClick={async () => await handlePushNewFrame(this.setSet3Win(true))}>
                  <IoMdTennisball size="20" />
                </Button>
              </ButtonGroup>
            </td>
            <td>
              <h4>{side2Set3}</h4>
            </td>
            <td>
              <ButtonGroup className="ms-1">
                <Button variant="outline-primary" onClick={async () => await handlePushNewFrame(this.setSet3(false, true))}>
                  <IoMdAdd size="20" />
                </Button>
                <Button variant="outline-danger" disabled={side2Set3 === 0} onClick={async () => await handlePushNewFrame(this.setSet3(false, false))}>
                  <IoMdRemove size="20" />
                </Button>
                <Button variant="outline-warning" onClick={async () => await handlePushNewFrame(this.setSet3Win(false))}>
                  <IoMdTennisball size="20" />
                </Button>
              </ButtonGroup>
            </td>
          </tr>
          <tr>
            <th>
              <Stack gap="3" direction="horizontal">
                <h4>G</h4>
                <Button variant="outline-warning" onClick={async () => await handlePushNewFrame(this.resetGame(true))}>
                  <IoMdRefresh size="20" />
                </Button>
              </Stack>
            </th>
            <td>
              <h4>{getSide1Score(side1Game, side2Game)}</h4>
            </td>
            <td>
              <ButtonGroup className="ms-1">
                <Button variant="outline-primary" onClick={async () => await handlePushNewFrame(this.setGame(true, true))}>
                  <IoMdAdd size="20" />
                </Button>
                <Button variant="outline-danger" disabled={side1Game === 0} onClick={async () => await handlePushNewFrame(this.setGame(true, false))}>
                  <IoMdRemove size="20" />
                </Button>
              </ButtonGroup>
            </td>
            <td>
              <h4>{getSide2Score(side1Game, side2Game)}</h4>
            </td>
            <td>
              <ButtonGroup className="ms-1">
                <Button variant="outline-primary" onClick={async () => await handlePushNewFrame(this.setGame(false, true))}>
                  <IoMdAdd size="20" />
                </Button>
                <Button variant="outline-danger" disabled={side2Game === 0} onClick={async () => await handlePushNewFrame(this.setGame(false, false))}>
                  <IoMdRemove size="20" />
                </Button>
              </ButtonGroup>
            </td>
          </tr>
          <tr>
            <th>
              <Stack gap="1" direction="horizontal">
                <h4>TB</h4>
                <Button variant="outline-warning" onClick={async () => await handlePushNewFrame(this.resetTiebreak(true))}>
                  <IoMdRefresh size="20" />
                </Button>
              </Stack>
            </th>
            <td>
              <h4>{side1Tiebreak}</h4>
            </td>
            <td>
              <ButtonGroup className="ms-1">
                <Button variant="outline-primary" onClick={async () => await handlePushNewFrame(this.setTiebreak(true, true))}>
                  <IoMdAdd size="20" />
                </Button>
                <Button variant="outline-danger" disabled={side1Tiebreak === 0} onClick={async () => await handlePushNewFrame(this.setTiebreak(true, false))}>
                  <IoMdRemove size="20" />
                </Button>
              </ButtonGroup>
            </td>
            <td>
              <h4>{side2Tiebreak}</h4>
            </td>
            <td>
              <ButtonGroup className="ms-1">
                <Button variant="outline-primary" onClick={async () => await handlePushNewFrame(this.setTiebreak(false, true))}>
                  <IoMdAdd size="20" />
                </Button>
                <Button variant="outline-danger" disabled={side2Tiebreak === 0} onClick={async () => await handlePushNewFrame(this.setTiebreak(false, false))}>
                  <IoMdRemove size="20" />
                </Button>
              </ButtonGroup>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default Score;
