export function getSideName(sideName) {
  return (
    <div>
      {sideName.split("&").map((item, i) => (
        <div key={i}>{item}</div>
      ))}
    </div>
  );
}

export function getSideNameForOption(sideName) {
  return sideName.replace("&", " & ");
}
