import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import moment from "moment";

import ScoreCastApi from "../../api/scoreCastApi";

import { withQueryParams } from "../../common/queryParams";
import TextCommentary from "../../common/textCommentary";

import ViewScore from "./viewScore";

class Match extends React.Component {
  state = {
    eventName: "",
    startAt: new Date(),
    currentTime: new Date(),
    lastUpdate: new Date(),
    lastScoreChange: new Date(),

    viewerId: "na",
    viewersCount: 1,

    version: -1,
    messages: [],

    scoreInfo: {
      side1Name: "",
      side2Name: "",

      side2Serve: false,
      side1Set1: 0,
      side2Set1: 0,
      side1Set2: 0,
      side2Set2: 0,
      side1Set3: 0,
      side2Set3: 0,
      side1Game: 0,
      side2Game: 0,
      side1Tiebreak: 0,
      side2Tiebreak: 0,
    },
  };

  async componentDidMount() {
    await this.refreshData();

    if (!this.refreshInterval) this.refreshInterval = setInterval(async () => await this.refreshData(), 3000);
    if (!this.timerInterval) this.timerInterval = setInterval(() => this.timer(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
    clearInterval(this.timerInterval);
  }

  timer = () => {
    this.setState({ currentTime: new Date() });
  };

  refreshData = async () => {
    const { version, viewerId } = this.state;
    const { status, data: scoreCastInfo } = await ScoreCastApi.getOne(this.props.queryParams.id, version, viewerId);

    this.setState({ lastUpdate: new Date() });

    if (status === 204) return;
    if (status !== 200) return;

    this.setState({
      eventName: scoreCastInfo.eventName,
      startAt: new Date(scoreCastInfo.startAt),
      lastScoreChange: new Date(scoreCastInfo.lastScoreChange),

      viewerId: scoreCastInfo.viewerId,
      viewersCount: scoreCastInfo.viewersCount,

      version: scoreCastInfo.version,
      messages: scoreCastInfo.messages,

      scoreInfo: {
        side1Name: scoreCastInfo.side1Name,
        side2Name: scoreCastInfo.side2Name,

        side2Serve: scoreCastInfo.side2Serve,
        side1Set1: scoreCastInfo.side1Set1,
        side2Set1: scoreCastInfo.side2Set1,
        side1Set2: scoreCastInfo.side1Set2,
        side2Set2: scoreCastInfo.side2Set2,
        side1Set3: scoreCastInfo.side1Set3,
        side2Set3: scoreCastInfo.side2Set3,
        side1Game: scoreCastInfo.side1Game,
        side2Game: scoreCastInfo.side2Game,
        side1Tiebreak: scoreCastInfo.side1Tiebreak,
        side2Tiebreak: scoreCastInfo.side2Tiebreak,
      },
    });
  };

  render() {
    const {
      eventName,
      startAt,
      currentTime,
      lastUpdate,
      lastScoreChange,

      version,
      viewersCount,
      messages,

      scoreInfo,
    } = this.state;

    const delay = currentTime - lastUpdate;
    const changeDiff = moment.duration(lastScoreChange - currentTime);

    return (
      <Container fluid="md">
        <Row className="g-0">
          <Col>
            <h4>Score Cast - {eventName}</h4>
          </Col>
        </Row>
        <Row className="g-0">
          <Col>
            <h6>
              Match starts at {startAt.toLocaleDateString()} {startAt.toLocaleTimeString()}
            </h6>
          </Col>
        </Row>
        <Row className="g-0">
          <Col>
            <ViewScore scoreInfo={scoreInfo} />
            {messages.count > 0 && <TextCommentary messages={messages} count={version}></TextCommentary>}
          </Col>
        </Row>
        <Row className="g-0 mt-1">
          <Col>
            {delay > 20000 ? (
              <div className="text-danger fw-bold">Disconnected, please check your mobile data or wifi connection</div>
            ) : (
              <div className="text-success fw-bold">Connected to UmpireCast.</div>
            )}
          </Col>
        </Row>
        <Row className="g-0">
          <Col>{viewersCount} viewers connected to the stream</Col>
        </Row>
        <Row className="g-0">
          <Col>Last score change: {changeDiff.humanize(true)}</Col>
        </Row>
      </Container>
    );
  }
}

export default withQueryParams(Match);
