import React from "react";

import { useQueryParams } from "../../common/queryParams";

import { Helmet } from "react-helmet-async";

import MainMenu from "../../components/mainMenu";

import StaticDataContext from "../../contexts/staticDataContext";

import EventsApi from "../../api/eventsApi";
import PlannedLeagueApi from "../../api/plannedLeagueApi";

import Dashboard from "./dashboard";
import League from "./league";
import Tournament from "./tournament";
import Championship from "./championship";
import EventsList from "./eventList";
import LeagueSelector from "./leagueSelector";
import PlannedLeague from "./plannedLeague";
import ScheduledJobs from "./scheduledJobs";
import { getChampionshipsLink, getLeaguesLink, getScheduledJobsLink, getTournamentsLink } from "./adminLinkBuilder";
import { getAppLink } from "../../common/appLinkBuilder";

const MenuItems = [
  {
    key: "dashboard",
    title: "Dashboard",
    url: getAppLink("admin"),
  },
  {
    key: "tournaments",
    title: "Tournaments",
    url: getTournamentsLink(),
  },
  {
    key: "leagues",
    title: "Leagues",
    url: getLeaguesLink(),
  },
  {
    key: "championships",
    title: "Championships",
    url: getChampionshipsLink(),
  },
  {
    key: "scheduled-jobs",
    title: "Scheduled Jobs",
    url: getScheduledJobsLink(),
  },
];

class AdminApp extends React.Component {
  state = {
    staticData: {
      times: {},
    },
  };

  async componentDidMount() {
    const { data } = await PlannedLeagueApi.getStaticData();
    this.setState({ staticData: data });
  }

  render() {
    const { staticData } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Admin Dashboard - ATS</title>
        </Helmet>
        <MainMenu appItems={MenuItems} />
        <StaticDataContext.Provider value={staticData}>
          <GetPage />
        </StaticDataContext.Provider>
      </React.Fragment>
    );
  }
}

function GetPage() {
  const queryParams = useQueryParams();
  const columns = [{ name: "name" }, { name: "state" }];

  if ("page" in queryParams) {
    if (queryParams.page === "tournaments")
      return <EventsList getData={() => EventsApi.getAllTournaments()} type="tournament" title="Tournaments" columns={columns} />;
    if (queryParams.page === "leagues") return <LeagueSelector />;
    if (queryParams.page === "championships")
      return <EventsList getData={() => EventsApi.getAllChampionships()} type="championship" title="Championships" columns={columns} />;

    if (queryParams.page === "tournament")
      return <Tournament name={queryParams.name} eventId={queryParams.eventId} plannedEventId={queryParams.plannedEventId} />;
    if (queryParams.page === "league") return <League name={queryParams.name} eventId={queryParams.eventId} plannedEventId={queryParams.plannedEventId} />;
    if (queryParams.page === "planned-league") return <PlannedLeague plannedLeagueId={queryParams.id} eventSeriesId={queryParams.eventSeriesId} />;
    if (queryParams.page === "championship")
      return <Championship name={queryParams.name} eventId={queryParams.eventId} plannedEventId={queryParams.plannedEventId} />;
    if (queryParams.page === "scheduled-jobs") return <ScheduledJobs />;
  }

  return <Dashboard />;
}

export default AdminApp;
