import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { getPageLink } from "../../common/appLinkBuilder";

class EventList extends React.Component {
  state = {
    loaded: false,
    events: [],
  };

  async componentDidMount() {
    const { data: events } = await this.props.getData();
    this.setState({ events, loaded: true });
  }

  onFixColumnSize = (params) => {
    params.api.sizeColumnsToFit();
  };

  onRowClicked = (params) => {
    let { name, eventId, plannedEventId } = params.data;
    eventId = eventId == null ? "" : eventId;
    plannedEventId = plannedEventId == null ? "" : plannedEventId;
    window.location.assign(getPageLink("admin", this.props.type, { name: name, eventId: eventId, plannedEventId: plannedEventId }));
  };

  render() {
    return (
      <Container className="ag-theme-alpine">
        <Row className="justify-content-md-center">
          <Col lg="10">
            <h1>{this.props.title}</h1>
            <br />
            {!this.state.loaded && <Spinner animation="border" role="status" />}
            {this.state.loaded && (
              <AgGridReact
                rowData={this.state.events}
                rowStyle={{ cursor: "pointer" }}
                domLayout="autoHeight"
                onGridSizeChanged={this.onFixColumnSize}
                onFirstDataRendered={this.onFixColumnSize}
                onRowClicked={this.onRowClicked}
              >
                {this.props.columns.map((c) => {
                  return <AgGridColumn key={c.name} field={c.name}></AgGridColumn>;
                })}
              </AgGridReact>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EventList;
