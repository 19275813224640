import React, { Component } from "react";

import _ from "lodash";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";

import MatchScore from "./matchScore";

class UserControls extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const { scoreTypeId, validNewRoundRequests, matches } = this.props;

    const matchesWithScores = _.chain(matches)
      .filter((m) => m.score)
      .groupBy("round")
      .map((value, key) => ({ round: key, matches: value }))
      .orderBy("round", "desc")
      .value();

    const matchesWithoutScores = _.chain(matches)
      .filter((m) => !m.score)
      .groupBy("round")
      .map((value, key) => ({ round: key, matches: value }))
      .orderBy("round", "desc")
      .value();

    return (
      <React.Fragment>
        <Row>
          {validNewRoundRequests.map((r) => (
            <Col key={r.name}>
              <Button variant="outline-primary" size="sm" onClick={() => this.props.onNewRound(r)}>
                {r.name}
              </Button>
            </Col>
          ))}
        </Row>

        {matches.length > 0 && (
          <Row className="mt-2">
            <Col>
              <Tabs>
                <Tab eventKey="home" title="Missing Scores">
                  <Table size="sm">
                    <tbody>
                      {matchesWithoutScores.map((r) => (
                        <React.Fragment key={r.round}>
                          <tr>
                            <td colSpan={4} className="text-center table-primary">
                              <h5>Round {r.round} matches</h5>
                            </td>
                          </tr>
                          {r.matches.map((m) => (
                            <MatchScore key={m.matchId} match={m} scoreTypeId={scoreTypeId} onSubmitScore={this.props.onNewScore} />
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="profile" title="Entered Scores">
                  <Table size="sm">
                    <tbody>
                      {matchesWithScores.map((r) => (
                        <React.Fragment key={r.round}>
                          <tr>
                            <td colSpan={4} className="text-center table-primary">
                              <h5>Round {r.round} matches</h5>
                            </td>
                          </tr>
                          {r.matches.map((m) => (
                            <MatchScore key={m.matchId} match={m} scoreTypeId={scoreTypeId} onSubmitScore={this.props.onNewScore} />
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default UserControls;
