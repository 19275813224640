import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { toast } from "react-toastify";
import CourtAlertsApi from "../../api/courtAlertsApi";
import { SelectEdit } from "../../common/editControls";

class NotifyCourt extends React.Component {
  state = {
    courtId: "",
    date: "",
    time: "",
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleNotify = async (r) => {
    const { courtId, date, time } = this.state;
    const { success, message } = await CourtAlertsApi.postNotifyCourt({ courtId, date, time });

    if (!success) {
      toast.error(message);
      return;
    }

    toast.success("Thanks for reporting the court");
    this.setState({ courtId: "", date: "", time: "" });
  };

  render() {
    const { courts, dates, times } = this.props.staticData;
    const { courtId, date, time } = this.state;

    return (
      <React.Fragment>
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h1>Court Alerts - Notify Court</h1>
            </Col>
          </Row>
          <Row className="justify-content-flex-start">
            <Col>
              <form onSubmit={this.handleSubmit}>
                <SelectEdit id="court" label="Court" items={courts} value={courtId} onChange={(e) => this.setState({ courtId: e.currentTarget.value })} />
                <SelectEdit id="date" label="Date" items={dates} value={date} onChange={(e) => this.setState({ date: e.currentTarget.value })} />
                <SelectEdit id="time" label="Time" items={times} value={time} onChange={(e) => this.setState({ time: e.currentTarget.value })} />
                <div className="form-group mt-3">
                  <Button variant="primary" size="lg" onClick={() => this.handleNotify()} disabled={!courtId || !date || !time}>
                    Notify
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default NotifyCourt;
