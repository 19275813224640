import { PlayerApi } from "./atsApi";

class FindOpponentApi {
  constructor() {
    this.api = new PlayerApi("find-opponent");
  }

  getStaticData = () => this.api.getRequest("static-data");

  getMyMatches = () => this.api.getRequest("my-matches");
  getMyMatch = (availableMatchId) => this.api.getRequest(`my-matches/${availableMatchId}`);
  setMyMatch = (match) => this.api.postRequest("my-matches", match);
  deleteMyMatch = (availableMatchId) => this.api.deleteRequest(`my-matches/${availableMatchId}`);

  getMySlots = () => this.api.getRequest("my-slots");
  getMySlot = (availablePlayerId) => this.api.getRequest(`my-slots/${availablePlayerId}`);
  setMySlot = (match) => this.api.postRequest("my-slots", match);
  deleteMySlot = (availablePlayerId) => this.api.deleteRequest(`my-slots/${availablePlayerId}`);

  findAvailableMatches = (filter) => this.api.postRequest("find-available-match", filter);
  findAvailablePlayers = (filter) => this.api.postRequest("find-available-player", filter);

  askToJoinAvailableMatch = (availableMatchId) => this.api.postRequest(`join-available-match/${availableMatchId}`);
  askToJoinAvailablePlayer = (availablePlayerId, data) => this.api.postRequest(`join-available-player/${availablePlayerId}`, data);
}

const findOpponentApi = new FindOpponentApi();
export default findOpponentApi;
