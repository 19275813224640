import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { toast } from "react-toastify";

import { login } from "./auth";

import PlayerLoginApi from "../api/playerLoginApi";

import ProcessingModal from "./processingModal";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";

const phasePhone = 0;
const phaseCode = 1;
//const phaseName = 2;

class Login extends React.Component {
  state = {
    processing: false,
    phase: 0,
    authData: { phone: "", token: "" },
  };

  handleAuthChange = (e) => {
    const authData = { ...this.state.authData };
    authData[e.currentTarget.id] = e.currentTarget.value;
    this.setState({ authData });
  };

  find = async (e) => {
    if (e !== undefined) e.preventDefault();

    this.setState({ processing: true });

    const { success, message } = await PlayerLoginApi.find(this.state.authData.phone);
    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ processing: false, phase: 1 });
  };

  login = async (e) => {
    if (e !== undefined) e.preventDefault();

    this.setState({ processing: true });

    const { phone, token } = this.state.authData;

    const { success, data } = await PlayerLoginApi.login(phone, token);
    if (!success) {
      toast.error("Unable to sign in with provided verification code, please try again");
      this.setState({ phase: 0, authData: { phone: "", token: "" } });
      return;
    }

    login(data.sessionId, data.isAdmin, data.myPage, data.tag, data.permissions, true);
  };

  render() {
    const {
      processing,
      phase,
      authData: { phone, token },
    } = this.state;

    return (
      <React.Fragment>
        {processing && <ProcessingModal show={true} />}
        <Row>
          <Col>
            {phase === phasePhone && (
              <form onSubmit={this.find}>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone number
                  </label>
                  <input
                    type="phone"
                    required
                    className="form-control"
                    id="phone"
                    value={phone}
                    aria-describedby="phoneHelp"
                    onChange={(e) => this.handleAuthChange(e)}
                  />
                  <div className="invalid-feedback">Please enter a valid phone number</div>
                  <div id="phoneHelp" className="form-text">
                    Please enter as 07123456789: 07 followed by 9 digits
                  </div>
                </div>
                <Button variant="outline-primary" size="sm" type="submit">
                  Continue
                </Button>
              </form>
            )}
            {phase === phaseCode && (
              <form onSubmit={this.login}>
                <div className="mb-3">
                  <label className="form-label">A verification code has been sent to {phone} via text, please enter it below:</label>
                </div>
                <div className="mb-3">
                  <label htmlFor="token" className="form-label">
                    Verification code
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    id="token"
                    value={token}
                    aria-describedby="tokenHelp"
                    onChange={(e) => this.handleAuthChange(e)}
                  />
                  <div id="tokenHelp" className="form-text">
                    Please enter 6 digits without spaces
                  </div>
                </div>
                <ButtonGroup>
                  <Button variant="outline-secondary" size="sm" onClick={() => this.setState({ phase: 0 })}>
                    Back
                  </Button>
                  <Button variant="outline-secondary" size="sm" onClick={() => this.find()}>
                    Resend code
                  </Button>
                  <Button variant="outline-primary" size="sm" onClick={() => this.login()}>
                    Continue
                  </Button>
                </ButtonGroup>
              </form>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Login;
