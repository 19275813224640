import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import FadeIn from "react-fade-in";

import { Number, Text, Trophy } from "./utils";

class ChampionshipPage extends React.Component {
  state = {};

  render() {
    const {
      page,
      data: { bestRun, trophies },
      fadeTimeout,
    } = this.props;

    return (
      <React.Fragment>
        <Container className="mt-5">
          <Row>
            <Col>
              <h1>Championships</h1>
            </Col>
          </Row>
          <FadeIn delay={fadeTimeout} key={page}>
            <Row className="mt-4">
              <Col>
                Best run: <Number value={bestRun.name} />
              </Col>
            </Row>
            {bestRun.path.map((p) => (
              <Row className="mt-2" key={p.name}>
                <Col>
                  <div>
                    {p.name}: {p.result} {<Text value={p.opponent} />}
                  </div>
                  <div>
                    <Number value={p.score} />
                  </div>
                </Col>
              </Row>
            ))}
            {trophies && trophies.length > 0 && bestRun.path.length < 7 && (
              <Row className="mt-2">
                <Col>
                  Medals won:
                  {trophies.map((t) => (
                    <Trophy key={t.name} {...t} />
                  ))}
                </Col>
              </Row>
            )}
            {!trophies ||
              (trophies.length === 0 && (
                <Row className="mt-4">
                  <Col>
                    <Text value="Better luck next time!" />
                  </Col>
                </Row>
              ))}
          </FadeIn>
        </Container>
      </React.Fragment>
    );
  }
}

export default ChampionshipPage;
