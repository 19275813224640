import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { toast } from "react-toastify";

import { Helmet } from "react-helmet-async";

import Matches from "./../tournament/matches";
import Standings from "./../tournament/standings";

import TournamentApi from "../../api/tournamentApi";
import { withQueryParams } from "../../common/queryParams";
import { withSettings } from "../../common/withSettings";
import Stack from "react-bootstrap/esm/Stack";
import { getLink } from "../../common/appLinkBuilder";

class Tournament extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventId: props.queryParams.id,
      eventName: "",
      version: -1,
      scoreTypeId: "tournament-15",
      standings: [],
      allMatches: [],
      setScoreMatches: [],
      isAdmin: localStorage.getItem("atsIsAdmin") === "true",
      validNewRoundRequests: [],
      scoreData: { matchId: "", scoreId: "" },
      rules: [],
    };
  }

  async componentDidMount() {
    await this.loadStaticData();
    await this.refreshData();

    this.interval = setInterval(async () => this.refreshData(), 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadStaticData = async () => {
    const { eventId } = this.state;
    const { status, data: staticData } = await TournamentApi.getTournamentStaticData(eventId);

    if (status !== 200) {
      return;
    }
    this.setState({
      rules: staticData.rules,
    });
  };

  refreshData = async () => {
    const { eventId, version } = this.state;
    const { status, data: tournamentInfo, message } = await TournamentApi.getTournament(eventId, version);

    if (status === 204) return;

    if (status !== 200) {
      toast.error(message);
      return;
    }

    this.setState({
      eventName: tournamentInfo.name,
      standings: tournamentInfo.standings,
      allMatches: tournamentInfo.allMatches,
      setScoreMatches: tournamentInfo.setScoreMatches,
      version: tournamentInfo.version,
      scoreTypeId: tournamentInfo.scoreTypeId,
      validNewRoundRequests: tournamentInfo.validNewRoundRequests,
    });
  };

  render() {
    const { eventName, standings, allMatches } = this.state;

    const {
      settings: { siteWhitelabel },
    } = this.props;

    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=0.5, minimum-scale=0.5, maximum-scale=0.5, user-scalable=no" />
        </Helmet>
        <Container fluid="md">
          <Row className="g-0">
            <Col>
              <h1>
                <Stack direction="horizontal" gap="3">
                  <img src={getLink(`${siteWhitelabel}.logo.png`)} height="40" alt="" />
                  {eventName}
                </Stack>
              </h1>
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <Standings standings={standings} twoColumn />
            </Col>
            <Col className="ms-2">
              <Matches matches={allMatches} twoColumn />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSettings(withQueryParams(Tournament));
