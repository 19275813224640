import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

class EventStatus extends React.Component {
  state = {};

  render() {
    const { groupStatuses } = this.props;

    return (
      <>
        <Row>
          <Col>
            <Table size="sm" stripped="true" hover>
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Matches</th>
                  <th scope="col">Complete</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {groupStatuses.map((g) => {
                  return (
                    <tr key={g.name}>
                      <th scope="row" className="pr-4">
                        {g.name}
                      </th>
                      <td className="text-center">{g.matches}</td>
                      <td className="text-center">{g.complete}</td>
                      <td>{((100.0 * g.complete) / g.matches).toFixed(2)} %</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  }
}

export default EventStatus;
