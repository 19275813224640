import React from "react";

import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";

import _ from "lodash";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { autoSizeAll } from "../../common/agGridUtils";

class EventRegistrations extends React.Component {
  state = {};

  render() {
    const { plannedEventRegistrations, registrationFields, onDelete } = this.props;

    const registrationData = _.map(plannedEventRegistrations, (per) => {
      return { ...{ id: per.id, number: per.number, player: per.player }, ...per.fields };
    });

    const buttonRenderer = (props) => (
      <Button size="sm" variant="danger" onClick={() => onDelete(props.data.id)}>
        DEL
      </Button>
    );

    return (
      <Accordion flush className="ag-theme-alpine">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h3>Registrations</h3>
          </Accordion.Header>
          <Accordion.Body>
            <AgGridReact
              rowData={registrationData}
              domLayout="autoHeight"
              onGridSizeChanged={autoSizeAll}
              onFirstDataRendered={autoSizeAll}
              frameworkComponents={{ buttonRenderer: buttonRenderer }}
            >
              {onDelete && <AgGridColumn headerName="" cellRenderer="buttonRenderer" />}
              <AgGridColumn field="number" headerName="#" width={70} minWidth={70} sortable="true" />
              <AgGridColumn field="player" headerName="Player" sortable="true" />
              {registrationFields.map((c) => {
                return <AgGridColumn key={c} field={c} sortable="true"></AgGridColumn>;
              })}
            </AgGridReact>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
}

export default EventRegistrations;
