import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { Helmet } from "react-helmet-async";
import ProcessingModal from "../../common/processingModal";
import joinApi from "../../api/joinApi";
import { toast } from "react-toastify";
import Rules from "../tournament/rules";
import { getSessionId, isAuthenticated } from "../../common/auth";
import Register from "../../common/register";
import JoinEventFields from "./joinEventFields";
import { withStripePromise } from "../../common/withStripeSettings";
import { Elements } from "@stripe/react-stripe-js";
import { getPlayerApiUrl } from "../../api/apiUrlBuilder";
import LeagueFields from "./leagueFields";
import ConfirmModal, { ConfirmModalHelper } from "../../common/confirmModal";
import texts from "./joinTexts";

class Request extends React.Component {
  state = {
    processing: false,
    loaded: false,

    modal: "",
    confirmModalData: ConfirmModalHelper.Empty,

    clubId: this.props.data.clubId,
    name: "",
    address: "",
    courts: "",
    originalPrice: 5,
    price: 5,
    discount: 0,
    leagueName: "",
    startDate: new Date("2024-01-01"),
    endDate: new Date("2024-01-01"),
    registerByDate: new Date("2024-01-01"),
    clientSecret: "",
    eventRules: [],
    registrationFields: [],
  };

  async componentDidMount() {
    this.setState({ processing: true });

    const { success, data, message } = await joinApi.getRequestData(this.state.clubId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    const {
      clubId,
      name,
      address,
      courts,
      originalPrice,
      price,
      discount,
      leagueName,
      startDate,
      endDate,
      registerByDate,
      clientSecret,
      eventRules,
      registrationFields,
    } = data;

    this.setState({
      processing: false,
      loaded: true,
      clubId,
      name,
      address,
      courts,
      originalPrice,
      price,
      discount,
      leagueName,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      registerByDate: new Date(registerByDate),
      clientSecret,
      eventRules,
      registrationFields: registrationFields.fields ?? [],
    });
  }

  handleSubmit = async (stripe, elements, data) => {
    if (!stripe || !elements) {
      return;
    }

    const { clubId } = this.state;
    const auth = getSessionId();
    const dataB64 = btoa(JSON.stringify(data));

    this.setState({ processing: true });

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${getPlayerApiUrl()}/join/stripe-request-league/${clubId}?AtsSessionId=${auth}&data=${dataB64}`,
      },
    });

    this.setState({ processing: false });

    if (error.type === "card_error" || error.type === "validation_error") {
      toast.error(error.message);
    } else {
      toast.error("An unexpected error occurred.");
    }
  };

  render() {
    const {
      processing,

      modal,
      confirmModalData,

      loaded,
      clubId,
      name,
      address,
      courts,
      originalPrice,
      price,
      discount,
      leagueName,
      startDate,
      endDate,
      registerByDate,
      clientSecret,
      eventRules,
      registrationFields,
    } = this.state;

    const { stripePromise } = this.props;

    const isAuth = isAuthenticated();

    if (!loaded) return <ProcessingModal show={true} text="Loading..." />;

    return (
      <React.Fragment>
        <Helmet>
          <title>Request a league - {name} - ATS</title>
        </Helmet>
        <ProcessingModal show={processing} />
        <ConfirmModal show={modal === ConfirmModalHelper.Name} data={confirmModalData} />
        <Container fluid="md">
          <Row>
            <Col>
              <h1>Request a league</h1>
              <LeagueFields
                name={name}
                address={address}
                courts={courts}
                originalPrice={originalPrice}
                price={price}
                discount={discount}
                leagueName={leagueName}
                startDate={startDate}
                endDate={endDate}
                registerByDate={registerByDate}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              {!isAuth && (
                <React.Fragment>
                  <h2>Sign in to request a league:</h2>
                  <Register inPlace={true} />
                </React.Fragment>
              )}
              {isAuth && (
                <React.Fragment>
                  <h2>Fill in the form to request a league:</h2>
                  {clientSecret && (
                    <Elements options={{ clientSecret, appearance: { theme: "stripe" } }} stripe={stripePromise}>
                      <JoinEventFields
                        buttonText="REQUEST AND REGISTER NOW"
                        clubId={clubId}
                        registrationFields={registrationFields}
                        price={price}
                        handleSubmit={(stripe, elements, data) =>
                          ConfirmModalHelper.handleShowConfirm(
                            (s) => this.setState(s),
                            texts.confirmCanUseCourts(name, registerByDate),
                            () => this.handleSubmit(stripe, elements, data)
                          )
                        }
                      />
                    </Elements>
                  )}
                </React.Fragment>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>{texts.cancellationPolicy(registerByDate)}</Col>
          </Row>
          <Row className="text-muted mt-2 mb-5">
            <Col>{texts.confirmPrivacy("REQUEST AND REGISTER NOW")}</Col>
          </Row>
          <Row>
            <Col>
              <h2>League Rules:</h2>
              <Rules rules={eventRules} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStripePromise(Request);
