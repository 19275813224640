import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import FadeIn from "react-fade-in";

import { Trophy } from "./utils";
import { withQueryParams } from "./../../common/queryParams";

class TrophiesPage extends React.Component {
  state = {};
  render() {
    const { page, name, year, data: trophies, fadeTimeout } = this.props;
    const { shared } = this.props.queryParams;

    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <h1>
              {shared ? `${name}'s ` : "Your"} {year} Trophy Cabinet
            </h1>
          </Col>
        </Row>
        <FadeIn delay={fadeTimeout} key={page}>
          {trophies &&
            Object.keys(trophies).map((t) => {
              return (
                <React.Fragment key={t}>
                  <Row className="mt-3">
                    <Col className="text-capitalize">{t}</Col>
                  </Row>
                  {trophies[t].map((tt) => (
                    <Row className="mt-0" key={tt.name}>
                      <Col>
                        <Trophy {...tt} />
                      </Col>
                    </Row>
                  ))}
                </React.Fragment>
              );
            })}
        </FadeIn>
      </Container>
    );
  }
}

export default withQueryParams(TrophiesPage);
