import { PlayerApi } from "./atsApi";

class PlannedTournamentApi {
  constructor() {
    this.api = new PlayerApi("planned-tournament");
  }

  getAll = () => this.api.getRequest("");
  getOne = (plannedTournamentId) => this.api.getRequest(`${plannedTournamentId}`);
  setOne = (plannedTournament) => this.api.postRequest("", plannedTournament);
  deleteOne = (plannedTournamentId) =>
    this.api.deleteRequest(`${plannedTournamentId}`, { 400: "Cannot delete tournament with active registrations, please delete registrations first." });

  copy = (plannedTournamentId) => this.api.postRequest(`${plannedTournamentId}/copy`);

  openRegistration = (plannedTournamentId) => this.api.postRequest(`${plannedTournamentId}/open`);
  closeRegistration = (plannedTournamentId) => this.api.postRequest(`${plannedTournamentId}/close`);
  unregister = (plannedTournamentId, plannedEventRegistrationId) => this.api.postRequest(`${plannedTournamentId}/unregister/${plannedEventRegistrationId}`);
  setTier = (plannedTournamentId, plannedEventRegistrationId, tierId) =>
    this.api.postRequest(`${plannedTournamentId}/set-tier/${plannedEventRegistrationId}/${tierId}`);
  checkIn = (plannedTournamentId, plannedEventRegistrationId) => this.api.postRequest(`${plannedTournamentId}/check-in/${plannedEventRegistrationId}`);

  uploadRegistrations = (plannedTournamentId, file) => {
    const formData = new FormData();
    formData.append("registrations", file);

    return this.api.postRequest(`${plannedTournamentId}/upload`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  uploadRegistration = (plannedTournamentId, name) => this.api.postRequest(`${plannedTournamentId}/upload/${name}`);

  start = (plannedTournamentId) => this.api.postRequest(`${plannedTournamentId}/start`, { 400: "Event with the same name already exists." });
}

const plannedTournamentApi = new PlannedTournamentApi();
export default plannedTournamentApi;
