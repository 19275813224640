import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { toast } from "react-toastify";

import { Link } from "react-router-dom";

import { handleCopyNo, handleCopyAz } from "../../common/copyRegistrations";

import EventsApi from "../../api/eventsApi";

import EventInfo from "./eventInfo";
import EventStatus from "./eventStatus";
import EventRegistrations from "./eventRegistrations";
import Regenerate from "./regenerate";
import UnconfirmedScores from "./unconfirmedScores";
import { getPlannedEventLink, getActiveEventLink } from "./adminLinkBuilder";

class Dashboard extends React.Component {
  state = {
    loaded: false,

    activeEvents: [],
    openRegistrations: [],
  };

  async componentDidMount() {
    const { success: alSuccess, data: activeEvents, message: alMessage } = await EventsApi.getActiveEvents();
    const { success: orSuccess, data: openRegistrations, message: orMessage } = await EventsApi.getOpenRegistrations();

    if (!alSuccess) {
      toast.error(alMessage);
    }

    if (!orSuccess) {
      toast.error(orMessage);
    }

    this.setState({
      loaded: true,

      activeEvents: activeEvents ?? [],
      openRegistrations: openRegistrations ?? [],
    });
  }

  render() {
    const { activeEvents: activeLeagues, openRegistrations } = this.state;

    return (
      <Container>
        <Row>
          <Col lg="6" xxl="4" className="border border-primary m-0 pt-2">
            <h5>
              <span className="pe-4">Admin Controls</span>
              <Regenerate />
            </h5>
            <UnconfirmedScores />
          </Col>
          {openRegistrations
            .sort((a, b) => a.registrationFields.length - b.registrationFields.length)
            .map((pe) => {
              const { lg, xxl } = getColSpan(pe.registrationFields.length);
              return (
                <Col key={pe.key} lg={lg} xxl={xxl} className="border border-primary m-0 pt-2">
                  <Link to={getPlannedEventLink(pe)}>
                    <h5>{pe.name}</h5>
                  </Link>
                  <EventInfo
                    state={pe.state}
                    startDate={pe.startDate}
                    endDate={pe.endDate}
                    participantCount={pe.plannedEventRegistrations.length}
                    onCopyNo={() => handleCopyNo(pe.plannedEventRegistrations)}
                    onCopyAz={() => handleCopyAz(pe.plannedEventRegistrations)}
                  />
                  <EventRegistrations plannedEventRegistrations={pe.plannedEventRegistrations} registrationFields={pe.registrationFields} />
                </Col>
              );
            })}
          {activeLeagues.map((al) => {
            return (
              <Col key={al.key} lg={6} xxl={4} className="border border-primary m-0 pt-2">
                <Link to={getActiveEventLink(al)}>
                  <h5>{al.name}</h5>
                </Link>
                <EventInfo state={al.state} startDate={al.startDate} endDate={al.endDate} participantCount={al.participantCount} />
                <EventStatus id={al.key} eventSeriesId={al.eventSeriesId} name={al.name} groupStatuses={al.groupStatuses} />
              </Col>
            );
          })}
        </Row>
      </Container>
    );
  }

  formatName() {
    return (
      <h3>
        {this.state.name} as of {this.state.asOf}
      </h3>
    );
  }
}

function getColSpan(fieldCount) {
  if (fieldCount === 0) return { lg: 6, xxl: 4 };
  else if (fieldCount <= 2) return { lg: 8, xxl: 6 };
  else return { lg: 12, xxl: 8 };
}

export default Dashboard;
