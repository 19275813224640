import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Stack from "react-bootstrap/Stack";

class Serve extends React.Component {
  state = {
    isSecondServe: false,
  };

  handleServeClick = async (outcome) => {
    const { onServeClick } = this.props;
    const { isSecondServe } = this.state;

    await onServeClick({ isSecondServe, outcome });
  };

  render() {
    const { serverName } = this.props;
    const { isSecondServe } = this.state;

    return (
      <Stack gap="1">
        <Stack direction="horizontal" gap="4">
          <ButtonGroup>
            <Button variant={isSecondServe ? "outline-info" : "info"} onClick={() => this.setState({ isSecondServe: false })}>
              Serve 1
            </Button>
            <Button variant={isSecondServe ? "info" : "outline-info"} onClick={() => this.setState({ isSecondServe: true })}>
              Serve 2
            </Button>
          </ButtonGroup>
          <h4>{`by ${serverName ?? ""}`}</h4>
        </Stack>
        <Stack direction="horizontal">
          <ButtonGroup>
            <Button variant="outline-primary" onClick={() => this.handleServeClick("ace")}>
              Ace
            </Button>
            <Button variant="outline-primary" onClick={() => this.handleServeClick("win")}>
              Win
            </Button>
            <Button variant="outline-warning" onClick={() => this.handleServeClick("let")}>
              Let
            </Button>
            <Button variant="outline-danger" onClick={() => this.handleServeClick("net")}>
              Net
            </Button>
            <Button variant="outline-danger" onClick={() => this.handleServeClick("long")}>
              Long
            </Button>
            <Button variant="outline-danger" onClick={() => this.handleServeClick("wide")}>
              Wide
            </Button>
          </ButtonGroup>
        </Stack>
      </Stack>
    );
  }
}

export default Serve;
