export function getSide1Score(side1Game, side2Game) {
  if (side1Game === 0) return "0";
  if (side1Game === 1) return "15";
  if (side1Game === 2) return "30";
  if (side1Game === 3) return "40";

  if (side1Game === side2Game) return "40";
  if (side1Game > side2Game) return "AD";
  if (side1Game < side2Game) return "40";
}

export function getSide2Score(side1Game, side2Game) {
  if (side2Game === 0) return "0";
  if (side2Game === 1) return "15";
  if (side2Game === 2) return "30";
  if (side2Game === 3) return "40";

  if (side2Game === side1Game) return "40";
  if (side2Game > side1Game) return "AD";
  if (side2Game < side1Game) return "40";
}
