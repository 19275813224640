import React from "react";

import ListGroup from "react-bootstrap/ListGroup";

import _ from "lodash";

class Rules extends React.Component {
  render() {
    const { rules } = this.props;

    return (
      <ListGroup>
        {rules.map((e, i) => {
          return (
            <ListGroup.Item key={i}>
              {_.split(e, "<br/>")
                .map((l, ii) => <span key={ii}>{l}</span>)
                .reduce((t, c) => {
                  return (
                    <>
                      {t}
                      <br />
                      {c}
                    </>
                  );
                })}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  }
}

export default Rules;
