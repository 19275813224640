import React from "react";

import { Helmet } from "react-helmet-async";

import { useQueryParams } from "../../common/queryParams";

import MainMenu from "../../components/mainMenu";

import PlannedTournament from "./plannedTournament";
import Tournament from "./tournament";
import TournamentSelector from "./tournamentSelector";
import { Page, LinkBuilder } from "./tournamentLinkBuilder";

class TournamentApp extends React.Component {
  state = {};

  render() {
    const menuItems = [{ key: "tournaments", title: "Tournaments", url: LinkBuilder.getTournamentsLink() }];

    return (
      <React.Fragment>
        <Helmet>
          <title>Tournaments - ATS</title>
        </Helmet>
        <MainMenu appItems={menuItems} />
        <GetPage />
      </React.Fragment>
    );
  }
}

function GetPage() {
  const queryParams = useQueryParams();

  if ("page" in queryParams) {
    if (queryParams.page === Page.Tournaments) return <TournamentSelector />;
    if (queryParams.page === Page.Tournament) return <Tournament />;
    if (queryParams.page === Page.PlannedTournament) return <PlannedTournament />;
  }

  return <TournamentSelector />;
}

export default TournamentApp;
