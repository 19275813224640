import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import _ from "lodash";

import { toast } from "react-toastify";

import { getS3ApiUrl } from "../../api/apiUrlBuilder";
import DataApi from "../../api/dataApi";
import ScoreApi from "../../api/scoreApi";

import { isAuthenticated } from "../../common/auth";
import ProcessingModal from "../../common/processingModal";
import EnterScore from "../../common/scoreControls/enterScore";
import { withQueryParams } from "../../common/queryParams";

import MainMenu from "../../components/mainMenu";

class SetScoreApp extends React.Component {
  state = {
    processing: false,
    processingText: "Processing...",
    matches: [],
  };

  async componentDidMount() {
    if (!isAuthenticated()) return;

    await this.refresh();
  }

  refresh = async () => {
    this.setState({ processing: true });

    const { success, data: matches, message } = await DataApi.getMyMatchesData();

    if (!success) {
      toast.error(message);
      this.setState({ processing: false });

      return;
    }

    this.setState({ matches, processing: false });
  };

  handleSubmit = async (matchId, scoreTypeId, score) => {
    this.setState({ processing: true, processingText: "Verifying entered score, this might take a minute..." });

    const { status, message } = await ScoreApi.setScoreNew(matchId, scoreTypeId, score);
    const { embed } = this.props.queryParams;

    const action = embed
      ? () => window.parent.postMessage({ action: "GoHome" }, getS3ApiUrl())
      : () => (window.location.href = `/?$id=${new Date().getTime()}`);

    if (status === 200) {
      this.setState({ processing: true, processingText: "Score was processed and standings are updated" });
      this.interval = setInterval(async () => action(), 5000);
    } else if (status === 202) {
      this.setState({ processing: true, processingText: "Score accepted, but we will have to wait for your opponent to enter from their side" });
      this.interval = setInterval(async () => action(), 5000);
    } else if (status === 409) {
      this.setState({ processing: false });
      toast.error("Score you entered conflicts with your opponent's version, please check with them and try again");
    } else {
      this.setState({ processing: false });
      toast.error(message);
    }
  };

  render() {
    const { processing, processingText, matches } = this.state;
    const { matchId, embed } = this.props.queryParams;

    const groupedMatches = _.chain(matches)
      .filter((m) => !matchId || m.matchId === matchId)
      .map((m, i) => {
        return { ...m, index: i };
      })
      .orderBy("dueDate")
      .groupBy("eventName")
      .value();

    return (
      <React.Fragment>
        {!embed && <MainMenu />}
        {processing && <ProcessingModal show={true} text={processingText} />}
        <Container fluid="md">
          {Object.keys(groupedMatches).map((e) => {
            return (
              <React.Fragment key={e}>
                <Row>
                  <Col>
                    <h4>{e}</h4>
                  </Col>
                </Row>
                {groupedMatches[e].map((m) => (
                  <Row key={m.matchId}>
                    <Col sm="auto">
                      <EnterScore match={m} handleSubmit={(score) => this.handleSubmit(m.matchId, m.scoreTypeId, score)} />
                    </Col>
                  </Row>
                ))}
              </React.Fragment>
            );
          })}
        </Container>
      </React.Fragment>
    );
  }
}

export default withQueryParams(SetScoreApp);
