import React from "react";

const texts = {
  cancellationPolicy: (registerByDate) =>
    `Cancel any time before ${registerByDate.toLocaleTimeString()} on ${registerByDate.toLocaleDateString()} for a full refund.`,

  confirmCanUseCourts: (name, registerByDate) =>
    `By clicking "CONFIRM", you acknowledge that you are able to use the courts at ${name}. Remember that you can cancel any time before ${registerByDate.toLocaleTimeString()} on ${registerByDate.toLocaleDateString()} for a full refund.`,

  confirmPrivacy: (button) => (
    <React.Fragment>
      <small>
        By clicking "{button}", you confirm that you have read and agree to the terms of our{" "}
        <a href="https://ats.tennis/privacy-policy.pdf" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </small>
    </React.Fragment>
  ),

  share: (name, discount) => (
    <React.Fragment>
      Now that you are registered for this league, let's add more players: <strong>share</strong> this league with friends and challenge them to join you on the
      courts at <strong>{name}</strong>.
      {discount > 0 && (
        <React.Fragment>
          {" "}
          Anyone who registers for this league round will receive a <strong>lifetime discount of {discount * 100}%</strong> on all ATS events in {name}.
        </React.Fragment>
      )}
    </React.Fragment>
  ),
};

export default texts;
