import Image from "react-bootstrap/Image";
import { getLink } from "../../common/appLinkBuilder";
import { getScreenHeight, getScreenWidth } from "../../common/utils";

export const Background1 = () => {
  const width = getScreenWidth();
  const height = getScreenHeight();

  return width < height ? (
    <Image
      className="vh-100"
      width={width + 1}
      height={height + 1}
      style={{ objectFit: "cover", objectPosition: "right", opacity: 0.3 }}
      src={getLink("wrapped/1_3.svg")}
    />
  ) : (
    <Image
      className="vh-100"
      width={width}
      height={height}
      style={{ objectFit: "cover", objectPosition: "right", opacity: 0.3 }}
      src={getLink("wrapped/1_1.svg")}
    />
  );
};

export const Background2 = () => {
  const width = getScreenWidth();
  const height = getScreenHeight();

  return width < height ? (
    <Image className="vh-100" width={width + 1} height={height + 1} style={{ objectFit: "cover", objectPosition: "right" }} src={getLink("wrapped/2_3.svg")} />
  ) : (
    <Image className="vh-100" width={width} height={height} style={{ objectFit: "cover", objectPosition: "right" }} src={getLink("wrapped/2_1.svg")} />
  );
};

export const Background3 = () => {
  const width = getScreenWidth();
  const height = getScreenHeight();

  return width < height ? (
    <Image
      className="vh-100"
      width={width + 1}
      height={height + 1}
      style={{ objectFit: "cover", objectPosition: "centre", opacity: 0.75 }}
      src={getLink("wrapped/3_3.svg")}
    />
  ) : (
    <Image
      className="vh-100"
      width={width}
      height={height}
      style={{ objectFit: "cover", objectPosition: "right", opacity: 0.75 }}
      src={getLink("wrapped/3_1.svg")}
    />
  );
};

export const Background4 = () => {
  const width = getScreenWidth();
  const height = getScreenHeight();

  return width < height ? (
    <Image
      className="vh-100"
      width={window.innerWidth + 1}
      height={height + 1}
      style={{ objectFit: "cover", objectPosition: "centre", opacity: 0.75 }}
      src={getLink("wrapped/4_3.svg")}
    />
  ) : (
    <Image
      className="vh-100"
      width={window.innerWidth}
      height={height}
      style={{ objectFit: "cover", objectPosition: "centre", opacity: 0.75 }}
      src={getLink("wrapped/4_1.svg")}
    />
  );
};
