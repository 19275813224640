import { AdminApi } from "./atsApi";

class ScheduledJobsApi {
  constructor() {
    this.api = new AdminApi("scheduled-jobs");
  }

  getAll = () => this.api.getRequest("");
  executeOne = (jobId) => this.api.postRequest(jobId);
}

const scheduledJobsApi = new ScheduledJobsApi();
export default scheduledJobsApi;
