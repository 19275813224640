import React from "react";

class Match extends React.Component {
  state = {};
  render() {
    return <h1>Match</h1>;
  }
}

export default Match;
