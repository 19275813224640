import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Stack from "react-bootstrap/Stack";

import _ from "lodash";

import { IoMdAdd, IoMdShare, IoMdCopy, IoMdCreate, IoMdTennisball, IoMdRefresh } from "react-icons/io";
import { MdOutlineLibraryAdd } from "react-icons/md";

import { getEventSeriesName } from "../../common/eventSeries";
import { FieldRow, getVariantForMessageType, Section } from "../../common/viewControls";
import { getNewId } from "../../common/utils";
import { getPrimary } from "../../common/colours";

import GroupView from "./groupView";
import UnassignedView from "./unassignedView";

const PlannedLeagueView = (props) => {
  const {
    plannedLeague: {
      eventSeriesId,
      name,
      startDate,
      endDate,
      registerByDate,
      registerByTime,
      registrations,
      registrationUrl,
      whatsAppGroupLink,
      canCreateDefaultGroups,
      groups,
      messages,

      canStart,
      isRegistrationOpen,
    },
    handleEdit,
    handleStart,
    handleOpenRegistration,
    handleCloseRegistration,
    handleGroupEdit,
    handleGroupDelete,
    handleGroupNumberChange,
    handleDeleteRegistration,
    handleAssignRegistration,
    handleCreateDefaultGroups,
  } = props;

  const shareData = {
    title: name,
    text: `Register for ${name} here:`,
    url: registrationUrl,
  };

  return (
    <React.Fragment>
      <Container fluid="md">
        <Row className="mt-2">
          <Col>
            <h1>
              Project - {name}{" "}
              {
                <ButtonGroup>
                  <Button variant="outline-warning" size="sm" onClick={handleEdit}>
                    <IoMdCreate size="25" />
                  </Button>
                  <Button variant="outline-primary" size="sm" onClick={() => window.location.reload()}>
                    <IoMdRefresh size="25" />
                  </Button>
                  <Button variant="outline-primary" size="sm" disabled={!canStart} onClick={handleStart}>
                    <IoMdTennisball size="25" />
                    START
                  </Button>
                </ButtonGroup>
              }
            </h1>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm="auto">
            <Table>
              <tbody>
                <FieldRow id="eventSeriesId" label="League Type">
                  {getEventSeriesName(eventSeriesId)}
                </FieldRow>
                <FieldRow id="registration" label="Registration">
                  {isRegistrationOpen && (
                    <React.Fragment>
                      Is Open{" "}
                      <ButtonGroup>
                        <Button variant="outline-primary" size="sm" onClick={handleCloseRegistration}>
                          CLOSE
                        </Button>
                        <Button variant="outline-primary" size="sm" onClick={async () => await navigator.share(shareData)}>
                          <IoMdShare size="25" />
                        </Button>
                      </ButtonGroup>
                    </React.Fragment>
                  )}
                  {!isRegistrationOpen && (
                    <React.Fragment>
                      Is Closed{" "}
                      <Button variant="outline-primary" size="sm" onClick={handleOpenRegistration}>
                        OPEN
                      </Button>
                    </React.Fragment>
                  )}
                </FieldRow>
                <FieldRow id="startDate" label="Start Date">
                  {new Date(startDate).toLocaleDateString()}
                </FieldRow>
                <FieldRow id="endDate" label="End Date">
                  {new Date(endDate).toLocaleDateString()}
                </FieldRow>
                <FieldRow id="registerByDate" label="Register By Date">
                  {new Date(registerByDate).toLocaleDateString()}
                </FieldRow>
                <FieldRow id="registerByTime" label="Register By Time">
                  {registerByTime}
                </FieldRow>
                <FieldRow id="whatsAppGroupLink" label="WhatsApp Group">
                  {whatsAppGroupLink && (
                    <React.Fragment>
                      <Stack direction="horizontal" gap="2">
                        <a href={whatsAppGroupLink} target="_blank" rel="noreferrer">
                          Open
                        </a>
                        <IoMdCopy size="25" color={getPrimary()} onClick={() => navigator.clipboard.writeText(whatsAppGroupLink)} />
                      </Stack>
                    </React.Fragment>
                  )}
                  {!whatsAppGroupLink && "Not Set"}
                </FieldRow>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Section
          id="registrations"
          label={
            <React.Fragment>
              <Stack direction="horizontal" gap="2">
                Groups:
                <Button variant="outline-primary" size="sm" onClick={() => handleGroupEdit({ id: getNewId(), number: -1, name: "", whatsAppGroupLink: "" })}>
                  <IoMdAdd size="25" />
                </Button>
                <Button variant="outline-primary" size="sm" onClick={handleCreateDefaultGroups}>
                  <MdOutlineLibraryAdd size="25" disabled={!canCreateDefaultGroups} />
                </Button>
              </Stack>
            </React.Fragment>
          }
        >
          <Container fluid>
            <Row xs={1} lg={2}>
              {_.chain(Object.values(groups))
                .orderBy("number", "asc")
                .map((group) => {
                  return (
                    <GroupView
                      key={group.id}
                      group={group}
                      registrations={registrations}
                      handleEdit={() => handleGroupEdit(group)}
                      handleDelete={() => handleGroupDelete(group)}
                      handleNumberChange={(change) => handleGroupNumberChange(group.id, change)}
                      handleDeleteRegistration={handleDeleteRegistration}
                      handleAssignRegistration={handleAssignRegistration}
                    />
                  );
                })
                .value()}
              <UnassignedView
                registrations={registrations}
                handleDeleteRegistration={handleDeleteRegistration}
                handleAssignRegistration={handleAssignRegistration}
              />
            </Row>
          </Container>
        </Section>
        <Section id="messages" label="Messages:">
          <ListGroup>
            {messages.map((e, i) => {
              return (
                <ListGroup.Item key={i} variant={getVariantForMessageType(e.type)}>
                  {e.message}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Section>
      </Container>
    </React.Fragment>
  );
};

export default PlannedLeagueView;
