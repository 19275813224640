import React from "react";

import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { autoSizeAll } from "../../common/agGridUtils";

class Matches extends React.Component {
  state = {};

  render() {
    const { matches, onSetScore, onDefaultOne, onCoinToss, onLossInjury, onLossConcede, onLossNoShow, onWinInjury, onWinConcede, onWinNoShow } = this.props;

    const scoreRenderer = (props) => (
      <MatchScore
        score={props.data.score}
        onSetScore={(score) => onSetScore(props.data.id, score)}
        onDefaultOne={onDefaultOne && (() => onDefaultOne(props.data.id))}
        onCoinToss={onCoinToss && (() => onCoinToss(props.data.id))}
        onLossInjury={onLossInjury && (() => onLossInjury(props.data.id))}
        onLossConcede={onLossConcede && (() => onLossConcede(props.data.id))}
        onLossNoShow={onLossNoShow && (() => onLossNoShow(props.data.id))}
        onWinInjury={onWinInjury && (() => onWinInjury(props.data.id))}
        onWinConcede={onWinConcede && (() => onWinConcede(props.data.id))}
        onWinNoShow={onWinNoShow && (() => onWinNoShow(props.data.id))}
      />
    );

    return (
      <Accordion flush className="ag-theme-alpine">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h2>Matches</h2>
          </Accordion.Header>
          <Accordion.Body>
            <AgGridReact
              rowData={matches}
              domLayout="autoHeight"
              onGridSizeChanged={autoSizeAll}
              onFirstDataRendered={autoSizeAll}
              frameworkComponents={{ scoreRenderer: scoreRenderer }}
            >
              <AgGridColumn field="group" headerName="Group" sortable="true" filter={true} floatingFilter={true} />
              <AgGridColumn field="side1" headerName="Side1" sortable="true" filter={true} floatingFilter={true} />
              <AgGridColumn field="score" headerName="Score" sortable="true" />
              <AgGridColumn field="side2" headerName="Side2" sortable="true" filter={true} floatingFilter={true} type="rightAligned" />
              {onSetScore && <AgGridColumn headerName="" cellRenderer="scoreRenderer" />}
            </AgGridReact>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
}

class MatchScore extends React.Component {
  state = { score: "" };

  componentDidMount() {
    this.setState({ score: this.props.score });
  }

  handleChange = (e) => {
    this.setState({ score: e.target.value });
  };

  render() {
    const {
      score: initialScore,
      onSetScore,
      onDefaultOne,
      onCoinToss,
      onLossInjury,
      onLossConcede,
      onLossNoShow,
      onWinInjury,
      onWinConcede,
      onWinNoShow,
    } = this.props;
    const { score } = this.state;

    return (
      <InputGroup size="sm" className="align-items-center">
        <Form.Control size="sm" type="text" style={{ width: "6em" }} placeholder="Score" defaultValue={initialScore} onChange={this.handleChange} />
        <Button size="sm" variant="outline-primary" disabled={!score} onClick={() => onSetScore(score)}>
          SET
        </Button>
        {onDefaultOne && (
          <Button size="sm" variant="outline-danger" onClick={() => onDefaultOne()}>
            DEF
          </Button>
        )}
        {onCoinToss && (
          <Button size="sm" variant="outline-danger" onClick={() => onCoinToss()}>
            COIN
          </Button>
        )}
        {onLossInjury && (
          <Button size="sm" variant="outline-warning" onClick={() => onLossInjury()}>
            L INJ
          </Button>
        )}
        {onLossConcede && (
          <Button size="sm" variant="outline-warning" onClick={() => onLossConcede()}>
            L CDE
          </Button>
        )}
        {onLossNoShow && (
          <Button size="sm" variant="outline-warning" onClick={() => onLossNoShow()}>
            L NS
          </Button>
        )}
        {onWinInjury && (
          <Button size="sm" variant="outline-warning" onClick={() => onWinInjury()}>
            W INJ
          </Button>
        )}
        {onWinConcede && (
          <Button size="sm" variant="outline-warning" onClick={() => onWinConcede()}>
            W CDE
          </Button>
        )}
        {onWinNoShow && (
          <Button size="sm" variant="outline-warning" onClick={() => onWinNoShow()}>
            W NS
          </Button>
        )}
      </InputGroup>
    );
  }
}

export default Matches;
