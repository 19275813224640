import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import _ from "lodash";

import { toast } from "react-toastify";

import EventsApi from "../../api/eventsApi";

import { handleCopyNo, handleCopyAz } from "../../common/copyRegistrations";

import EventInfo from "./eventInfo";
import EventRegistrations from "./eventRegistrations";

class Tournament extends React.Component {
  state = {
    loaded: false,

    state: "",
    startDate: new Date(),
    endDate: new Date(),

    plannedEventRegistrations: [],
    registrationFields: [],
  };

  async componentDidMount() {
    await this.refreshData();
  }

  refreshData = async () => {
    const { eventId, plannedEventId } = this.props;
    const {
      data: { state, startDate, endDate, plannedEventRegistrations, registrationFields },
    } = await EventsApi.getEvent(eventId, plannedEventId);
    const registrationData = _.map(plannedEventRegistrations, (per) => {
      return { ...{ id: per.id, number: per.number, player: per.player }, ...per.fields };
    });

    this.setState({
      loaded: true,

      state,
      startDate: new Date(startDate),
      endDate: new Date(endDate),

      plannedEventRegistrations: registrationData,
      registrationFields,
    });
  };

  handleSubmit = async (registrationId) => {
    const { plannedEventRegistrations } = this.state;
    const { success } = await EventsApi.unregister(registrationId);

    if (success) {
      _.remove(plannedEventRegistrations, (pe) => pe.id === registrationId);

      this.setState({ plannedEventRegistrations });
      this.refreshData();
    } else {
      toast.error("Unable to delete registration, please try again...");
    }
  };

  render() {
    const { loaded, state, startDate, endDate, plannedEventRegistrations, registrationFields } = this.state;
    const canDeleteRegistrations = state.includes("Registration");

    return (
      <>
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h1>{this.props.name}</h1>
            </Col>
          </Row>
          {!loaded && <Spinner animation="border" role="status" />}
          {loaded && (
            <Row className="justify-content-flex-start">
              <Col lg="auto">
                <EventInfo
                  state={state}
                  startDate={startDate}
                  endDate={endDate}
                  participantCount={plannedEventRegistrations.length}
                  onCopyNo={() => handleCopyNo(plannedEventRegistrations)}
                  onCopyAz={() => handleCopyAz(plannedEventRegistrations)}
                />
              </Col>
            </Row>
          )}
        </Container>
        <Container>
          {plannedEventRegistrations.length > 0 && (
            <Row>
              <Col>
                <EventRegistrations
                  onDelete={canDeleteRegistrations ? this.handleSubmit : null}
                  plannedEventRegistrations={plannedEventRegistrations}
                  registrationFields={registrationFields}
                />
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

export default Tournament;
