import React from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-data-grid/lib/styles.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Container from "react-bootstrap/Container";
import Footer from "./components/footer";
import { ToastContainer, toast } from "react-toastify";
import AtsRouter from "./components/atsRouter";
import { withQueryParams } from "./common/queryParams";
import AuxInfoApi from "./api/auxInfoApi";
import SettingsContext from "./contexts/settingsContext";
import { setAnonId } from "./common/auth";
import Offline from "./apps/offline";
import ProcessingModal from "./common/processingModal";

class App extends React.Component {
  state = {
    settings: {
      siteWhitelabel: "default",
      courtAlertsEnabled: false,
      findOpponentEnabled: false,
      tournamentsEnabled: false,
      umpireCastEnabled: false,
      clubStreamEnabled: false,
      rallyRecordEnabled: false,
      joinEnabled: false,
      siteGtag: "",
      defaultApp: "home",
      stripePublishableKey: "",
    },
    settingsLoaded: false,
    isOffline: false,
  };

  async componentDidMount() {
    const { success, data: instanceSettings, message } = await AuxInfoApi.getInstanceSettings();

    if (!success) {
      this.setState({ isOffline: true, settingsLoaded: false });
      toast.error(message);
      return;
    }

    setAnonId(instanceSettings.anonId);

    this.setState({ settings: instanceSettings, settingsLoaded: true });
  }

  render() {
    const { settings, settingsLoaded, isOffline } = this.state;
    const { embed } = this.props.queryParams;

    return (
      <React.Fragment>
        <SettingsContext.Provider value={settings}>
          <main>
            <Container fluid className="g-0">
              <ToastContainer />
              {isOffline && <Offline />}
              {!isOffline && !settingsLoaded && <ProcessingModal text="Loading application..." />}
              {!isOffline && settingsLoaded && <AtsRouter />}
            </Container>
            {!embed && <div className="m-5">&nbsp;</div>}
          </main>
          {!embed && (
            <footer>
              <Footer />
            </footer>
          )}
        </SettingsContext.Provider>
      </React.Fragment>
    );
  }
}

export default withQueryParams(App);
