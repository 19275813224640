import { PlayerApi } from "./atsApi";

class PlayerLoginApi {
  constructor() {
    this.api = new PlayerApi("");
  }

  find = (phone) => this.api.postRequest("find", { phone }, { 405: "User registrations are suspended, please contact administrator." });

  login = (phone, token) => this.api.postRequest("login", { phone, token });

  register = (phone, name) => this.api.postRequest("register", { phone, name }, { 405: "User registrations are suspended, please contact administrator." });

  verify = (phone, token) => this.api.postRequest("verify", { phone, token });
}

const playerLoginApi = new PlayerLoginApi();
export default playerLoginApi;
