import { getLink } from "./appLinkBuilder";

export function login(sessionId, isAdmin, myPage, tag, permissions, inPlace, targetUrl) {
  localStorage.setItem("atsSessionId", sessionId);
  localStorage.setItem("atsIsAdmin", isAdmin);
  localStorage.setItem("atsMyPage", myPage);
  localStorage.setItem("atsTag", tag);
  localStorage.setItem("atsPermissions", permissions);

  if (inPlace) window.location.reload();
  else if (targetUrl) window.location.replace(`${targetUrl}?atsSessionId=${sessionId}`);
  else window.location.replace(getLink());
}

export function setAuthData(sessionId, isAdmin, myPage, tag, permissions) {
  localStorage.setItem("atsSessionId", sessionId);
  localStorage.setItem("atsIsAdmin", isAdmin);
  localStorage.setItem("atsMyPage", myPage);
  localStorage.setItem("atsTag", tag);
  localStorage.setItem("atsPermissions", permissions);
}

export function getSessionId() {
  return localStorage.getItem("atsSessionId");
}

export function setAnonId(anonId) {
  localStorage.setItem("atsAnonId", anonId);
}

export function getAnonId() {
  return localStorage.getItem("atsAnonId");
}

export function getDefaultHeaders() {
  return {
    AtsSessionId: getSessionId(),
    AtsAnonId: getAnonId(),
  };
}

export function logout() {
  localStorage.removeItem("atsSessionId");
  localStorage.removeItem("atsIsAdmin");
  localStorage.removeItem("atsMyPage");
  localStorage.removeItem("atsTag");
  localStorage.removeItem("atsPermissions");

  window.location.replace(getLink());
}

export function isAuthenticated() {
  return localStorage.getItem("atsSessionId") !== null;
}

export function isAdmin() {
  return hasPermission("Admin");
}

export function hasPermission(permission) {
  const permissions = localStorage.getItem("atsPermissions") ?? "";
  return permissions.includes(permission) || permissions.includes("Admin");
}

export const Permission = {
  TournamentAuthor: "TournamentAuthor",
  UmpireCast: "UmpireCast",
  ParentCast: "ParentCast",
  CommentCast: "CommentCase",
};
