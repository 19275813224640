import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DateEdit, TextEdit, SelectEdit } from "../../common/editControls";

import { withSettings } from "../../common/withSettings";

class PlannedTournamentEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      staticData: props.staticData,
      plannedTournament: { ...props.plannedTournament },
      handleSubmit: props.handleSubmit,
      handleCancel: props.handleCancel,
    };
  }

  handleChange = (e) => {
    const { plannedTournament } = this.state;
    plannedTournament[e.target.id] = e.target.value;
    this.setState({ plannedTournament });
  };

  render() {
    const {
      plannedTournament: { name, date, startTime, endTime, eventInfoId, scoringFormatId, eventVisibilityId, courtInfo, whatsAppGroupLink },
      handleSubmit,
      handleCancel,
    } = this.state;

    const { staticData } = this.props.settings;

    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col>
              <Form>
                <TextEdit id="name" label="Name" value={name} onChange={this.handleChange} />
                <DateEdit id="date" label="Tournament Date" value={date} onChange={this.handleChange} />
                <SelectEdit id="startTime" label="Start Time" items={staticData.times} value={startTime} onChange={this.handleChange} />
                <SelectEdit id="endTime" label="End Time" items={staticData.times} value={endTime} onChange={this.handleChange} />
                <SelectEdit
                  id="eventInfoId"
                  label="Event Type"
                  items={staticData.eventInfos}
                  value={eventInfoId}
                  helpText={getHelpText(Object.values(staticData.eventInfos))}
                  onChange={this.handleChange}
                />
                <SelectEdit
                  id="scoringFormatId"
                  label="Scoring Format"
                  items={staticData.scoringFormats}
                  value={scoringFormatId}
                  helpText={getHelpText(Object.values(staticData.scoringFormats))}
                  onChange={this.handleChange}
                />
                <SelectEdit
                  id="eventVisibilityId"
                  label="Event Visibility"
                  items={staticData.tournamentVisibilities}
                  value={eventVisibilityId}
                  helpText={getHelpText(Object.values(staticData.tournamentVisibilities))}
                  onChange={this.handleChange}
                />
                <TextEdit
                  id="courtInfo"
                  label="Courts"
                  value={courtInfo ?? ""}
                  helpText="Comma-separated list of courts for the tournament"
                  onChange={this.handleChange}
                />
                <TextEdit
                  id="whatsAppGroupLink"
                  label="WhatsApp Group"
                  value={whatsAppGroupLink ?? ""}
                  helpText="Link to the WhatApp Group for the tournament"
                  onChange={this.handleChange}
                />

                <ButtonGroup className="mt-5">
                  <Button
                    variant="outline-primary"
                    onClick={() => handleSubmit(this.state.plannedTournament)}
                    disabled={!name || !date || !startTime || !endTime || !eventInfoId || !scoringFormatId || !eventVisibilityId}
                  >
                    SAVE
                  </Button>
                  <Button variant="outline-danger" onClick={handleCancel}>
                    CANCEL
                  </Button>
                </ButtonGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

function getHelpText(staticDataItems) {
  return staticDataItems.map((sdi) => (
    <div key={sdi.key}>
      <strong>{sdi.name}</strong>: {sdi.description}
    </div>
  ));
}

export default withSettings(PlannedTournamentEdit);
