import { PlayerApi } from "./atsApi";

class CourtAlertsApi {
  constructor() {
    this.api = new PlayerApi("court-alerts");
  }

  getCourtAlert = () => this.api.getRequest("");
  postCourtAlert = (courtAlert) => this.api.postRequest("", courtAlert);
  postNotifyCourt = (court) => this.api.postRequest("notify", court);
}

const courtAlertApi = new CourtAlertsApi();
export default courtAlertApi;
