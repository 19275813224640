import React, { useContext } from "react";
import StripeSettingsContext from "../contexts/stripeContext";
import { ElementsConsumer } from "@stripe/react-stripe-js";

export function withStripePromise(Component) {
  return (props) => <Component {...props} stripePromise={useContext(StripeSettingsContext)} />;
}

export function withStripeElements(Component) {
  return (props) => <ElementsConsumer>{({ stripe, elements }) => <Component {...props} stripe={stripe} elements={elements} />}</ElementsConsumer>;
}

export function useStripeSettings() {
  return useContext(StripeSettingsContext);
}
