import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { TextEdit } from "../../common/editControls";

class GroupEdit extends React.Component {
  state = {
    group: { ...this.props.data.group },
  };

  handleChange = (e) => {
    const { group } = this.state;
    group[e.target.id] = e.target.value;
    this.setState({ group });
  };

  render() {
    const { group } = this.state;
    const {
      show,
      data: { handleSubmit, handleCancel },
    } = this.props;

    return (
      <React.Fragment>
        <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
            <h1>Edit Group</h1>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <Form>
                    <TextEdit id="name" label="Name" value={group.name} onChange={this.handleChange} />
                    <TextEdit id="whatsAppGroupLink" label="WhatsApp Group Link" value={group.whatsAppGroupLink} onChange={this.handleChange} />
                  </Form>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup className="mt-5">
              <Button variant="primary" onClick={() => handleSubmit(group)} disabled={!group.name}>
                SAVE
              </Button>
              <Button variant="danger" onClick={handleCancel}>
                CANCEL
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default GroupEdit;
