import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import FadeIn from "react-fade-in";

import { DualProgressBar3, DualProgressBar4 } from "./progressBars";
import { Number, Text } from "./utils";
import { withQueryParams } from "./../../common/queryParams";

class MatchesPage extends React.Component {
  state = {};
  render() {
    const {
      page,
      name,
      data: { matchesByType, matchesByOutcome, gamesCount, setsCount, tieBreakMatches, biggestWin, biggestLoss },
      fadeTimeout,
    } = this.props;

    const { shared } = this.props.queryParams;

    const optionalRows = [];
    if (tieBreakMatches && tieBreakMatches.refVal > 0) {
      optionalRows.push(
        <React.Fragment key="tieBreakMatches">
          <Row className="mt-3">
            <Col>{<Number value={tieBreakMatches.refVal} />} matches went to tie-break set</Col>
          </Row>
          <Row className="mt-1">
            <Col>
              of which you won {<Number value={tieBreakMatches.val1} />} and lost {<Number value={tieBreakMatches.val2} />}
              <DualProgressBar3 value={tieBreakMatches} />
            </Col>
          </Row>
        </React.Fragment>
      );
    }

    if (biggestWin) {
      optionalRows.push(
        <Row className="mt-3" key="biggestWin">
          <Col>
            Biggest Win: {<Number value={biggestWin.val} />} vs {<Text value={biggestWin.name} />}
          </Col>
        </Row>
      );
    }

    if (biggestLoss) {
      optionalRows.push(
        <Row className="mt-3" key="biggestLoss">
          <Col>
            Biggest Lesson Learnt: {<Number value={biggestLoss.val} />} by {<Text value={biggestLoss.name} />}
          </Col>
        </Row>
      );
    }

    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <h1>{shared ? `${name}'s ` : "Your"} best-of-three sets matches</h1>
          </Col>
        </Row>
        <FadeIn delay={fadeTimeout} key={page}>
          <Row className="mt-4">
            <Col>{<Number value={matchesByType.refVal} />} matches played</Col>
          </Row>
          <Row className="mt-3">
            <Col>
              {<Number value={gamesCount} />} games and {<Number value={setsCount} />} sets
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              {<Number value={matchesByType.val1} />} singles and {<Number value={matchesByType.val2} />} doubles
              <DualProgressBar4 value={matchesByType} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              Won {<Number value={matchesByOutcome.val1} />} and lost {<Number value={matchesByOutcome.val2} />}
              <DualProgressBar3 value={matchesByOutcome} />
            </Col>
          </Row>
          {optionalRows.map((r) => r)}
        </FadeIn>
      </Container>
    );
  }
}

export default withQueryParams(MatchesPage);
