export function getEventSeriesPath(eventSeriesId) {
  switch (eventSeriesId) {
    case "094kz3068tyjrg1t":
    case "0h6xb8r33x6m96qz":
      return "league";
    case "0gpzshwrze60nyrd":
    case "0h6xb56y0v9nn2f9":
      return "tournament";
    case "0qp2khhwr4xf7542":
    case "0qp2khhwv38j6hzv":
      return "championship";
    default:
      return null;
  }
}

export function getEventSeriesName(eventSeriesId) {
  switch (eventSeriesId) {
    case "094kz3068tyjrg1t":
      return "Singles League";
    case "0h6xb8r33x6m96qz":
      return "Doubles League";
    default:
      return "";
  }
}

export const EventSeriesSinglesLeague = "094kz3068tyjrg1t";
export const EventSeriesDoublesLeague = "0h6xb8r33x6m96qz";

export function isLeague(eventSeriesId) {
  return eventSeriesId === EventSeriesSinglesLeague || eventSeriesId === EventSeriesDoublesLeague;
}
