import axios from "axios";
import { getAdminApiUrl, getPlayerApiUrl, getS3ApiUrl } from "./apiUrlBuilder";
import { getDefaultHeaders, logout } from "../common/auth";

class AtsApi {
  constructor(rootUrl) {
    this.rootUrl = rootUrl;
    this.defaultErr = "Unexpected error occured, please try again later";
    this.unauthErr = "You are unauthorised to perform this operation, please re-login and try again";
  }

  getRequest = async (url, errMap) => {
    try {
      const { data, status } = await axios.get(`${this.rootUrl}/${url}`, { headers: getDefaultHeaders() });

      if (status === 200) return { success: true, status, data };
      if (status === 239) window.location.replace(data);

      return { success: false, status, data, message: (errMap && errMap[status]) ?? this.defaultErr };
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
        return { success: false, status: error.response.status, message: this.unauthErr };
      }

      return error.response
        ? { success: false, status: error.response.status, message: (errMap && errMap[error.response.status]) ?? this.defaultErr }
        : { success: false, status: -1, message: "Network error, are you connected to Internet?" };
    }
  };

  postRequest = async (url, postData, errMap) => {
    try {
      const { data, status } = await axios.post(`${this.rootUrl}/${url}`, postData, { headers: getDefaultHeaders() });

      if (status === 200) return { success: true, status, data };
      if (status === 239) window.location.replace(data);

      return { success: false, status, data, message: (errMap && errMap[status]) ?? this.defaultErr };
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
        return { success: false, status: error.response.status, message: this.unauthErr };
      }

      return error.response
        ? { success: false, status: error.response.status, message: (errMap && errMap[error.response.status]) ?? this.defaultErr }
        : { success: false, status: -1, message: "Network error, are you connected to Internet?" };
    }
  };

  deleteRequest = async (url, errMap) => {
    try {
      const { status } = await axios.delete(`${this.rootUrl}/${url}`, { headers: getDefaultHeaders() });
      if (status === 200) return { success: true, status };

      return { success: false, status, message: (errMap && errMap[status]) ?? this.defaultErr };
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
        return { success: false, status: error.response.status, message: this.unauthErr };
      }
      return error.response
        ? { success: false, status: error.response.status, message: (errMap && errMap[error.response.status]) ?? this.defaultErr }
        : { success: false, status: -1, message: "Network error, are you connected to Internet?" };
    }
  };
}

class AdminApi {
  constructor(name) {
    this.atsApi = new AtsApi(`${getAdminApiUrl()}/${name}`);
  }

  getRequest = (url, errMap) => this.atsApi.getRequest(url, errMap);
  postRequest = (url, postData, errMap) => this.atsApi.postRequest(url, postData, errMap);
  deleteRequest = (url, errMap) => this.atsApi.deleteRequest(url, errMap);
}

class PlayerApi {
  constructor(name) {
    this.atsApi = new AtsApi(name ? `${getPlayerApiUrl()}/${name}` : getPlayerApiUrl());
  }

  getRequest = (url, errMap) => this.atsApi.getRequest(url, errMap);
  postRequest = (url, postData, errMap) => this.atsApi.postRequest(url, postData, errMap);
  deleteRequest = (url, errMap) => this.atsApi.deleteRequest(url, errMap);
}

class S3Api {
  constructor() {
    this.atsApi = new AtsApi(getS3ApiUrl());
  }

  getRequest = (url, errMap) => this.atsApi.getRequest(url, errMap);
}

export { AdminApi, PlayerApi, S3Api };
