import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import _ from "lodash";

import { toast } from "react-toastify";

import { IoIosTennisball } from "react-icons/io";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { autoSizeAll } from "../../common/agGridUtils";

import FindOpponentApi from "../../api/findOpponentApi";

import { withStaticData } from "../../common/staticData";
import { LinkBuilder } from "./findOpponentLinkBuilder";

const colours = { Draft: "gold", Active: "blue" };

class MySlots extends React.Component {
  state = {
    loaded: false,
    mySlots: [],
  };

  async componentDidMount() {
    const { success, data: mySlots, message } = await FindOpponentApi.getMySlots();

    if (!success) {
      toast.error(message);
      return;
    }

    this.setState({ mySlots, loaded: true });
  }

  handleDelete = async (e, availablePlayerId) => {
    e.stopPropagation();

    const { mySlots } = this.state;

    const { success, message } = await FindOpponentApi.deleteMySlot(availablePlayerId);
    if (!success) {
      toast.error(message);
      return;
    }

    _.remove(mySlots, (m) => m.availablePlayerId === availablePlayerId);

    this.setState({ mySlots });
    toast.success("Slot deleted");
  };

  onRowClicked = (params) => {
    let { availablePlayerId } = params.data;
    window.location.assign(LinkBuilder.getMySlotLink(availablePlayerId));
  };

  render() {
    const { staticData } = this.props;
    const { loaded, mySlots } = this.state;

    const buttonRenderer = (props) => (
      <>
        <IoIosTennisball size="25" color={colours[props.data.currentState]} />
        <Button className="ps-2 ms-2" size="sm" variant="danger" onClick={(e) => this.handleDelete(e, props.data.availableMatchId)}>
          DEL
        </Button>
      </>
    );

    return (
      <Container fluid className="ag-theme-alpine">
        <Row>
          <Col>
            <h1>
              Find Opponent - My Tennis Slots{" "}
              <Button variant="primary" href={LinkBuilder.getMySlotLink()}>
                New
              </Button>
            </h1>
            <br />
            {!loaded && <Spinner animation="border" role="status" />}
            {loaded && (
              <AgGridReact
                rowData={mySlots}
                rowStyle={{ cursor: "pointer" }}
                domLayout="autoHeight"
                onGridSizeChanged={autoSizeAll}
                onFirstDataRendered={autoSizeAll}
                onRowClicked={this.onRowClicked}
                frameworkComponents={{ buttonRenderer: buttonRenderer }}
                overlayNoRowsTemplate="No tennis slots found"
              >
                <AgGridColumn headerName="" cellRenderer="buttonRenderer" />
                <AgGridColumn field="matchTypeId" headerName="Match" valueFormatter={(p) => staticData.matchTypes[p.data.matchTypeId]}></AgGridColumn>
                <AgGridColumn field="dayOfWeekId" headerName="Days" valueFormatter={(p) => staticData.dayOfWeeks[p.data.dayOfWeekId]}></AgGridColumn>
                <AgGridColumn field="time" headerName="Time"></AgGridColumn>
                <AgGridColumn
                  field="timeToleranceId"
                  headerName="Tolerance"
                  valueFormatter={(p) => staticData.tolerances[p.data.timeToleranceId]}
                ></AgGridColumn>
                <AgGridColumn field="courtTypeId" headerName="Court" valueFormatter={(p) => staticData.courtTypes[p.data.courtTypeId]}></AgGridColumn>
                <AgGridColumn
                  field="courtSurfaceId"
                  headerName="Surface"
                  valueFormatter={(p) => staticData.courtSurfaces[p.data.courtSurfaceId]}
                ></AgGridColumn>
              </AgGridReact>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStaticData(MySlots);
