import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";

import _ from "lodash";

import { toast } from "react-toastify";

import PlannedTournamentApi from "../../api/plannedTournamentApi";
import ProcessingModal from "../../common/processingModal";
import { hasPermission, isAuthenticated, Permission } from "../../common/auth";
import { LinkBuilder } from "./tournamentLinkBuilder";

class TournamentSelector extends React.Component {
  state = {
    processing: false,
    isLoading: true,

    plannedTournaments: [],
  };

  async componentDidMount() {
    await this.loadPlannedTournaments();
  }

  loadPlannedTournaments = async () => {
    this.setState({ processing: true });

    const { success, data: plannedTournaments, message } = await PlannedTournamentApi.getAll();

    if (!success) {
      this.setState({ processing: false, isLoading: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedTournaments, processing: false, isLoading: false });
  };

  handleCopy = async (plannedTournamentId) => {
    this.setState({ processing: true });

    let { plannedTournaments } = this.state;

    let { success, message } = await PlannedTournamentApi.copy(plannedTournamentId);
    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    ({ success, data: plannedTournaments, message } = await PlannedTournamentApi.getAll());
    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedTournaments, processing: false });
    toast.success("Tournament copied");
  };

  render() {
    const { processing, loading } = this.state;

    const tournaments = _.chain(this.state.plannedTournaments).orderBy("date", "desc").value();

    return (
      <React.Fragment>
        {processing && <ProcessingModal />}
        <Container fluid="md">
          <Row>
            <Col>
              <h1>
                Tournaments:{" "}
                {hasPermission(Permission.TournamentAuthor) && (
                  <Button variant="outline-primary" size="sm" href={LinkBuilder.getEditPlannedTournamentLink("")}>
                    New
                  </Button>
                )}
              </h1>
              {loading && <ProcessingModal text="Loading..." />}
              {!loading && tournaments.length === 0 && (
                <h4>
                  No tournaments yet,{" "}
                  {isAuthenticated() ? (
                    hasPermission(Permission.TournamentAuthor) ? (
                      <span>create one now...</span>
                    ) : (
                      <span>ask your tennis organiser to create one</span>
                    )
                  ) : (
                    <span>sign in to create one...</span>
                  )}
                </h4>
              )}
              <ListGroup>
                {tournaments.map((tournament) => {
                  return (
                    <ListGroup.Item action key={tournament.key} as="div">
                      <Stack gap="2">
                        <Nav.Link
                          href={
                            tournament.eventId
                              ? LinkBuilder.getTournamentLink(tournament.eventId)
                              : LinkBuilder.getViewPlannedTournamentLink(tournament.plannedEventId)
                          }
                        >
                          {tournament.state} - {tournament.name}
                        </Nav.Link>
                      </Stack>
                      <Stack direction="horizontal" gap="1">
                        <ButtonGroup>
                          {tournament.canView && (
                            <Button variant="outline-primary" size="sm" href={LinkBuilder.getViewPlannedTournamentLink(tournament.plannedEventId)}>
                              VIEW
                            </Button>
                          )}
                          {tournament.canEdit && (
                            <Button variant="outline-warning" size="sm" href={LinkBuilder.getEditPlannedTournamentLink(tournament.plannedEventId)}>
                              EDIT
                            </Button>
                          )}
                          {tournament.canCopy && (
                            <Button variant="outline-secondary" size="sm" onClick={(e) => this.handleCopy(tournament.plannedEventId)}>
                              COPY
                            </Button>
                          )}
                        </ButtonGroup>
                      </Stack>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default TournamentSelector;
