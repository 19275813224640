import queryString from "query-string";
import { useLocation } from "react-router-dom";

export function withQueryParams(Component) {
  return (props) => <Component {...props} queryParams={queryString.parse(useLocation().search)} />;
}

export function useQueryParams() {
  return queryString.parse(useLocation().search);
}
