import { getPageLink } from "../../common/appLinkBuilder";

const appName = "tournament";

export const Page = {
  Tournaments: "tournaments",
  Tournament: "tournament",
  PlannedTournament: "planned-tournament",
};

class TournamentLinkBuilder {
  getTournamentsLink = () => getPageLink(appName, Page.Tournaments);
  getTournamentLink = (tournamentId) => getPageLink(appName, Page.Tournament, { id: tournamentId });
  getViewPlannedTournamentLink = (tournamentId) => getPageLink(appName, Page.PlannedTournament, { id: tournamentId });
  getEditPlannedTournamentLink = (tournamentId) => getPageLink(appName, Page.PlannedTournament, { id: tournamentId, edit: true });
}

export const LinkBuilder = new TournamentLinkBuilder();
