import React from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { getSideName } from "../../common/sideNameBuilder";

class MatchScore extends React.Component {
  state = {
    scoreData: { matchId: this.props.match.matchId, scoreId: this.props.match.score ?? "" },
  };

  handleChange = (e) => {
    const { scoreData } = this.state;
    scoreData[e.currentTarget.id] = e.currentTarget.value;
    this.setState({ scoreData });
  };

  render() {
    const { scoreData } = this.state;
    const { match, scoreTypeId, onSubmitScore } = this.props;

    return (
      <React.Fragment>
        <tr key={match.matchId}>
          <td className="text-end align-middle">{getSideName(match.side1)}</td>
          <td className="text-center align-middle">
            <Form.Select id="scoreId" className="form-select" size="sm" value={scoreData.scoreId} onChange={this.handleChange}>
              <option key="empty" value="">
                Score
              </option>
              {Scores[scoreTypeId].map((s) => (
                <option key={s} value={s}>
                  {s}
                </option>
              ))}
            </Form.Select>
          </td>
          <td className="text-start align-middle">{getSideName(match.side2)}</td>
          <td className="align-middle">
            <Button variant="outline-primary" size="sm" disabled={!scoreData.scoreId} onClick={() => onSubmitScore(scoreData)}>
              SET
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const Scores = {
  "best-of-nine": ["9-0", "8-1", "7-2", "6-3", "5-4", "4-5", "3-6", "2-7", "1-8", "0-9"],
  "fast-4-set": ["4-0", "4-1", "4-2", "4-3", "0-4", "1-4", "2-4", "3-4"],

  "tournament-10": [
    "10-9",
    "9-10",
    "10-8",
    "8-10",
    "10-7",
    "7-10",
    "10-6",
    "6-10",
    "10-5",
    "5-10",
    "10-4",
    "4-10",
    "10-3",
    "3-10",
    "10-2",
    "2-10",
    "10-1",
    "1-10",
    "10-0",
    "0-10",
  ],
  "tournament-15": [
    "15-14",
    "14-15",
    "15-13",
    "13-15",
    "15-12",
    "12-15",
    "15-11",
    "11-15",
    "15-10",
    "10-15",
    "15-9",
    "9-15",
    "15-8",
    "8-15",
    "15-7",
    "7-15",
    "15-6",
    "6-15",
    "15-5",
    "5-15",
    "15-4",
    "4-15",
    "15-3",
    "3-15",
    "15-2",
    "2-15",
    "15-1",
    "1-15",
    "15-0",
    "0-15",
  ],
};

export default MatchScore;
