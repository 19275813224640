import React from "react";

import Stack from "react-bootstrap/Stack";

import { IoIosTennisball } from "react-icons/io";

class TextCommentary extends React.Component {
  render() {
    const { messages, count } = this.props;

    return (
      <Stack direction="vertical" gap="1">
        <h6>Last few points:</h6>
        {messages.map((message, i) => (
          <span key={count * 100 + i}>
            <IoIosTennisball size="10" color="green" className="me-2" />
            {message}
          </span>
        ))}
      </Stack>
    );
  }
}

export default TextCommentary;
