import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import { withSettings } from "../common/withSettings";
import { getLink } from "./appLinkBuilder";

const ProcessingModal = (props) => {
  const {
    show,
    text,
    settings: { siteWhitelabel },
  } = props;

  return (
    <React.Fragment>
      <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <Container>
            <Row className="text-center">
              <Col>
                <div className="bg-white p-2">
                  <a href="https://ats.tennis" rel="nofollow">
                    <img src={getLink(`${siteWhitelabel}.logo.png`)} height="40" alt="" />
                  </a>
                </div>
                <div>{text || "Processing..."}</div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default withSettings(ProcessingModal);
