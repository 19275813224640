import { PlayerApi } from "./atsApi";

class JoinApi {
  constructor() {
    this.api = new PlayerApi("join");
  }

  getActive = (searchId) => this.api.getRequest(`get-active/${searchId}`);
  findByPostcode = (postcode, radius, courtType, page, searchId) =>
    this.api.getRequest(
      `find-by-postcode/${postcode}?radius=${radius}&courtType=${courtType}&page=${page}&searchId=${searchId}`,
      { 400: "Invalid postcode, please correct it and try again" },
      { 404: "No clubs found in your area, please try another area." }
    );
  getRequestData = (clubId) => this.api.getRequest(`request-data/${clubId}`);
  getJoinData = (plannedEventId) => this.api.getRequest(`join-data/${plannedEventId}`);
  leaveEvent = (plannedEventId) => this.api.postRequest(`leave-event/${plannedEventId}`);
}

const joinApi = new JoinApi();
export default joinApi;
