import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle, IoIosArrowForward, IoMdMedal, IoMdTrophy } from "react-icons/io";

export const Number = (props) => {
  return <span className="fw-bold text-primary fs-4">{props.value}</span>;
};

export const Text = (props) => {
  return <span className="fw-bold text-secondary fs-4">{props.value}</span>;
};

export const Trophy = (props) => {
  switch (props.val) {
    case "MG":
      return (
        <div style={{ color: "gold" }}>
          <IoMdMedal size="25" /> <span className="fw-bold fs-4 align-middle">{props.name}</span>
        </div>
      );
    case "MS":
      return (
        <div style={{ color: "silver" }}>
          <IoMdMedal size="25" /> <span className="fw-bold fs-4 align-middle">{props.name}</span>
        </div>
      );
    case "MB":
      return (
        <div style={{ color: "#CD7F32" }}>
          <IoMdMedal size="25" /> <span className="fw-bold fs-4 align-middle">{props.name}</span>
        </div>
      );
    case "T1":
      return (
        <div style={{ color: "gold" }}>
          <IoMdTrophy size="25" /> <span className="fw-bold fs-4 align-middle">{props.name}</span>
        </div>
      );
    case "T2":
      return (
        <div style={{ color: "silver" }}>
          <IoMdTrophy size="25" /> <span className="fw-bold fs-4 align-middle">{props.name}</span>
        </div>
      );
    default:
      <></>;
  }
};

export const RatingQ = (props) => {
  return (
    <div>
      <Text value={props.name} />:{" "}
      {props.start < props.end ? (
        <IoIosArrowDropupCircle size="25" color="green" className="align-text-bottom" />
      ) : (
        <IoIosArrowDropdownCircle size="25" color="red" className="align-text-bottom" />
      )}{" "}
      <Number value={props.start} /> <IoIosArrowForward size="25" className="text-secondary align-text-bottom" /> <Number value={props.end} />
    </div>
  );
};
