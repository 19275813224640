import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import FadeIn from "react-fade-in";

import { DualProgressBar3 } from "./progressBars";
import { Number, RatingQ, Text, Trophy } from "./utils";

class LeaguePage extends React.Component {
  state = {};

  render() {
    const {
      page,
      data: { count, matches, highestBox, lowestBox, nemesis, trophies, ratings },
      type,
      fadeTimeout,
    } = this.props;

    const optionalRows = [];

    if (nemesis) {
      optionalRows.push(
        <React.Fragment key="nemesis">
          <Row className="mt-3">
            <Col>
              Nemesis: {<Text value={nemesis.name} />}, played {<Number value={nemesis.outcome.refVal} />} times
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              Won {<Number value={nemesis.outcome.val1} />} and lost {<Number value={nemesis.outcome.val2} />}
              <DualProgressBar3 value={nemesis.outcome} />
            </Col>
          </Row>
        </React.Fragment>
      );
    }

    if (trophies && trophies.length > 0) {
      optionalRows.push(
        <Row className="mt-3" key="medals">
          <Col>
            Best results:
            {trophies.map((t) => (
              <Trophy key={t.name} {...t} />
            ))}
          </Col>
        </Row>
      );
    }

    if (ratings && ratings.quarterRatings && ratings.quarterRatings.length > 0) {
      optionalRows.push(
        <Row className="mt-3" key="ratings">
          <Col>
            Ratings:
            {ratings.quarterRatings.map((r) => r && <RatingQ key={r.name} {...r} />)}
          </Col>
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Container className="mt-5">
          <Row>
            <Col>
              <h1>{type} Leagues</h1>
            </Col>
          </Row>
          <FadeIn delay={fadeTimeout} key={page}>
            <Row className="mt-4">
              <Col>Played {<Number value={count} />} rounds</Col>
            </Row>
            <Row className="mt-3">
              <Col>Played {<Number value={matches.refVal} />} matches in total</Col>
            </Row>
            <Row className="mt-3">
              <Col>
                Won {<Number value={matches.val1} />} and lost {<Number value={matches.val2} />}
                <DualProgressBar3 value={matches} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>Highest Box: {<Number value={highestBox} />}</Col>
            </Row>
            <Row className="mt-3">
              <Col>Lowest Box: {<Number value={lowestBox} />}</Col>
            </Row>
            {optionalRows.map((r) => r)}
          </FadeIn>
        </Container>
      </React.Fragment>
    );
  }
}

export default LeaguePage;
