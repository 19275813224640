import { AdminApi } from "./atsApi";

class EventsApi {
  constructor() {
    this.api = new AdminApi("events");
  }

  unregister = (registrationId) => this.api.postRequest(`unregister?plannedEventRegistrationId=${registrationId}`);

  getAllLeagues = () => this.api.getRequest("leagues");
  getAllTournaments = () => this.api.getRequest("tournaments");
  getAllChampionships = () => this.api.getRequest("championships");

  getEvent = (eventId, plannedEventId) => this.api.getRequest(`?eventId=${eventId ?? ""}&plannedEventId=${plannedEventId ?? ""}`);

  getActiveEvents = () => this.api.getRequest("active");

  getOpenRegistrations = () => this.api.getRequest("open-registrations");

  getEventStatus = (eventId) => this.api.getRequest(`${eventId}/status`);
}

const eventsApi = new EventsApi();
export default eventsApi;
