import React from "react";

import ListGroup from "react-bootstrap/ListGroup";

import _ from "lodash";

import { toast } from "react-toastify";

import PlannedLeagueApi from "../../api/plannedLeagueApi";

import { getVariantForMessageType } from "../../common/viewControls";
import ConfirmModal from "../../common/confirmModal";
import ProcessingModal from "../../common/processingModal";

import { getLeaguesLink, getPlannedLeagueLink } from "./adminLinkBuilder";
import GroupEdit from "./groupEdit";
import PlannedLeagueEdit from "./plannedLeagueEdit";
import PlannedLeagueView from "./plannedLeagueView";
import SelectGroupModal from "./selectGroupModal";

const ModalEditGroup = "group";
const ModalConfirm = "confirm";
const ModalAssignGroup = "assign-group";
const ModalStart = "start";

class PlannedLeague extends React.Component {
  state = {
    loading: true,
    processing: false,

    isEditMode: true,

    modal: "",
    editGroupModalData: { group: {}, handleSubmit: () => {}, handleCancel: () => {} },
    confirmModalData: { text: "", handleSubmit: () => {}, handleCancel: () => {} },
    selectGroupModalData: { groupId: "", groups: {}, handleSubmit: () => {}, handleCancel: () => {} },

    plannedLeague: {
      plannedLeagueId: this.props.plannedLeagueId,
      eventSeriesId: this.props.eventSeriesId,
      state: "",
      name: "",
      startDate: "",
      endDate: "",
      registerByDate: "",
      registerByTime: "",
      registrations: [],
      registrationUrl: "",
      whatsAppGroupLink: "",
      groups: {},
      messages: [],

      canStart: false,
      isRegistrationOpen: false,
    },
  };

  async componentDidMount() {
    const { plannedLeagueId } = this.state.plannedLeague;

    if (!plannedLeagueId) {
      this.setState({ loading: false, isEditMode: true });
      return;
    }

    this.setState({ processing: true });

    const {
      success,
      data: {
        eventSeriesId,
        state,
        name,
        startDate,
        endDate,
        registerByDate,
        registerByTime,
        registrations,
        registrationUrl,
        whatsAppGroupLink,
        groups,
        messages,

        canStart,
        isRegistrationOpen,
      },
      message,
    } = await PlannedLeagueApi.getOne(plannedLeagueId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({
      loading: false,
      processing: false,

      isEditMode: false,

      plannedLeague: {
        plannedLeagueId,
        eventSeriesId,
        state,
        name,
        startDate,
        endDate,
        registerByDate,
        registerByTime,
        registrations,
        registrationUrl,
        whatsAppGroupLink,
        groups,
        messages,

        canStart,
        isRegistrationOpen,
      },
    });
  }

  handleSubmit = async (plannedLeague) => {
    this.setState({ processing: true });

    const { success, data, message } = await PlannedLeagueApi.setOne(plannedLeague);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    if (!this.state.plannedLeague.plannedLeagueId) {
      window.location.replace(getPlannedLeagueLink(data.plannedLeagueId, data.eventSeriesId));
    } else {
      this.setState({ isEditMode: false, plannedLeague: data, processing: false });
      toast.success("League project saved");
    }
  };

  handleCancel = () => {
    const { plannedLeagueId } = this.state.plannedLeague;

    if (!plannedLeagueId) {
      window.location.replace(getLeaguesLink());
      return;
    }

    this.setState({ isEditMode: false });
  };

  handleCloseRegistration = async () => {
    const { plannedLeagueId } = this.state.plannedLeague;

    this.setState({ processing: true });

    const { success, data, message } = await PlannedLeagueApi.closeRegistration(plannedLeagueId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedLeague: data, processing: false });
    toast.success("Registration is now closed");
  };

  handleOpenRegistration = async () => {
    const { plannedLeagueId } = this.state.plannedLeague;

    this.setState({ processing: true });

    const { success, data, message } = await PlannedLeagueApi.openRegistration(plannedLeagueId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedLeague: data, processing: false });
    toast.success("Registration is now open");
  };

  handleEdit = () => {
    this.setState({ isEditMode: true });
  };

  handleGroupEdit = (group) => {
    this.setState({ modal: ModalEditGroup, editGroupModalData: { group, handleSubmit: this.handleGroupSubmit, handleCancel: this.handleGroupCancel } });
  };

  handleGroupSubmit = async (group) => {
    const { plannedLeague } = this.state;

    plannedLeague.groups[group.id] = group;
    if (group.number === -1) {
      group.number = Object.keys(plannedLeague.groups).length;
    }

    this.setState({ processing: true, modal: "", editGroupModalData: { group: {}, handleSubmit: () => {}, handleCancel: () => {} } });

    const { success, data, message } = await PlannedLeagueApi.setOne(plannedLeague);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedLeague: data, processing: false });
    toast.success("Group saved");
  };

  handleGroupDelete = async (groupId) => {
    const { plannedLeague } = this.state;

    delete plannedLeague.groups[groupId];
    const sortedGroups = _.chain(Object.values(plannedLeague.groups)).orderBy("number", "asc").value();
    for (let index = 0; index < sortedGroups.length; index++) {
      const group = sortedGroups[index];
      group.number = index + 1;
    }

    this.setState({ processing: true });

    const { success, data, message } = await PlannedLeagueApi.setOne(plannedLeague);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedLeague: data, processing: false });
    toast.success("Group deleted");
  };

  handleGroupNumberChange = async (groupId, change) => {
    const { plannedLeague } = this.state;

    const sortedGroups = _.chain(Object.values(plannedLeague.groups)).orderBy("number", "asc").value();
    const index = _.findIndex(sortedGroups, (g) => g.id === groupId);
    const newIndex = index + change;
    if (newIndex < 0 || newIndex >= sortedGroups.length) return;

    var newNumber = sortedGroups[newIndex].number;
    sortedGroups[newIndex].number = sortedGroups[index].number;
    sortedGroups[index].number = newNumber;

    this.setState({ processing: true });

    const { success, data, message } = await PlannedLeagueApi.setOne(plannedLeague);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedLeague: data, processing: false });
    toast.success("Group order changes");
  };

  handleGroupCancel = () => {
    this.setState({ modal: "", editGroupModalData: { group: {}, handleSubmit: () => {}, handleCancel: () => {} } });
  };

  handleShowConfirm = (text, action) => {
    this.setState({
      modal: ModalConfirm,
      confirmModalData: {
        text: text,
        handleSubmit: () => {
          this.handleHideConfirm();
          action();
        },
        handleCancel: this.handleHideConfirm,
      },
    });
  };

  handleHideConfirm = () => {
    this.setState({ modal: "", confirmModalData: { text: "", handleSubmit: () => {}, handleCancel: () => {} } });
  };

  handleRegistrationDelete = async (registration) => {
    const { plannedLeague } = this.state;

    this.setState({ processing: true });

    const { success, data, message } = await PlannedLeagueApi.unregister(plannedLeague.plannedLeagueId, registration.plannedEventRegistrationId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedLeague: data, processing: false });
    toast.success("Group deleted");
  };

  handleRegistrationAssign = async (registration, groupId) => {
    const { plannedLeague } = this.state;

    registration.groupId = groupId;

    this.setState({ processing: true, modal: "", selectGroupModalData: { groupId: "", group: [], handleSubmit: () => {}, handleCancel: () => {} } });

    const { success, data, message } = await PlannedLeagueApi.assignGroup(plannedLeague.plannedLeagueId, registration.plannedEventRegistrationId, groupId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedLeague: data, processing: false });
    toast.success(`${registration.name} is assigned to ${plannedLeague.groups[groupId].name}`);
  };

  handleRegistrationAssignCancel = () => {
    this.setState({ modal: "", selectGroupModalData: { groupId: "", group: [], handleSubmit: () => {}, handleCancel: () => {} } });
  };

  handleStart = async () => {
    const { plannedLeagueId } = this.state.plannedLeague;

    this.setState({ processing: true });

    const { success, message } = await PlannedLeagueApi.start(plannedLeagueId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ processing: false });
    toast.success("League started");
    window.location.replace(getLeaguesLink());
  };

  handleCreateDefaultGroups = async () => {
    const { plannedLeagueId } = this.state.plannedLeague;

    this.setState({ processing: true });

    const { success, data, message } = await PlannedLeagueApi.createdefaultGroups(plannedLeagueId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ plannedLeague: data, processing: false });
    toast.success(`Default groups were created`);
  };

  render() {
    const { loading, processing, isEditMode, modal, editGroupModalData, confirmModalData, selectGroupModalData, plannedLeague } = this.state;

    return (
      <React.Fragment>
        {processing && <ProcessingModal show={true} />}
        {modal === ModalEditGroup && <GroupEdit show={true} data={editGroupModalData} />}
        {modal === ModalConfirm && <ConfirmModal show={true} data={confirmModalData} />}
        {modal === ModalAssignGroup && <SelectGroupModal show={true} data={selectGroupModalData} />}
        {modal === ModalStart && (
          <ConfirmModal show={true} data={confirmModalData}>
            <ListGroup>
              {plannedLeague.messages.map((e, i) => {
                return (
                  <ListGroup.Item key={i} variant={getVariantForMessageType(e.type)}>
                    {e.message}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </ConfirmModal>
        )}
        {!loading && !isEditMode && (
          <PlannedLeagueView
            handleCloseRegistration={this.handleCloseRegistration}
            handleOpenRegistration={this.handleOpenRegistration}
            handleUnregister={this.handleUnregister}
            handleStart={() =>
              this.setState({
                modal: ModalStart,
                confirmModalData: {
                  text: "Please confirm you are happy to start the league",
                  handleSubmit: () => {
                    this.handleHideConfirm();
                    this.handleStart();
                  },
                  handleCancel: this.handleHideConfirm,
                },
              })
            }
            handleEdit={this.handleEdit}
            handleTierChange={this.handleTierChange}
            handleGroupEdit={this.handleGroupEdit}
            handleGroupDelete={(group) =>
              this.handleShowConfirm(`Are you sure you would like to delete ${group.name}?`, () => this.handleGroupDelete(group.id))
            }
            handleGroupNumberChange={this.handleGroupNumberChange}
            handleDeleteRegistration={(registration) =>
              this.handleShowConfirm(`Are you sure you would like to delete ${registration.name}'s registration?`, () =>
                this.handleRegistrationDelete(registration)
              )
            }
            handleAssignRegistration={(registration) => {
              this.setState({
                modal: ModalAssignGroup,
                selectGroupModalData: {
                  groupId: registration.groupId,
                  groups: plannedLeague.groups,
                  handleSubmit: (groupId) => this.handleRegistrationAssign(registration, groupId),
                  handleCancel: this.handleRegistrationAssignCancel,
                },
              });
            }}
            handleCreateDefaultGroups={this.handleCreateDefaultGroups}
            plannedLeague={plannedLeague}
          />
        )}
        {!loading && isEditMode && <PlannedLeagueEdit plannedLeague={plannedLeague} handleSubmit={this.handleSubmit} handleCancel={this.handleCancel} />}
      </React.Fragment>
    );
  }
}

export default PlannedLeague;
