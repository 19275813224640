import ReactGA from "react-ga4";

class Tracking {
  constructor() {
    this.initialized = false;
  }

  initialize = (siteGtag) => {
    if (siteGtag) {
      ReactGA.initialize(siteGtag);
      this.initialized = true;
    }
  };

  pageView = () => {
    if (!this.initialized) return;

    var { hostname, pathname, search } = window.location;

    if (hostname === "localhost") return;

    ReactGA.send({ hitType: "pageview", page: pathname + search });
  };
}

export default Tracking = new Tracking();
