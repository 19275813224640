import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";

import { toast } from "react-toastify";

import { Helmet } from "react-helmet-async";

import ScoreCastApi from "../../api/scoreCastApi";

import ProcessingModal from "../../common/processingModal";
import TextCommentary from "../../common/textCommentary";
import { withQueryParams } from "../../common/queryParams";

import MatchName from "./matchName";
import Serve from "./serve";
import Shot from "./shot";
import ViewScore from "./viewScore";

class CommentMatch extends React.Component {
  state = {
    scoreCastId: this.props.queryParams.id,
    eventName: "",
    side1Name: "",
    side2Name: "",
    startAt: new Date(),

    version: -1,
    viewersCount: 1,
    processing: false,

    playerName: "",

    scoreCastFrame: {
      side2Serve: false,
      serverName: "",
      side1Set1: 0,
      side2Set1: 0,
      side1Set2: 0,
      side2Set2: 0,
      side1Set3: 0,
      side2Set3: 0,
      side1Game: 0,
      side2Game: 0,
      side1Tiebreak: 0,
      side2Tiebreak: 0,

      messages: [],
    },
  };

  async componentDidMount() {
    await this.initData();

    if (!this.refreshInterval) this.refreshInterval = setInterval(async () => await this.refreshData(), 3000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  initData = async () => {
    const { scoreCastId, version } = this.state;
    const { status, data: scoreCastInfo, message } = await ScoreCastApi.getOne(scoreCastId, version, "root");

    if (status === 204) return;
    if (status !== 200) {
      toast.error(message);
      return;
    }

    this.setState({
      eventName: scoreCastInfo.eventName,
      side1Name: scoreCastInfo.side1Name,
      side2Name: scoreCastInfo.side2Name,
      startAt: new Date(scoreCastInfo.startAt),

      version: scoreCastInfo.version,

      scoreCastFrame: {
        side2Serve: scoreCastInfo.side2Serve,
        serverName: scoreCastInfo.serverName,
        side1Set1: scoreCastInfo.side1Set1,
        side2Set1: scoreCastInfo.side2Set1,
        side1Set2: scoreCastInfo.side1Set2,
        side2Set2: scoreCastInfo.side2Set2,
        side1Set3: scoreCastInfo.side1Set3,
        side2Set3: scoreCastInfo.side2Set3,
        side1Game: scoreCastInfo.side1Game,
        side2Game: scoreCastInfo.side2Game,
        side1Tiebreak: scoreCastInfo.side1Tiebreak,
        side2Tiebreak: scoreCastInfo.side2Tiebreak,
        messages: scoreCastInfo.messages,
      },
    });
  };

  refreshData = async () => {
    const { scoreCastId, version } = this.state;
    const { status, data: scoreCastInfo } = await ScoreCastApi.getOne(scoreCastId, version, "root");

    if (status === 204) return;
    if (status !== 200) return;

    this.setState({
      version: scoreCastInfo.version,
      viewersCount: scoreCastInfo.viewersCount,
    });
  };

  pushNewFrame = async (scoreCastFrame) => {
    this.setState({ processing: true });

    const { status, data: scoreCastInfo, message } = await ScoreCastApi.newFrame(this.state.scoreCastId, scoreCastFrame);

    if (status === 204) {
      this.setState({ processing: false });
      return;
    }

    if (status !== 200) {
      toast.error(message);
      this.setState({ processing: false });
      return;
    }

    this.setState({
      processing: false,
      scoreCastFrame: {
        side2Serve: scoreCastInfo.side2Serve,
        serverName: scoreCastInfo.serverName,
        side1Set1: scoreCastInfo.side1Set1,
        side2Set1: scoreCastInfo.side2Set1,
        side1Set2: scoreCastInfo.side1Set2,
        side2Set2: scoreCastInfo.side2Set2,
        side1Set3: scoreCastInfo.side1Set3,
        side2Set3: scoreCastInfo.side2Set3,
        side1Game: scoreCastInfo.side1Game,
        side2Game: scoreCastInfo.side2Game,
        side1Tiebreak: scoreCastInfo.side1Tiebreak,
        side2Tiebreak: scoreCastInfo.side2Tiebreak,
        messages: scoreCastInfo.messages,
      },
    });

    toast.info("Score accepted", { autoClose: 1500 });
  };

  setServeData = (serveData) => {
    const { scoreCastFrame } = this.state;
    scoreCastFrame.point = { playerName: scoreCastFrame.serverName, serve: serveData };
    this.setState({ scoreCastFrame });
    return scoreCastFrame;
  };

  setShotData = (shotData) => {
    const { playerName, scoreCastFrame } = this.state;
    scoreCastFrame.point = { playerName, lastShot: shotData };
    this.setState({ scoreCastFrame, playerName: "" });
    return scoreCastFrame;
  };

  render() {
    const {
      scoreCastId,
      eventName,
      side1Name,
      side2Name,

      version,
      viewersCount,

      playerName,

      scoreCastFrame: {
        side2Serve,
        serverName,
        side1Set1,
        side2Set1,
        side1Set2,
        side2Set2,
        side1Set3,
        side2Set3,
        side1Game,
        side2Game,
        side1Tiebreak,
        side2Tiebreak,

        messages,
      },
    } = this.state;

    const scoreInfo = {
      side1Name,
      side2Name,

      side2Serve,
      side1Set1,
      side2Set1,
      side1Set2,
      side2Set2,
      side1Set3,
      side2Set3,
      side1Game,
      side2Game,
      side1Tiebreak,
      side2Tiebreak,
    };

    const scale = 0.75;

    return (
      <>
        <Helmet>
          <meta name="viewport" content={`width=device-width, initial-scale=${scale}, minimum-scale=${scale} maximum-scale=${scale}`} />
        </Helmet>
        {this.state.processing && <ProcessingModal show={true} />}
        <Container fluid>
          <Row className="g-0">
            <Col>
              <MatchName eventName={eventName} scoreCastId={scoreCastId} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ViewScore scoreInfo={scoreInfo} viewersCount={viewersCount} />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Serve serverName={serverName} onServeClick={async (sd) => await this.pushNewFrame(this.setServeData(sd))} />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <ButtonGroup>
                {side1Name.split("&").map((name) => (
                  <Button key={name} variant={playerName === name ? "info" : "outline-info"} onClick={() => this.setState({ playerName: name })}>
                    {name}
                  </Button>
                ))}
                {side2Name.split("&").map((name) => (
                  <Button key={name} variant={playerName === name ? "info" : "outline-info"} onClick={() => this.setState({ playerName: name })}>
                    {name}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Stack gap="2" direction="horizontal">
                <Shot disabled={!playerName} onShotClick={async (sd) => await this.pushNewFrame(this.setShotData(sd))}></Shot>
              </Stack>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <TextCommentary messages={messages} count={version}></TextCommentary>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withQueryParams(CommentMatch);
