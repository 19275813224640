import React from "react";

import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";

import _ from "lodash";

import { toast } from "react-toastify";

import ScoreApi from "../../api/scoreApi";
import UtilsApi from "../../api/utilsApi";

class UnconfirmedScores extends React.Component {
  state = {
    loaded: false,

    matches: [],
  };

  async componentDidMount() {
    await this.refreshData();
  }

  refreshData = async () => {
    const { success, data: matches, message } = await UtilsApi.getUnconfirmedScores();

    if (!success) {
      toast.error(message);
      return;
    }

    this.setState({ loaded: true, matches });
  };

  handleSetMatchScore = async (matchId, scoreTypeId, score) => {
    const gameScores = score.trim().split(" ");

    if (gameScores.length !== 2 && gameScores.length !== 3) {
      toast.error("Invalid score");
      return;
    }

    const { matches } = this.state;
    _.remove(matches, (m) => m.matchId === matchId);
    this.setState({ matches });

    const toastId = toast.info("Updating score...", { autoClose: false });
    const { success, message } = await ScoreApi.setScoreNew(matchId, scoreTypeId, score);

    if (success) {
      toast.update(toastId, { render: "Score updated", autoClose: 5000, type: toast.TYPE.SUCCESS });
    } else {
      toast.update(toastId, { render: message, autoClose: 5000, type: toast.TYPE.ERROR });
    }

    await this.refreshData();
  };

  handleRemind = async (scoreDataId) => {
    const toastId = toast.info("Reminding players...", { autoClose: false });
    const { success, message } = await UtilsApi.remindScoreData(scoreDataId);

    if (success) {
      toast.update(toastId, { render: "Players were reminded", autoClose: 5000, type: toast.TYPE.SUCCESS });
    } else {
      toast.update(toastId, { render: message, autoClose: 5000, type: toast.TYPE.ERROR });
    }

    await this.refreshData();
  };

  handleDelete = async (scoreDataId) => {
    const { matches } = this.state;
    _.remove(matches, (m) => m.id === scoreDataId);
    this.setState({ matches });

    const toastId = toast.info("Deleting score data...", { autoClose: false });
    const { success, message } = await UtilsApi.deleteScoreData(scoreDataId);

    if (success) {
      toast.update(toastId, { render: "Score data was deleted", autoClose: 5000, type: toast.TYPE.SUCCESS });
    } else {
      toast.update(toastId, { render: message, autoClose: 5000, type: toast.TYPE.ERROR });
    }

    await this.refreshData();
  };

  render() {
    return (
      <>
        <Table size="sm" stripped="true" hover>
          <tbody>
            {this.state.matches.map((m) => {
              return (
                <React.Fragment key={m.scoreDataId}>
                  <tr key={`${m.scoreDataId}-1`}>
                    <td>{m.side1}</td>
                    <td>{m.side1Date}</td>
                    <td>{m.side1Score}</td>
                    <td>
                      {m.side1Score && (
                        <Button size="sm" variant="outline-primary" onClick={() => this.handleSetMatchScore(m.matchId, m.scoreTypeId, m.side1Score)}>
                          SET
                        </Button>
                      )}
                    </td>
                  </tr>
                  <tr key={`${m.scoreDataId}-2`}>
                    <td>{m.side2}</td>
                    <td>{m.side2Date}</td>
                    <td>{m.side2Score}</td>
                    <td>
                      {m.side2Score && (
                        <Button size="sm" variant="outline-primary" onClick={() => this.handleSetMatchScore(m.matchId, m.scoreTypeId, m.side2Score)}>
                          SET
                        </Button>
                      )}
                    </td>
                  </tr>
                  <tr key={`${m.scoreDataId}-controls`}>
                    <td colSpan="4" className="pb-4">
                      <InputGroup size="sm" className="align-items-center">
                        <Button key="remind" size="sm" variant="outline-primary" onClick={() => this.handleRemind(m.scoreDataId)}>
                          REMIND
                        </Button>
                        <Button key="delete" size="sm" variant="outline-danger" onClick={() => this.handleDelete(m.scoreDataId)}>
                          DELETE
                        </Button>
                      </InputGroup>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  }
}

export default UnconfirmedScores;
