import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import _ from "lodash";

import { toast } from "react-toastify";

import { IoIosTennisball } from "react-icons/io";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { autoSizeAll } from "../../common/agGridUtils";

import FindOpponentApi from "../../api/findOpponentApi";

import { withStaticData } from "../../common/staticData";
import { LinkBuilder } from "./findOpponentLinkBuilder";

const colours = { Draft: "gold", Active: "blue", Arranged: "green" };

class MyMatches extends React.Component {
  state = {
    loaded: false,
    myMatches: [],
  };

  async componentDidMount() {
    const { success, data: myMatches, message } = await FindOpponentApi.getMyMatches();

    if (!success) {
      toast.error(message);
      return;
    }

    this.setState({ myMatches, loaded: true });
  }

  handleDelete = async (e, availableMatchId) => {
    e.stopPropagation();

    const { myMatches } = this.state;

    const { success, message } = await FindOpponentApi.deleteMyMatch(availableMatchId);
    if (!success) {
      toast.error(message);
      return;
    }

    _.remove(myMatches, (m) => m.availableMatchId === availableMatchId);

    this.setState({ myMatches });
    toast.success("Match deleted");
  };

  onRowClicked = (params) => {
    let { availableMatchId } = params.data;
    window.location.assign(LinkBuilder.getMyMatchLink(availableMatchId));
  };

  render() {
    const { staticData } = this.props;
    const myMatches = _.chain(this.state.myMatches)
      .map((m) => {
        return { ...m, ...{ dateView: staticData.dates[m.date], court: staticData.courts[m.courtId], matchType: staticData.matchTypes[m.matchTypeId] } };
      })
      .orderBy(["date"], ["asc"])
      .value();

    const buttonRenderer = (props) => (
      <>
        <IoIosTennisball size="25" color={colours[props.data.currentState]} />
        <Button className="ps-2 ms-2" size="sm" variant="danger" onClick={(e) => this.handleDelete(e, props.data.availableMatchId)}>
          DEL
        </Button>
      </>
    );

    return (
      <Container fluid className="ag-theme-alpine">
        <Row>
          <Col>
            <h1>
              Find Opponent - My Matches{" "}
              <Button variant="primary" href={LinkBuilder.getMyMatchLink()}>
                New
              </Button>
            </h1>
            <br />
            {!this.state.loaded && <Spinner animation="border" role="status" />}
            {this.state.loaded && (
              <AgGridReact
                rowData={myMatches}
                rowStyle={{ cursor: "pointer" }}
                domLayout="autoHeight"
                onGridSizeChanged={autoSizeAll}
                onFirstDataRendered={autoSizeAll}
                onRowClicked={this.onRowClicked}
                frameworkComponents={{ buttonRenderer: buttonRenderer }}
                overlayNoRowsTemplate="No matches found"
              >
                <AgGridColumn headerName="" cellRenderer="buttonRenderer" />
                <AgGridColumn field="dateView" headerName="Date"></AgGridColumn>
                <AgGridColumn field="time" headerName="Time"></AgGridColumn>
                <AgGridColumn field="court" headerName="Court"></AgGridColumn>
                <AgGridColumn field="matchType" headerName="MatchType"></AgGridColumn>
                <AgGridColumn field="comment" headerName="Comment"></AgGridColumn>
              </AgGridReact>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStaticData(MyMatches);
