import React from "react";

import { useQueryParams } from "../common/queryParams";
import { useSettings } from "../common/withSettings";
import Tracking from "../common/tracking";

import Home from "../apps/home";
import PlayerLoginApp from "../apps/login/playerLoginApp";
import AdminApp from "../apps/admin/adminApp";
import CourtAlertsApp from "../apps/court-alerts/courtAlertsApp";
import FindOpponentApp from "../apps/find-opponent/findOpponentApp";
import TournamentApp from "../apps/tournament/tournamentApp";
import UmpireCastApp from "../apps/umpire-cast/umpireCastApp";
import ClubStreamApp from "../apps/club-stream/clubStreamApp";
import { isAdmin, isAuthenticated } from "../common/auth";
import SetScoreApp from "../apps/set-score/setScoreApp";
import WrappedApp from "../apps/wrapped/wrappedApp";
import RallyRecordApp from "../apps/rally-record/rallyRecordApp";
import JoinApp from "../apps/join/joinApp";

class AtsRouter extends React.Component {
  render() {
    return <GetApp />;
  }
}

function GetApp(props) {
  const queryParams = useQueryParams();
  const settings = useSettings();

  Tracking.initialize(settings.siteGtag);
  Tracking.pageView();

  if (settings.defaultApp === "rally-record") return <RallyRecordApp />;
  if (settings.defaultApp === "join") {
    if ("signin" in queryParams) return <PlayerLoginApp />;
    if (queryParams.app === "admin") {
      if (isAdmin()) return <AdminApp />;
      return <PlayerLoginApp />;
    }
    return <JoinApp />;
  }

  if ("signin" in queryParams) return <PlayerLoginApp />;

  if ("app" in queryParams) {
    if (queryParams.app === "admin") {
      if (isAdmin()) return <AdminApp />;
      return <PlayerLoginApp />;
    }
    if (settings.courtAlertsEnabled && queryParams.app === "court-alerts") return isAuthenticated() ? <CourtAlertsApp /> : <PlayerLoginApp inPlace={true} />;
    if (settings.findOpponentEnabled && queryParams.app === "find-opponent") return isAuthenticated() ? <FindOpponentApp /> : <PlayerLoginApp />;
    if (settings.tournamentsEnabled && queryParams.app === "tournament") return <TournamentApp />;
    if (settings.umpireCastEnabled && queryParams.app === "umpire-cast") return <UmpireCastApp />;
    if (settings.clubStreamEnabled && queryParams.app === "club-stream") return <ClubStreamApp />;
    if (queryParams.app === "set-score") return <SetScoreApp />;
    if (queryParams.app === "wrapped") return <WrappedApp />;
  }

  return <Home />;
}

export default AtsRouter;
