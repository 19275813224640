import React from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import _ from "lodash";

import { toast } from "react-toastify";

import ProcessingModal from "../../common/processingModal";
import { TextEdit } from "../../common/editControls";
import RallyRecordApi from "../../api/rallyRecordApi";

class ChildEdit extends React.Component {
  state = {
    processing: false,
    children: [
      {
        id: "",
        name: "",
      },
    ],
  };

  handleChange = (e, index) => {
    const { children } = this.state;
    children[index].name = e.currentTarget.value;

    if (_.every(children, (c) => c.name !== "")) {
      children.push({ id: "", name: "" });
    }
    this.setState({ children });
  };

  save = async (e) => {
    if (e !== undefined) e.preventDefault();

    this.setState({ processing: true });

    const { children } = this.state;
    const nonEmptyChildren = _.filter(children, (c) => c.id || c.name);

    const { success, message } = await RallyRecordApi.updateChildren(nonEmptyChildren);
    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    window.location.reload();
  };

  render() {
    const { processing, children } = this.state;

    const nonEmptyChildren = _.filter(children, (c) => c.id || c.name);

    return (
      <React.Fragment>
        <ProcessingModal show={processing} />
        <Container fluid="md">
          <Row>
            <Col>
              <Form onSubmit={this.save}>
                <h3 className="mt-3 fw-bold">Let's add children:</h3>
                {children.map((c, i) => {
                  return (
                    <TextEdit
                      key={i}
                      id="name"
                      label="Name of a child"
                      placeholder="Name of a child"
                      value={c.name}
                      onChange={(e) => this.handleChange(e, i)}
                    />
                  );
                })}
                <Button className="mt-3 me-1" variant="primary" size="lg" type="submit" disabled={nonEmptyChildren.length === 0}>
                  Continue
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default ChildEdit;
