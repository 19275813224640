import { getPageLink } from "../../common/appLinkBuilder";
import { isLeague } from "../../common/eventSeries";
import { getEventSeriesPath } from "../../common/eventSeries";

export function getPlannedLeagueLink(plannedLeagueId, eventSeriesId) {
  return getPageLink("admin", "planned-league", { id: plannedLeagueId ?? "", eventSeriesId: eventSeriesId });
}

export function getLeagueLink(name, eventId, plannedEventId) {
  return getPageLink("admin", "league", { name: name, eventId: eventId, plannedEventId: plannedEventId });
}

export function getLeaguesLink() {
  return getPageLink("admin", "leagues");
}

export function getEventSeriesLink() {
  return getPageLink("admin");
}

export function getPlannedEventLink(plannedEvent) {
  if (isLeague(plannedEvent.eventSeriesId)) return getPlannedLeagueLink(plannedEvent.key, plannedEvent.eventSeriesId);
  return getPageLink("admin", getEventSeriesPath(plannedEvent.eventSeriesId), { name: plannedEvent.name, plannedEventId: plannedEvent.key });
}

export function getActiveEventLink(activeEvent) {
  return getPageLink("admin", getEventSeriesPath(activeEvent.eventSeriesId), { name: activeEvent.name, eventId: activeEvent.key });
}

export function getTournamentsLink() {
  return getPageLink("admin", "tournaments");
}

export function getChampionshipsLink() {
  return getPageLink("admin", "championships");
}

export function getScheduledJobsLink() {
  return getPageLink("admin", "scheduled-jobs");
}
