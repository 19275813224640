import React from "react";

import BestOfThree from "./bestOfThree";
import BestOfThreePickle from "./bestOfThreePickle";

class EnterScore extends React.Component {
  state = {};

  render() {
    const { match, handleSubmit } = this.props;

    return (
      <React.Fragment>
        <GetScoreControl match={match} handleSubmit={handleSubmit} />
      </React.Fragment>
    );
  }
}

function GetScoreControl(props) {
  const { match, handleSubmit } = props;

  switch (match.scoreTypeId) {
    case "best-of-three":
      return <BestOfThree match={match} handleSubmit={handleSubmit} />;
    case "best-of-three-pickle":
      return <BestOfThreePickle match={match} handleSubmit={handleSubmit} />;
    default:
      return (
        <div>
          {match.side1} vs {match.side2} - {match.scoreTypeId}
        </div>
      );
  }
}

export default EnterScore;
