import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import { toast } from "react-toastify";

import { Helmet } from "react-helmet-async";

import Matches from "./matches";
import Rules from "./rules";
import Standings from "./standings";
import UserControls from "./userControls";

import TournamentApi from "../../api/tournamentApi";
import { withQueryParams } from "../../common/queryParams";
import { isAuthenticated } from "../../common/auth";
import Login from "../../common/login";
import ProcessingModal from "../../common/processingModal";

class Tournament extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventId: props.queryParams.id,
      eventName: "",
      version: -1,
      scoreTypeId: "tournament-15",
      standings: [],
      allMatches: [],
      setScoreMatches: [],
      isAdmin: localStorage.getItem("atsIsAdmin") === "true",
      validNewRoundRequests: [],
      processing: false,
      rules: [],
      showLogin: false,
      isDisconnected: false,
    };
  }

  async componentDidMount() {
    await this.loadStaticData();
    await this.refreshData();

    this.interval = setInterval(async () => this.refreshData(), 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadStaticData = async () => {
    const { eventId } = this.state;
    const { status, data: staticData } = await TournamentApi.getTournamentStaticData(eventId);

    if (status !== 200) {
      return;
    }
    this.setState({
      rules: staticData.rules,
    });
  };

  refreshData = async () => {
    const { eventId, version } = this.state;
    const { status, data: tournamentInfo, message } = await TournamentApi.getTournament(eventId, version);

    if (status === 204) {
      this.setState({ isDisconnected: false });
      return;
    }

    if (status !== 200) {
      if (status > 0) {
        toast.error(message);
      } else {
        this.setState({ isDisconnected: true });
      }
      return;
    }

    this.setState({
      eventName: tournamentInfo.name,
      standings: tournamentInfo.standings,
      allMatches: tournamentInfo.allMatches,
      setScoreMatches: tournamentInfo.setScoreMatches,
      version: tournamentInfo.version,
      scoreTypeId: tournamentInfo.scoreTypeId,
      validNewRoundRequests: tournamentInfo.validNewRoundRequests,
      isDisconnected: false,
    });
  };

  handleNewRound = async (r) => {
    this.setState({ processing: true });

    const { success, data: response, message } = await TournamentApi.postNewRound(this.state.eventId, r);

    this.setState({ processing: false });

    if (!success) {
      toast.error(message);
      return;
    }

    let toastText = "New round created";
    if (r.type === "Delete") toastText = "Last round deleted";
    if (r.type === "Unseal") toastText = "Event is now un-sealed";
    toast(toastText);

    this.setState({
      allMatches: [...this.state.allMatches, ...response.matches],
      setScoreMatches: [...this.state.setScoreMatches, ...response.matches],
      validNewRoundRequests: [],
    });
  };

  handleNewScore = async (scoreData) => {
    this.setState({ processing: true });

    const { eventId } = this.state;
    const { data: tournamentInfo, status, message } = await TournamentApi.postNewScore(eventId, scoreData);

    this.setState({ processing: false });

    if (status === 200) {
      this.setState({
        eventName: tournamentInfo.name,
        standings: tournamentInfo.standings,
        allMatches: tournamentInfo.allMatches,
        setScoreMatches: tournamentInfo.setScoreMatches,
        scoreData: { matchId: "", scoreId: "" },
        validNewRoundRequests: tournamentInfo.validNewRoundRequests,
      });
      toast("Score processed and standings are updated");
    } else if (status === 202) {
      this.setState({ scoreData: { matchId: "", scoreId: "" } });
      toast("Score accepted, waiting for score from your opponent");
    } else if (status === 409) {
      toast.error("Score you entered conflicts with your opponent's");
    } else {
      toast.error(message);
    }
  };

  render() {
    const { eventName, scoreTypeId, standings, allMatches, setScoreMatches, validNewRoundRequests, processing, rules, showLogin, isDisconnected } = this.state;

    const isAuth = isAuthenticated();

    return (
      <>
        <ProcessingModal show={processing} />
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=0.85, minimum-scale=0.85, maximum-scale=0.85, user-scalable=no" />
        </Helmet>
        <Container fluid="md">
          <Row className="g-0">
            <Col>
              <h1>{eventName}</h1>
              {isDisconnected && <h5 className="text-danger">You seem to be offline, please check your wifi or mobile connection.</h5>}
              {isAuth && (
                <UserControls
                  onNewRound={this.handleNewRound}
                  onNewScore={this.handleNewScore}
                  matches={setScoreMatches}
                  scoreTypeId={scoreTypeId}
                  validNewRoundRequests={validNewRoundRequests}
                />
              )}
              {!isAuth && !showLogin && (
                <Button variant="outline-primary" size="sm" onClick={() => this.setState({ showLogin: true })}>
                  Sign In to enter scores
                </Button>
              )}
              {!isAuth && showLogin && <Login />}
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <Standings standings={standings} />
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <Matches matches={allMatches} />
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <h2 className="text-center">Rules:</h2>
              <Rules rules={rules} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withQueryParams(Tournament);
