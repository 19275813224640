import React from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import ProcessingModal from "../../common/processingModal";
import { TextEdit, SelectEdit } from "../../common/editControls";
import { PaymentElement } from "@stripe/react-stripe-js";
import { withStripeElements } from "./../../common/withStripeSettings";

class JoinEventFields extends React.Component {
  state = {
    processing: false,

    data: {},
  };

  handleChange = (e) => {
    const { data } = this.state;
    data[e.target.id] = e.target.value;
    this.setState({ data });
  };

  handleSubmit = (e) => {
    if (e !== undefined) e.preventDefault();

    const { stripe, elements, handleSubmit } = this.props;
    const { data } = this.state;
    handleSubmit(stripe, elements, data);
  };

  render() {
    const { processing, data } = this.state;
    const { buttonText, price, registrationFields, stripe, elements } = this.props;

    let canRequest = true;
    const missingFields = [];
    registrationFields.forEach((field) => {
      if (!data[field.name]) {
        canRequest = false;
        missingFields.push(field.name);
      }
    });

    const canPurchase = canRequest && stripe && elements;

    return (
      <React.Fragment>
        <ProcessingModal show={processing} />
        <Form>
          {registrationFields.map((field) => {
            if (field.type === "select") {
              return (
                <SelectEdit
                  key={field.name}
                  id={field.name}
                  label={field.title}
                  items={field.choices}
                  value={data[field.name]}
                  helpText={field.helpText}
                  onChange={this.handleChange}
                />
              );
            }
            return <TextEdit id={field.id} label={field.label} value={field.value} onChange={this.handleChange} />;
          })}
          {price && (
            <div className="mt-5">
              <PaymentElement id="payment-element" options={{ layout: "tabs" }} />
            </div>
          )}
          <Button className="mt-5" variant={canPurchase ? "primary" : "danger-outline"} size="lg" disabled={!canPurchase} onClick={this.handleSubmit}>
            {buttonText} {price && ` - £${price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
          </Button>
          {!canRequest && (
            <Alert variant="danger" className="mt-3">{`Please fill in the missing field${missingFields.length > 1 ? "s" : ""}: ${missingFields.join(
              ", "
            )}`}</Alert>
          )}
        </Form>
      </React.Fragment>
    );
  }
}

export default withStripeElements(JoinEventFields);
