import React, { useContext } from "react";
import SettingsContext from "../contexts/settingsContext";

export function withSettings(Component) {
  return (props) => <Component {...props} settings={useContext(SettingsContext)} />;
}

export function useSettings() {
  return useContext(SettingsContext);
}
