import { getPageLink } from "../../common/appLinkBuilder";

const appName = "umpire-cast";

export const Page = {
  Matches: "matches",
  Match: "match",
  UmpireMatch: "umpire-match",
  CommentMatch: "comment-match",
  ParentMatch: "parent-match",
  EditMatch: "edit-match",
};

class UmpireCastLinkBuilder {
  getMatchesLink = () => getPageLink(appName, Page.Matches);
  getMatchLink = (matchId) => getPageLink(appName, Page.Match, { id: matchId });
  getUmpireMatchLink = (matchId) => getPageLink(appName, Page.UmpireMatch, { id: matchId });
  getCommentMatchLink = (matchId) => getPageLink(appName, Page.CommentMatch, { id: matchId });
  getParentMatchLink = (matchId) => getPageLink(appName, Page.ParentMatch, { id: matchId });
  getNewMatchLink = () => getPageLink(appName, Page.EditMatch);
  getEditMatchLink = (matchId) => getPageLink(appName, Page.EditMatch, { id: matchId });
}

export const LinkBuilder = new UmpireCastLinkBuilder();
