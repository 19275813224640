import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import Table from "react-bootstrap/Table";

import moment from "moment";

import FlashChange from "@avinlab/react-flash-change";

import { IoIosTennisball } from "react-icons/io";

import ScoreCastApi from "../../api/scoreCastApi";

import { getSide1Score, getSide2Score } from "../../common/formatGameScore";
import { withQueryParams } from "../../common/queryParams";
import { withSettings } from "../../common/withSettings";
import { getLink } from "../../common/appLinkBuilder";

class MatchStream extends React.Component {
  state = {
    eventName: "",
    side1Name: "",
    side2Name: "",
    startAt: new Date(),
    currentTime: new Date(),
    lastUpdate: new Date(),
    lastScoreChange: new Date(),

    version: -1,
    viewerId: "na",
    viewersCount: 1,

    side2Serve: false,
    side1Set1: 0,
    side2Set1: 0,
    side1Set2: 0,
    side2Set2: 0,
    side1Set3: 0,
    side2Set3: 0,
    side1Game: 0,
    side2Game: 0,
    side1Tiebreak: 0,
    side2Tiebreak: 0,
    messages: [],
  };

  async componentDidMount() {
    await this.refreshData();

    if (!this.refreshInterval) this.refreshInterval = setInterval(async () => await this.refreshData(), 3000);
    if (!this.timerInterval) this.timerInterval = setInterval(() => this.timer(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
    clearInterval(this.timerInterval);
  }

  timer = () => {
    this.setState({ currentTime: new Date() });
  };

  refreshData = async () => {
    const { version, viewerId } = this.state;
    const { status, data: scoreCastInfo } = await ScoreCastApi.getOne(this.props.queryParams.id, version, viewerId);

    this.setState({ lastUpdate: new Date() });

    if (status === 204) return;
    if (status !== 200) return;

    this.setState({
      eventName: scoreCastInfo.eventName,
      side1Name: scoreCastInfo.side1Name,
      side2Name: scoreCastInfo.side2Name,
      startAt: new Date(scoreCastInfo.startAt),
      lastScoreChange: new Date(scoreCastInfo.lastScoreChange),

      version: scoreCastInfo.version,
      viewerId: scoreCastInfo.viewerId,
      viewersCount: scoreCastInfo.viewersCount,

      side2Serve: scoreCastInfo.side2Serve,
      side1Set1: scoreCastInfo.side1Set1,
      side2Set1: scoreCastInfo.side2Set1,
      side1Set2: scoreCastInfo.side1Set2,
      side2Set2: scoreCastInfo.side2Set2,
      side1Set3: scoreCastInfo.side1Set3,
      side2Set3: scoreCastInfo.side2Set3,
      side1Game: scoreCastInfo.side1Game,
      side2Game: scoreCastInfo.side2Game,
      side1Tiebreak: scoreCastInfo.side1Tiebreak,
      side2Tiebreak: scoreCastInfo.side2Tiebreak,
      messages: scoreCastInfo.messages,
    });
  };

  render() {
    const {
      eventName,
      side1Name,
      side2Name,
      startAt,
      currentTime,
      lastUpdate,
      lastScoreChange,

      viewersCount,

      side2Serve,
      side1Set1,
      side2Set1,
      side1Set2,
      side2Set2,
      side1Set3,
      side2Set3,
      side1Game,
      side2Game,
      side1Tiebreak,
      side2Tiebreak,
    } = this.state;

    const {
      settings: { siteWhitelabel },
    } = this.props;

    const delay = currentTime - lastUpdate;
    const changeDiff = moment.duration(lastScoreChange - currentTime);

    const side1GameText = getSide1Score(side1Game, side2Game);
    const side2GameText = getSide2Score(side1Game, side2Game);

    const showGameScore = side1GameText !== "0" || side2GameText !== "0";
    const showTieBreakScore = side1Tiebreak !== 0 || side2Tiebreak !== 0;

    return (
      <Container fluid="md">
        <Row className="mt-1">
          <Col>
            <Stack direction="horizontal" gap="4">
              <img src={getLink(`${siteWhitelabel}.logo.png`)} height="40" alt="" />
              <h4>Club Stream - {eventName}</h4>
            </Stack>
          </Col>
        </Row>
        <Row className="g-0">
          <Col>
            <h6>
              Match starts at {startAt.toLocaleDateString()} {startAt.toLocaleTimeString()}
            </h6>
          </Col>
        </Row>
        <Row className="g-0">
          <Col>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <td align="middle" className="fw-bold">
                    Set 1
                  </td>
                  <td align="middle" className="fw-bold">
                    Set 2
                  </td>
                  <td align="middle" className="fw-bold">
                    Set 3
                  </td>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <h3>
                      {side1Name} {!side2Serve && <IoIosTennisball size="25" color="magenta" />}
                    </h3>
                  </th>
                  <td align="middle">
                    <ShowFlash value={side1Set1} />
                  </td>
                  <td align="middle">
                    <ShowFlash value={side1Set2} />
                  </td>
                  <td align="middle">
                    <ShowFlash value={side1Set3} />
                  </td>
                  <td align="middle">
                    {showGameScore && <ShowFlash value={side1GameText} />}
                    {showTieBreakScore && <ShowFlash value={side1Tiebreak} />}
                    {!showGameScore && !showTieBreakScore && (
                      <h3>
                        <div className="ps-1 pe-1" style={{ minWidth: "2em" }}>
                          &nbsp;
                        </div>
                      </h3>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    <h3>
                      {side2Name} {side2Serve && <IoIosTennisball size="25" color="magenta" />}
                    </h3>
                  </th>
                  <td align="middle">
                    <ShowFlash value={side2Set1} />
                  </td>
                  <td align="middle">
                    <ShowFlash value={side2Set2} />
                  </td>
                  <td align="middle">
                    <ShowFlash value={side2Set3} />
                  </td>
                  <td align="middle">
                    {showGameScore && <ShowFlash value={side2GameText} />}
                    {showTieBreakScore && <ShowFlash value={side2Tiebreak} />}
                    {!showGameScore && !showTieBreakScore && (
                      <h3>
                        <div className="ps-1 pe-1" style={{ minWidth: "2em" }}>
                          &nbsp;
                        </div>
                      </h3>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row className="g-0 mt-1">
          <Col>
            {delay > 20000 ? (
              <div className="text-danger fw-bold">Disconnected, please check your mobile data or wifi connection</div>
            ) : (
              <div className="text-success fw-bold">Connected to UmpireCast.</div>
            )}
          </Col>
        </Row>
        <Row className="g-0">
          <Col>{viewersCount} viewers connected to the stream</Col>
        </Row>
        <Row className="g-0">
          <Col>Last score change: {changeDiff.humanize(true)}</Col>
        </Row>
      </Container>
    );
  }
}

function ShowFlash(props) {
  return (
    <FlashChange
      value={props.value}
      className="ps-1 pe-1"
      flashClassName="fw-bold text-success text-white bg-success"
      flashDuration="5000"
      outerElementType="div"
    >
      <h3>{props.value}</h3>
    </FlashChange>
  );
}

export default withSettings(withQueryParams(MatchStream));
