import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import Tooltip from "react-bootstrap/Tooltip";

import { Helmet } from "react-helmet-async";

import QRCode from "react-qr-code";

import { MdOutlineHelp } from "react-icons/md";
import { IoMdCloseCircle, IoMdDoneAll, IoMdCopy, IoMdShare, IoMdCreate, IoMdTennisball, IoMdRefresh } from "react-icons/io";
import { MdOutlineAddBox, MdOutlineLibraryAdd } from "react-icons/md";

import { getPrimary } from "../../common/colours";
import { withSettings } from "../../common/withSettings";
import { FieldRow, getVariantForMessageType, Section } from "../../common/viewControls";

import Rules from "./rules";
import Table from "react-bootstrap/esm/Table";

class PlannedTournamentView extends React.Component {
  render() {
    const {
      settings: { staticData },
      plannedTournament: {
        name,
        date,
        startTime,
        endTime,
        eventInfoId,
        scoringFormatId,
        eventVisibilityId,
        courtInfo,
        registrations,
        registrationsSummary,
        registrationUrl,
        isRegistrationOpen,
        canStart,
        messages,
        whatsAppGroupLink,
        rules,
      },
      handleCloseRegistration,
      handleOpenRegistration,
      handleUnregister,
      handleCheckIn,
      handleStart,
      handleEdit,
      handleTierChange,
      handleAddRegistration,
      handleAddRegistrations,
    } = this.props;

    const tierLabels = staticData.tiers[eventInfoId];

    const shareData = {
      title: name,
      text: `Register for ${name} here:`,
      url: registrationUrl,
    };

    return (
      <React.Fragment>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=0.85, minimum-scale=0.85, maximum-scale=0.85, user-scalable=no" />
        </Helmet>
        <Container>
          <Row>
            <Col>
              <h1>{name}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonGroup>
                <Button variant="outline-warning" size="sm" onClick={handleEdit}>
                  <IoMdCreate size="25" />
                </Button>
                <Button variant="outline-primary" size="sm" onClick={() => window.location.reload()}>
                  <IoMdRefresh size="25" />
                </Button>
                <Button variant="outline-primary" size="sm" disabled={!canStart} onClick={handleStart}>
                  <IoMdTennisball size="25" />
                  START
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm="auto">
              <Table>
                <tbody>
                  <FieldRow id="registration" label="Registration">
                    {isRegistrationOpen && (
                      <React.Fragment>
                        Is Open{" "}
                        <ButtonGroup>
                          <Button variant="outline-primary" size="sm" onClick={handleCloseRegistration}>
                            CLOSE
                          </Button>
                          <Button variant="outline-primary" size="sm" onClick={async () => await navigator.share(shareData)}>
                            <IoMdShare size="25" />
                          </Button>
                        </ButtonGroup>
                      </React.Fragment>
                    )}
                    {!isRegistrationOpen && (
                      <React.Fragment>
                        Is Closed{" "}
                        <Button variant="outline-primary" size="sm" onClick={handleOpenRegistration}>
                          OPEN
                        </Button>
                      </React.Fragment>
                    )}
                  </FieldRow>
                  <FieldRow id="start" label="Start">
                    {`${new Date(date).toLocaleDateString()} ${startTime}`}
                  </FieldRow>
                  <FieldRow id="end" label="End">
                    {`${new Date(date).toLocaleDateString()} ${endTime}`}
                  </FieldRow>
                  <FieldRow id="eventType" label="Event Type">
                    <Stack gap={2} direction="horizontal">
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>{staticData.eventInfos[eventInfoId].description}</Tooltip>}>
                        <span>{staticData.eventInfos[eventInfoId].name}</span>
                      </OverlayTrigger>
                      <HelpOverlay label="Defines tournament type" subtitle="Following tournament types are available:" items={staticData.eventInfos} />
                    </Stack>
                  </FieldRow>
                  <FieldRow id="scoringFormat" label="Scoring Format">
                    <Stack gap={2} direction="horizontal">
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>{staticData.scoringFormats[scoringFormatId].description}</Tooltip>}>
                        <span>{staticData.scoringFormats[scoringFormatId].name}</span>
                      </OverlayTrigger>
                      <HelpOverlay label="Defines scoring format" subtitle="Following scoring formats are available:" items={staticData.scoringFormats} />
                    </Stack>
                  </FieldRow>
                  <FieldRow id="visibility" label="Visibility">
                    <Stack gap={2} direction="horizontal">
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>{staticData.tournamentVisibilities[eventVisibilityId].description}</Tooltip>}>
                        <span>{staticData.tournamentVisibilities[eventVisibilityId].name}</span>
                      </OverlayTrigger>
                      <HelpOverlay
                        label="Defines who can see the registration page"
                        subtitle="Following options are available:"
                        items={staticData.tournamentVisibilities}
                      />
                    </Stack>
                  </FieldRow>
                  <FieldRow id="courts" label="Courts">
                    {courtInfo}
                  </FieldRow>
                  <FieldRow id="whatsAppGroup" label="WhatsApp Group">
                    {whatsAppGroupLink && (
                      <React.Fragment>
                        <Stack direction="horizontal" gap="2">
                          <a href={whatsAppGroupLink} target="_blank" rel="noreferrer">
                            Open
                          </a>
                          <IoMdCopy size="25" color={getPrimary()} onClick={() => navigator.clipboard.writeText(whatsAppGroupLink)} />
                        </Stack>
                      </React.Fragment>
                    )}
                    {!whatsAppGroupLink && "Not Set"}
                  </FieldRow>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Section
            id="registrations"
            label={
              <Stack direction="horizontal" gap="3">
                {registrationsSummary}
                <Button variant="outline-primary" size="sm" onClick={() => handleAddRegistration()}>
                  <MdOutlineAddBox size="25" />
                </Button>
                <Button variant="outline-primary" size="sm" onClick={() => handleAddRegistrations()} disabled={isRegistrationOpen}>
                  <MdOutlineLibraryAdd size="25" />
                </Button>
              </Stack>
            }
          >
            <ListGroup>
              {registrations.map((r) => {
                return (
                  <ListGroup.Item key={r.plannedEventRegistrationId}>
                    <Stack direction="horizontal" gap="2">
                      <ButtonGroup>
                        <Button variant="outline-danger" size="sm" onClick={() => handleUnregister(r)}>
                          <IoMdCloseCircle size="25" />
                        </Button>
                        <Button
                          variant={r.checkedIn ? "primary" : "outline-primary"}
                          size="sm"
                          onClick={() => handleCheckIn(r.plannedEventRegistrationId, r.checkedIn)}
                        >
                          <IoMdDoneAll size="25" />
                        </Button>
                        {tierLabels && (
                          <Button
                            variant={r.tierId === "tier1" ? "primary" : "outline-primary"}
                            size="sm"
                            onClick={() => handleTierChange(r.plannedEventRegistrationId, "tier1")}
                          >
                            {tierLabels["tier1"]}
                          </Button>
                        )}
                        {tierLabels && (
                          <Button
                            variant={r.tierId === "tier2" ? "primary" : "outline-primary"}
                            size="sm"
                            onClick={() => handleTierChange(r.plannedEventRegistrationId, "tier2")}
                          >
                            {tierLabels["tier2"]}
                          </Button>
                        )}
                      </ButtonGroup>
                      {r.name}
                      {r.rating && ` [${r.rating}]${r.ratingNote}`}
                    </Stack>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Section>
          <Section id="messages" label="Messages:">
            <ListGroup>
              {messages.map((e, i) => {
                return (
                  <ListGroup.Item key={i} variant={getVariantForMessageType(e.type)}>
                    {e.message}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Section>
          {rules.length > 0 && (
            <Section id="rules" label="Rules:">
              <Rules rules={rules} />
            </Section>
          )}
          <Row className="mt-5 mb-5">
            <Col className="text-center">{isRegistrationOpen && <QRCode level="M" title="Scan to register" value={registrationUrl} />}</Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

function getHelpText(staticDataItems) {
  return staticDataItems.map((sdi) => (
    <p key={sdi.key}>
      <strong>{sdi.name}</strong>: {sdi.description}
    </p>
  ));
}

function HelpOverlay(props) {
  const { label, subtitle, items } = props;

  return (
    <OverlayTrigger
      placement="bottom"
      trigger="click"
      overlay={
        <Popover id="test">
          <Popover.Header as="h3">{label}</Popover.Header>
          <Popover.Body>
            {subtitle}
            <br />
            <br />
            {getHelpText(Object.values(items))}
          </Popover.Body>
        </Popover>
      }
    >
      <span>
        <MdOutlineHelp size="20" color={getPrimary()} />
      </span>
    </OverlayTrigger>
  );
}

export default withSettings(PlannedTournamentView);
