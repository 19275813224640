import { v1 as uuidv1 } from "uuid";

export function getNewId() {
  const uuid = uuidv1();

  return `${uuid.slice(15, 18)}${uuid.slice(9, 13)}${uuid.slice(0, 8)}`;
}

export function getScreenWidth() {
  return window.innerWidth && document.documentElement.clientWidth && document.body.clientWidth
    ? Math.min(window.innerWidth, document.documentElement.clientWidth, document.body.clientWidth)
    : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

export function getScreenHeight() {
  return window.innerHeight && document.documentElement.clientHeight && document.body.clientHeight
    ? Math.min(window.innerHeight, document.documentElement.clientHeight, document.body.clientHeight)
    : window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}
