import { AdminApi } from "./atsApi";

class UtilsApi {
  constructor() {
    this.api = new AdminApi("utils");
  }

  regenerate = () => this.api.postRequest("regenerate");

  remindScoreData = (scoreDataId) => this.api.postRequest(`remind-score-data/${scoreDataId}`);

  deleteScoreData = (scoreDataId) => this.api.postRequest(`delete-score-data/${scoreDataId}`);

  getUnconfirmedScores = () => this.api.getRequest("unconfirmed-scores");
}

const utilsApi = new UtilsApi();
export default utilsApi;
