import React from "react";

import Stack from "react-bootstrap/Stack";
import Table from "react-bootstrap/Table";

import { IoIosTennisball } from "react-icons/io";

import FlashChange from "@avinlab/react-flash-change";

import { getSide1Score, getSide2Score } from "../../common/formatGameScore";
import { getSideName } from "../../common/sideNameBuilder";

const ViewScore = (props) => {
  const {
    scoreInfo: {
      side1Name,
      side2Name,

      side2Serve,
      side1Set1,
      side2Set1,
      side1Set2,
      side2Set2,
      side1Set3,
      side2Set3,
      side1Game,
      side2Game,
      side1Tiebreak,
      side2Tiebreak,
    },
    viewersCount,
  } = props;

  const side1GameText = getSide1Score(side1Game, side2Game);
  const side2GameText = getSide2Score(side1Game, side2Game);

  return (
    <Table size="sm">
      <colgroup>
        <col span="1" style={{ width: "3em" }} />
        <col span="1" style={{ width: "50%" }} />
        <col span="1" style={{ width: "50%" }} />
      </colgroup>
      <thead>
        <tr>
          <th>{viewersCount && <h3>STR: {viewersCount}</h3>}</th>
          <th align="middle">
            <Stack direction="horizontal" gap="1" style={{ justifyContent: "center" }}>
              {getSideName(side1Name)}
              {!side2Serve && <IoIosTennisball size="25" color="magenta" />}
            </Stack>
          </th>
          <th align="middle">
            <Stack direction="horizontal" gap="1" style={{ justifyContent: "center" }}>
              {getSideName(side2Name)}
              {side2Serve && <IoIosTennisball size="25" color="magenta" />}
            </Stack>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th align="middle" style={{ width: "3em" }}>
            Set 1
          </th>
          <td align="middle" style={{ width: "1" }}>
            <ShowFlash value={side1Set1} />
          </td>
          <td align="middle" style={{ width: "1" }}>
            <ShowFlash value={side2Set1} />
          </td>
        </tr>
        <tr>
          <th align="middle">Set 2</th>
          <td align="middle">
            <ShowFlash value={side1Set2} />
          </td>
          <td align="middle">
            <ShowFlash value={side2Set2} />
          </td>
        </tr>
        <tr>
          <th align="middle">Set 3</th>
          <td align="middle">
            <ShowFlash value={side1Set3} />
          </td>
          <td align="middle">
            <ShowFlash value={side2Set3} />
          </td>
        </tr>
        <tr>
          <th>Game</th>
          <td align="middle">
            <ShowFlash value={side1GameText} />
          </td>
          <td align="middle">
            <ShowFlash value={side2GameText} />
          </td>
        </tr>
        <tr>
          <th>Tiebreak</th>
          <td align="middle">
            <ShowFlash value={side1Tiebreak} />
          </td>
          <td align="middle">
            <ShowFlash value={side2Tiebreak} />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

function ShowFlash(props) {
  return (
    <FlashChange
      value={props.value}
      className="ps-1 pe-1"
      flashClassName="fw-bold text-success text-white bg-success"
      flashDuration="5000"
      outerElementType="div"
    >
      <h3>{props.value}</h3>
    </FlashChange>
  );
}

export default ViewScore;
