import React from "react";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import { toast } from "react-toastify";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { autoSizeAll } from "../../common/agGridUtils";

import { withStaticData } from "../../common/staticData";

import FindOpponentApi from "../../api/findOpponentApi";

class FindPlayer extends React.Component {
  state = {
    loading: false,
    filter: {
      courtId: "",
      matchTypeId: "",
      date: "",
      time: "",
    },
    players: [],
  };

  handleChange = async (e) => {
    const filter = { ...this.state.filter };

    filter[e.currentTarget.id] = e.currentTarget.value;

    const { courtId, matchTypeId, date, time } = filter;

    this.setState({ filter });

    if (courtId && matchTypeId && date && time) {
      this.setState({ loading: true });
      await this.refresh(filter);
    }
  };

  refresh = async (filter) => {
    const { success, data: players, message } = await FindOpponentApi.findAvailablePlayers(filter);

    if (!success) {
      toast.error(message);
      this.setState({ loading: false });
      return;
    }

    this.setState({ loading: false, players });
  };

  handleAskToJoin = async (e, availablePlayerId, playerName, data) => {
    e.stopPropagation();

    const { success, message } = await FindOpponentApi.askToJoinAvailablePlayer(availablePlayerId, data);
    if (!success) {
      toast.error(message);
      return;
    }

    toast.success(`${playerName} was notified about your match and will contact you if they are available to join.`);
  };

  render() {
    const { loading, filter, players } = this.state;
    const { staticData } = this.props;

    const buttonRenderer = (props) => (
      <>
        <Button size="sm" variant="info" onClick={(e) => this.handleAskToJoin(e, props.data.availablePlayerId, props.data.playerName, filter)}>
          CAN YOU PLAY?
        </Button>
      </>
    );

    return (
      <Container fluid className="ag-theme-alpine">
        <Row>
          <Col>
            <h1>Find Opponent - Find a Player</h1>
            <br />
            {loading && <Spinner animation="border" role="status" />}
            {!loading && (
              <>
                <div className="form-group mt-3">
                  <label htmlFor="date">Date:</label>
                  <select id="date" className="form-select" value={filter.date} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select Date
                    </option>{" "}
                    {Object.keys(staticData.dates).map((d) => (
                      <option key={d} value={d}>
                        {staticData.dates[d]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="time">Time:</label>
                  <select id="time" className="form-select" value={filter.time} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select Time
                    </option>{" "}
                    {Object.keys(staticData.times).map((d) => (
                      <option key={d} value={d}>
                        {staticData.times[d]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="matchTypeId">Match type:</label>
                  <select id="matchTypeId" className="form-select" value={filter.matchTypeId} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select Match Type
                    </option>{" "}
                    {Object.keys(staticData.matchTypes)
                      .filter((s) => s !== "any")
                      .map((d) => (
                        <option key={d} value={d}>
                          {staticData.matchTypes[d]}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="courtId">Court:</label>
                  <select id="courtId" className="form-select" value={filter.courtId} onChange={this.handleChange}>
                    <option key="empty" value="">
                      Select Court
                    </option>{" "}
                    {Object.keys(staticData.courts).map((d) => (
                      <option key={d} value={d}>
                        {staticData.courts[d]}
                      </option>
                    ))}
                  </select>
                </div>
                <br />
                <AgGridReact
                  rowData={players}
                  rowStyle={{ cursor: "pointer" }}
                  domLayout="autoHeight"
                  onGridSizeChanged={autoSizeAll}
                  onFirstDataRendered={autoSizeAll}
                  frameworkComponents={{ buttonRenderer: buttonRenderer }}
                  overlayNoRowsTemplate="No players found for specified filters"
                >
                  <AgGridColumn field="playerName" headerName="Name"></AgGridColumn>
                  <AgGridColumn headerName="" cellRenderer="buttonRenderer" />
                </AgGridReact>
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStaticData(FindPlayer);
