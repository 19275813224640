import React from "react";

import { Helmet } from "react-helmet-async";

import { hasPermission, Permission } from "../../common/auth";
import { useQueryParams } from "../../common/queryParams";

import MainMenu from "../../components/mainMenu";

import CommentMatch from "./commentMatch";
import Match from "./match";
import MatchSelector from "./matchSelector";
import ParentMatch from "./parentMatch";
import UmpireMatch from "./umpireMatch";
import { LinkBuilder, Page } from "./umpireCastLinkBuilder";
import EditMatch from "./editMatch";

class UmpireCastApp extends React.Component {
  state = {};

  render() {
    const menuItems = [{ key: "scores", title: "Matches", url: LinkBuilder.getMatchesLink() }];

    return (
      <React.Fragment>
        <Helmet>
          <title>Score Cast - ATS</title>
        </Helmet>
        <MainMenu appItems={menuItems} />
        <GetPage />
      </React.Fragment>
    );
  }
}

function GetPage() {
  const queryParams = useQueryParams();

  if ("page" in queryParams) {
    if (hasPermission(Permission.UmpireCast) && queryParams.page === Page.UmpireMatch) return <UmpireMatch />;
    if (hasPermission(Permission.CommentCast) && queryParams.page === Page.CommentMatch) return <CommentMatch />;
    if (hasPermission(Permission.ParentCast) && queryParams.page === Page.ParentMatch) return <ParentMatch />;
    if (queryParams.page === Page.Match) return <Match />;
    if (queryParams.page === Page.Matches) return <MatchSelector />;
    if (queryParams.page === Page.EditMatch) return <EditMatch />;
  }

  return <MatchSelector />;
}

export default UmpireCastApp;
