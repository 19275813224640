export function getPrimary() {
  return "#11999e";
}

export function getSecondary() {
  return "#6c757d";
}

export function getSuccess() {
  return "#198754";
}

export function getInfo() {
  return "#0dcaf0";
}

export function getWarning() {
  return "#ffc107";
}

export function getDanger() {
  return "#dc3545";
}
