import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Stack from "react-bootstrap/Stack";

import { getVariantForMessageType } from "../../common/viewControls";

class AddRegistrationsModal extends React.Component {
  state = {
    file: null,
  };

  render() {
    const { file } = this.state;
    const { uploadMessages, handleUpload, handleCancel } = this.props;

    return (
      <React.Fragment>
        <Modal show={true} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
            <h1>Add Participants</h1>
          </Modal.Header>
          <Modal.Body>
            <Stack gap="3">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Bulk upload participants</Form.Label>
                <Form.Control type="file" onChange={(e) => this.setState({ file: e.target.files[0] })} />
              </Form.Group>
              <Button variant="outline-primary" onClick={() => handleUpload(file)} disabled={!file}>
                UPLOAD
              </Button>
              <ListGroup>
                {uploadMessages.map((e, i) => {
                  return (
                    <ListGroup.Item key={i} variant={getVariantForMessageType(e.type)}>
                      {e.message}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Stack>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup className="mt-5">
              <Button variant="outline-danger" onClick={handleCancel}>
                CLOSE
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default AddRegistrationsModal;
