import React from "react";

import Button from "react-bootstrap/Button";

import { toast } from "react-toastify";

import UtilsApi from "../../api/utilsApi";

class Regenerate extends React.Component {
  handleRegenerate = async () => {
    const toastId = toast.info("Regenerating ...", { autoClose: false });

    const { success, message } = await UtilsApi.regenerate();

    if (success) {
      toast.update(toastId, { render: "Portal was regenerated", autoClose: 5000, type: toast.TYPE.SUCCESS });
    } else {
      toast.update(toastId, { render: message, autoClose: 5000, type: toast.TYPE.ERROR });
    }
  };

  render() {
    return (
      <Button size="sm" variant="outline-primary" onClick={this.handleRegenerate}>
        REGENERATE
      </Button>
    );
  }
}

export default Regenerate;
