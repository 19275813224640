import { getPageLink } from "../../common/appLinkBuilder";

const appName = "join";

export const Page = {
  Find: "find",
  Join: "join",
  Request: "request",
};

class JoinLinkBuilder {
  getFindLink = (postcode, radius, courtType, page, searchId) =>
    getPageLink(appName, Page.Find, { postcode: postcode, radius: radius, courtType: courtType, page: page, searchId: searchId });
  getRequestLink = (clubId) => getPageLink(appName, Page.Request, { clubId: clubId });
  getJoinLink = (plannedEventId) => getPageLink(appName, Page.Join, { plannedEventId: plannedEventId });
}

export const LinkBuilder = new JoinLinkBuilder();
