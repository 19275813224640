import { AdminApi, PlayerApi } from "./atsApi";

class ScoreApi {
  constructor() {
    this.adminApi = new AdminApi("match");
    this.playerApi = new PlayerApi("new-score");
  }

  setScoreNew = (matchId, scoreTypeId, score) => this.playerApi.postRequest(matchId, { scoreTypeId, score });
  setScoreOld = (request) => this.playerApi.postRequest("", request);

  defaultOne = (matchId) => this.adminApi.postRequest(`default-one/${matchId}`);

  coinToss = (matchId) => this.adminApi.postRequest(`coin-toss/${matchId}`);

  lossInjury = (matchId) => this.adminApi.postRequest(`loss-injury/${matchId}`);
  lossConcede = (matchId) => this.adminApi.postRequest(`loss-concede/${matchId}`);
  lossNoShow = (matchId) => this.adminApi.postRequest(`loss-no-show/${matchId}`);

  winInjury = (matchId) => this.adminApi.postRequest(`win-injury/${matchId}`);
  winConcede = (matchId) => this.adminApi.postRequest(`win-concede/${matchId}`);
  winNoShow = (matchId) => this.adminApi.postRequest(`win-no-show/${matchId}`);
}

const scoreApi = new ScoreApi();
export default scoreApi;
