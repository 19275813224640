import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import { SelectEdit } from "../../common/editControls";

class SelectGroupModal extends React.Component {
  state = {
    group: { groupId: this.props.data.groupId },
  };

  handleChange = (e) => {
    const { group } = this.state;
    group[e.target.id] = e.target.value;
    this.setState({ group });
  };

  render() {
    const { group } = this.state;
    const {
      data: { groups, handleSubmit, handleCancel },
      show,
    } = this.props;

    groups["Unassigned"] = { id: "Unassigned", name: "Unassigned" };

    return (
      <React.Fragment>
        <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
            <h1>Select group</h1>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <Form>
                    <SelectEdit id="groupId" label="Group" items={groups} value={group.groupId} onChange={this.handleChange} />
                  </Form>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup className="mt-5">
              <Button variant="primary" onClick={() => handleSubmit(group.groupId)} disabled={!group.groupId || group.groupId === this.props.data.groupId}>
                SAVE
              </Button>
              <Button variant="danger" onClick={handleCancel}>
                CANCEL
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SelectGroupModal;
