import React from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Table from "react-bootstrap/Table";

import { IoMdHand } from "react-icons/io";

import { getDanger } from "../colours";
import { getSideNameForOption } from "../sideNameBuilder";

class BestOfThreePickle extends React.Component {
  state = {
    data: {
      score11: "",
      score12: "",
      score21: "",
      score22: "",
      score31: "",
      score32: "",
    },
    validation: {
      errors: [],
      canSave: false,
      set3Enabled: false,
    },
  };

  handleChange = (e) => {
    const { data } = this.state;

    if (isNaN(e.target.value) || e.target.value.includes(",") || e.target.value.includes(".")) return;
    data[e.target.id] = e.target.value;

    const validation = this.getCanSave(data);
    this.setState({ data, validation });
  };

  getCanSave(data) {
    const { score11, score12, score21, score22, score31, score32 } = data;

    const errors = [];

    let set1Valid = true;
    if (!score11 || !score12) {
      errors.push("Set1 score is required");
      set1Valid = false;
    } else {
      let winScore = Math.max(Number(score11), Number(score12));
      let looseScore = Math.min(Number(score11), Number(score12));
      if (winScore < 11) {
        errors.push("Set1 is a tie-break to 11 with 2 clear points");
        set1Valid = false;
      } else if (winScore > 11) {
        if (looseScore !== winScore - 2) {
          errors.push("Set1 is a tie-break to 11 with 2 clear points");
          set1Valid = false;
        }
      } else {
        if (looseScore > winScore - 2) {
          errors.push("Set1 is a tie-break to 11 with 2 clear points");
          set1Valid = false;
        }
      }
    }

    let set2Valid = true;
    if (!score21 || !score22) {
      errors.push("Set2 score is required");
      set2Valid = false;
    } else {
      let winScore = Math.max(Number(score21), Number(score22));
      let looseScore = Math.min(Number(score21), Number(score22));
      if (winScore < 11) {
        errors.push("Set2 is a tie-break to 11 with 2 clear points");
        set2Valid = false;
      } else if (winScore > 11) {
        if (looseScore !== winScore - 2) {
          errors.push("Set2 is a tie-break to 11 with 2 clear points");
          set2Valid = false;
        }
      } else {
        if (looseScore > winScore - 2) {
          errors.push("Set2 is a tie-break to 11 with 2 clear points");
          set2Valid = false;
        }
      }
    }

    let set3Enabled = false;
    if (set1Valid && set2Valid) {
      if ((Number(score11) > Number(score12) ? 1 : 0) + (Number(score21) > Number(score22) ? 1 : 0) === 1) {
        set3Enabled = true;
        if (!score31 || !score32) {
          errors.push("Set3 score is required");
        } else {
          let winScore = Math.max(Number(score31), Number(score32));
          let loseScore = Math.min(Number(score31), Number(score32));
          if (winScore < 11) {
            errors.push("Set3 is a tie-break to 11 with 2 clear points");
          } else if (winScore > 11) {
            if (loseScore !== winScore - 2) {
              errors.push("Set3 is a tie-break to 11 with 2 clear points");
            }
          } else {
            if (loseScore > winScore - 2) {
              errors.push("Set3 is a tie-break to 11 with 2 clear points");
            }
          }
        }
      }
    }

    return { errors, canSave: errors.length === 0, set3Enabled };
  }

  getScoreControl = (id, value, disabled, tabIndex) => {
    return (
      <Form.Control
        type="text"
        id={id}
        value={value}
        disabled={disabled}
        tabIndex={tabIndex}
        onChange={this.handleChange}
        htmlSize="1"
        size="sm"
        autoComplete="off"
      />
    );
  };

  render() {
    const {
      data: { score11, score12, score21, score22, score31, score32 },
      validation: { errors, canSave, set3Enabled },
    } = this.state;

    const {
      match: { side1, side2, index, dueBy },
      handleSubmit,
    } = this.props;

    let score = `${score11}-${score12} ${score21}-${score22}`;
    if (set3Enabled) score = score.concat(` ${score31}-${score32}`);

    return (
      <Table>
        <tbody>
          <tr>
            <td style={{ minWidth: "200px" }}>&nbsp;</td>
            <td className="fw-bold" width="15px">
              Set1
            </td>
            <td className="fw-bold" width="15px">
              Set2
            </td>
            <td className="fw-bold" width="15px">
              Set3
            </td>
          </tr>
          <tr>
            <td className="fw-bold">{getSideNameForOption(side1)}</td>
            <td>{this.getScoreControl("score11", score11, false, index * 7 + 1)}</td>
            <td>{this.getScoreControl("score21", score21, false, index * 7 + 3)}</td>
            <td>{this.getScoreControl("score31", score31, !set3Enabled, index * 7 + 5)}</td>
          </tr>
          <tr>
            <td className="fw-bold">{getSideNameForOption(side2)}</td>
            <td>{this.getScoreControl("score12", score12, false, index * 7 + 2)}</td>
            <td>{this.getScoreControl("score22", score22, false, index * 7 + 4)}</td>
            <td>{this.getScoreControl("score32", score32, !set3Enabled, index * 7 + 6)}</td>
          </tr>
          <tr>
            <td className="fw-bold">Due by: {new Date(dueBy).toLocaleDateString()}</td>
            <td colSpan={3} style={{ textAlign: "end" }}>
              {errors.length > 0 && (
                <OverlayTrigger
                  placement="bottom"
                  trigger="click"
                  overlay={
                    <Popover id="test">
                      <Popover.Body>
                        {errors.map((e, i) => (
                          <div key={i}>{e}</div>
                        ))}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <span>
                    <IoMdHand size="20" color={getDanger()} />
                  </span>
                </OverlayTrigger>
              )}{" "}
              <Button variant="primary" disabled={!canSave} tabIndex={index * 7 + 7} onClick={() => handleSubmit(score)}>
                SUBMIT
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default BestOfThreePickle;
