import React from "react";

import { Helmet } from "react-helmet-async";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { toast } from "react-toastify";

import CourtAlertsApi from "../../api/courtAlertsApi";
import { getAppLink, getPageLink } from "../../common/appLinkBuilder";
import { useQueryParams } from "../../common/queryParams";

import MainMenu from "../../components/mainMenu";
import NotifyCourt from "./notifyCourt";
import SetAlert from "./setAlert";

const MenuItems = [
  { key: "home", title: "Set Alert", url: getAppLink("court-alerts") },
  { key: "notify-court", title: "Notify Court", url: getPageLink("court-alerts", "notify-court") },
];

class CourtAlertsApp extends React.Component {
  state = {
    courtAlert: {
      courtSurfaceId: "Any",
      courtTypeId: "Any",
      currentState: "Draft",
      date: "",
      time: "",
      tolerance: "",
    },
    staticData: {
      courts: {},
      courtSurfaces: {},
      courtTypes: {},
      dates: {},
      times: {},
      tolerances: {},
    },
  };

  async componentDidMount() {
    const {
      success,
      data: { courtAlert, staticData },
      message,
    } = await CourtAlertsApi.getCourtAlert();

    if (!success) {
      toast.error(message);
      return;
    }

    this.setState({ courtAlert, staticData });
  }

  handleChange = (e) => {
    const courtAlert = { ...this.state.courtAlert };
    courtAlert[e.currentTarget.id] = e.currentTarget.value;
    this.setState({ courtAlert });
  };

  handleSetAlert = async () => {
    const toastId = toast.info("Setting the alert...", { autoClose: false });

    const {
      success,
      data: { courtAlert, staticData },
      message,
    } = await CourtAlertsApi.postCourtAlert(this.state.courtAlert);

    if (!success) {
      toast.update(toastId, { render: message, autoClose: 5000, type: toast.TYPE.ERROR });
    }

    toast.update(toastId, { render: "Alert is set", autoClose: 5000, type: toast.TYPE.SUCCESS });
    this.setState({ courtAlert, staticData });
  };

  render() {
    const { courtAlert, staticData } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Court Alerts - ATS</title>
        </Helmet>
        <MainMenu appItems={MenuItems} />
        <Container fluid="md">
          <Row>
            <Col>
              <GetPage courtAlert={courtAlert} staticData={staticData} onChange={this.handleChange} onSetAlert={this.handleSetAlert} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

function GetPage(props) {
  const queryParams = useQueryParams();
  const { courtAlert, staticData, onChange, onSetAlert } = props;

  if ("page" in queryParams) {
    if (queryParams.page === "notify-court") return <NotifyCourt staticData={staticData} />;
  }

  return <SetAlert courtAlert={courtAlert} staticData={staticData} onChange={onChange} onSetAlert={onSetAlert} />;
}

export default CourtAlertsApp;
