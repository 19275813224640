import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { toast } from "react-toastify";

import ScoreCastApi from "../../api/scoreCastApi";

import ProcessingModal from "../../common/processingModal";
import { DateEdit, SelectEdit, TextEdit } from "../../common/editControls";
import { withQueryParams } from "../../common/queryParams";

import { LinkBuilder } from "./umpireCastLinkBuilder";
import { withSettings } from "../../common/withSettings";

class EditMatch extends React.Component {
  state = {
    loading: true,
    processing: false,

    match: {
      matchStreamId: this.props.queryParams.id,
      eventName: "",
      side1Name: "",
      side2Name: "",
      startDate: "",
      startTime: "",
      isPrivate: "false",
    },
  };

  async componentDidMount() {
    const { matchStreamId } = this.state.match;

    if (!matchStreamId) {
      this.setState({ loading: false });
      return;
    }

    this.setState({ processing: true });

    const {
      success,
      data: { eventName, side1Name, side2Name, startDate, startTime, isPrivate },
      message,
    } = await ScoreCastApi.getOneEdit(matchStreamId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({
      processing: false,

      match: {
        matchStreamId,
        eventName,
        side1Name,
        side2Name,
        startDate,
        startTime,
        isPrivate,
      },
    });
  }

  handleChange = (e) => {
    const { match } = this.state;
    match[e.target.id] = e.target.value;
    this.setState({ match });
  };

  handleSubmit = async (match) => {
    this.setState({ processing: true });

    const { success, message } = await ScoreCastApi.setOne(match);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    window.location.replace(LinkBuilder.getMatchesLink());
  };

  handleCancel = () => {
    window.location.replace(LinkBuilder.getMatchesLink());
  };

  render() {
    const {
      processing,
      match: { eventName, side1Name, side2Name, startDate, startTime, isPrivate },
    } = this.state;
    const { staticData } = this.props.settings;

    return (
      <React.Fragment>
        {processing && <ProcessingModal show={true} />}
        <Container>
          <Row>
            <Col>
              <Form>
                <TextEdit id="eventName" label="Event Name" value={eventName} onChange={this.handleChange} />
                <TextEdit id="side1Name" label="Side1 Name" value={side1Name} onChange={this.handleChange} />
                <TextEdit id="side2Name" label="Side2 Name" value={side2Name} onChange={this.handleChange} />
                <DateEdit id="startDate" label="Date" value={startDate} onChange={this.handleChange} />
                <SelectEdit id="startTime" label="Time" items={staticData.times} value={startTime} onChange={this.handleChange} />
                <SelectEdit id="isPrivate" label="Visibility" items={staticData.matchVisibilities} value={isPrivate} onChange={this.handleChange} />
                <ButtonGroup className="mt-5">
                  <Button
                    variant="outline-primary"
                    onClick={() => this.handleSubmit(this.state.match)}
                    disabled={!eventName || !side1Name || !side2Name || !startDate || !startTime}
                  >
                    SAVE
                  </Button>
                  <Button variant="outline-danger" onClick={this.handleCancel}>
                    CANCEL
                  </Button>
                </ButtonGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withSettings(withQueryParams(EditMatch));
