import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/esm/Stack";

import _ from "lodash";

import { toast } from "react-toastify";

import EventsApi from "../../api/eventsApi";
import PlannedLeagueApi from "../../api/plannedLeagueApi";
import ProcessingModal from "../../common/processingModal";

import { EventSeriesSinglesLeague, EventSeriesDoublesLeague } from "../../common/eventSeries";

import { getPlannedLeagueLink, getLeagueLink } from "./adminLinkBuilder";

class LeagueSelector extends React.Component {
  state = {
    processing: false,
    isLoading: true,
    leagues: [],
  };

  async componentDidMount() {
    const { success, data: leagues, message } = await EventsApi.getAllLeagues();

    if (!success) {
      this.setState({ isLoading: false });
      toast.error(message);
      return;
    }

    this.setState({ isLoading: false, leagues });
  }

  handleCopy = async (plannedLeagueId) => {
    this.setState({ processing: true });

    let { leagues } = this.state;

    let { success, message } = await PlannedLeagueApi.copy(plannedLeagueId);
    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    ({ success, data: leagues, message } = await EventsApi.getAllLeagues());
    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({ leagues, processing: false });
    toast.success("League copied");
  };

  render() {
    const leagues = _.chain(this.state.leagues).orderBy(["startDate", "desc"]).value();

    return (
      <React.Fragment>
        {this.state.processing && <ProcessingModal show={true} />}
        <Container fluid="md">
          <Row>
            <Col>
              <h1>
                Leagues:{" "}
                <ButtonGroup>
                  <Button variant="outline-primary" href={getPlannedLeagueLink(null, EventSeriesSinglesLeague)}>
                    New Singles
                  </Button>
                  <Button variant="outline-primary" href={getPlannedLeagueLink(null, EventSeriesDoublesLeague)}>
                    New Doubles
                  </Button>
                </ButtonGroup>
              </h1>
              {this.state.isLoading && (
                <div className="spinner-border text-success" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              <ListGroup>
                {leagues.map((league) => {
                  return (
                    <ListGroup.Item action key={league.eventId ?? league.plannedEventId} as="div" variant={getVariantForState(league.state)}>
                      <Stack direction="horizontal" gap="2">
                        <Button variant="outline-primary" size="sm" onClick={(e) => this.handleCopy(league.plannedEventId)}>
                          COPY
                        </Button>
                        <Nav.Link href={getLeagueNavLink(league)}>
                          {getPrefixForState(league.state)}
                          {league.name}
                        </Nav.Link>
                      </Stack>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

function getLeagueNavLink(league) {
  if (!league.eventId) {
    return getPlannedLeagueLink(league.plannedEventId, league.eventSeriesId);
  }

  return getLeagueLink(league.name, league.eventId, league.plannedEventId);
}

function getVariantForState(type) {
  switch (type) {
    case "Active":
      return "";
    case "Complete":
      return "secondary";
    case "Draft":
    case "RegistrationClosed":
    case "RegistrationOpen":
      return "primary";
    default:
      return "";
  }
}

function getPrefixForState(type) {
  switch (type) {
    case "Draft":
    case "RegistrationClosed":
    case "RegistrationOpen":
      return "Project - ";
    case "Active":
      return "Active - ";
    case "Complete":
      return "Complete - ";
    default:
      return "";
  }
}

export default LeagueSelector;
