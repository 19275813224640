import { getPageLink } from "../../common/appLinkBuilder";

const appName = "rally-record";

export const Page = {
  Matches: "matches",
  Match: "match",
  MatchEdit: "match-edit",

  Children: "chidlren",
  ChildEdit: "child-edit",

  Analytics: "analytics",
};

class RallyRecordLinkBuilder {
  getMatchesLink = (childId) => getPageLink(appName, Page.Matches, { childId: childId });
  getMatchLink = (matchId) => getPageLink(appName, Page.Match, { id: matchId });
  getAnalyticsLink = (childId) => getPageLink(appName, Page.Analytics, { childId });
  getEditMatchLink = (matchId) => getPageLink(appName, Page.MatchEdit, { id: matchId });
}

export const LinkBuilder = new RallyRecordLinkBuilder();
