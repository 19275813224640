import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Stack from "react-bootstrap/Stack";

import { getVariantForMessageType } from "../../common/viewControls";
import { TextEdit } from "../../common/editControls";
import { withSettings } from "../../common/withSettings";

class AddRegistrationModal extends React.Component {
  state = {
    name: "",
  };

  handleChange = (e) => {
    this.setState({ name: e.target.value });
  };

  render() {
    const { name } = this.state;
    const { uploadMessages, handleUpload, handleCancel, helpText } = this.props;

    return (
      <React.Fragment>
        <Modal show={true} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
            <h1>Add Participant</h1>
          </Modal.Header>
          <Modal.Body>
            <Stack gap="3">
              <Form.Group controlId="formFile" className="mb-3">
                <TextEdit id="name" label="Name" helpText={helpText} value={name} onChange={this.handleChange} />
              </Form.Group>
              <Button
                variant="outline-primary"
                onClick={() => {
                  this.setState({ name: "" });
                  handleUpload(name);
                }}
                disabled={!name}
              >
                REGISTER
              </Button>
              <ListGroup>
                {uploadMessages.map((e, i) => {
                  return (
                    <ListGroup.Item key={i} variant={getVariantForMessageType(e.type)}>
                      {e.message}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Stack>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup className="mt-5">
              <Button variant="outline-danger" onClick={handleCancel}>
                CLOSE
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withSettings(AddRegistrationModal);
