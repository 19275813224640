import React, { useContext } from "react";
import StaticDataContext from "../contexts/staticDataContext";

export function withStaticData(Component) {
  return (props) => <Component {...props} staticData={useContext(StaticDataContext)} />;
}

export function useStaticData() {
  return useContext(StaticDataContext);
}
