import React from "react";

import { Helmet } from "react-helmet-async";

import { useQueryParams } from "../../common/queryParams";

import MatchStream from "./matchStream";
import TournamentStream from "./tournamentStream";

class ClubStreamApp extends React.Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Club Stream - ATS</title>
        </Helmet>
        <GetPage />
      </React.Fragment>
    );
  }
}

function GetPage() {
  const queryParams = useQueryParams();

  if ("page" in queryParams) {
    if (queryParams.page === "match-stream") return <MatchStream id={queryParams.id} />;
    if (queryParams.page === "tournament-stream") return <TournamentStream id={queryParams.id} />;
  }

  return <></>;
}

export default ClubStreamApp;
