import { PlayerApi } from "./atsApi";

class AuxInfoApi {
  constructor() {
    this.api = new PlayerApi("aux-info");
  }

  getInstanceSettings = () => this.api.getRequest(`instance-settings${window.location.search}`);
}

const auxInfoApi = new AuxInfoApi();
export default auxInfoApi;
