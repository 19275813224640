import { S3Api } from "./atsApi";

class DataApi {
  constructor() {
    this.api = new S3Api();
  }

  getMyMatchesData = () => this.api.getRequest(`players/${localStorage.getItem("atsMyPage")}/data.json?id=${new Date().getTime()}`);
  getWrappedData = (year, playerId) => this.api.getRequest(`wrapped${year}/${playerId}.json?id=${new Date().getTime()}`);
}

const dataApi = new DataApi();
export default dataApi;
