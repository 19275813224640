import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Pagination from "react-bootstrap/Pagination";

import { toast } from "react-toastify";

import joinApi from "../../api/joinApi";

import ProcessingModal from "../../common/processingModal";
import { SelectEdit, TextEdit } from "../../common/editControls";
import SearchResult from "./searchResult";
import { LinkBuilder } from "./joinLinkBuilder";
import { Helmet } from "react-helmet-async";

class Find extends React.Component {
  state = {
    processing: false,
    searchData: {
      postcode: this.props.postcode ?? "",
      radius: this.props.radius ?? 3,
      courtType: this.props.courtType ?? 0,
      searchId: this.props.searchId ?? "",
    },
    searchResults: [],
    activeLeagues: [],
    page: 0,
    totalPages: 0,
    totalResults: 0,
  };

  handleSearchDataChange = (e, autoRefresh) => {
    const searchData = { ...this.state.searchData };
    searchData[e.currentTarget.id] = e.currentTarget.value;
    this.setState({ searchData });
    if (autoRefresh && searchData.postcode) this.findByPostcode(undefined, 0, searchData);
  };

  componentDidMount() {
    if (this.state.searchData.postcode) this.findByPostcode(undefined, 0);
    else this.showActive();
  }

  showActive = async () => {
    this.setState({ processing: true });

    const { success, data, status, message } = await joinApi.getActive(this.state.searchData.searchId);
    if (!success && status !== 404) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    this.setState({
      processing: false,
      activeLeagues: data.activeLeagues,
    });
  };

  findByPostcode = async (e, page, searchData) => {
    if (e !== undefined) e.preventDefault();

    this.setState({ processing: true });

    const { postcode, radius, courtType, searchId } = searchData ?? this.state.searchData;

    const { success, data, status, message } = await joinApi.findByPostcode(postcode, radius, courtType, page, searchId);
    if (!success && status !== 404) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    window.history.pushState({}, this.getTitle(), LinkBuilder.getFindLink(postcode, radius, courtType, page, searchId));

    this.setState({
      processing: false,
      searchResults: data.currentPageResults,
      activeLeagues: [],
      page: data.currentPage,
      totalPages: data.totalPages,
      totalResults: data.totalResults,
    });
  };

  getTitle = (postcode) => {
    return `Find a league in your tennis club ${postcode ? ` - ${postcode}` : ""}`;
  };

  render() {
    const {
      processing,
      searchData: { postcode, radius, courtType },
      page,
      searchResults,
      activeLeagues,
      totalPages,
      totalResults,
    } = this.state;

    var prevPageDisabled = page === 0;
    var nextPageDisabled = page === totalPages - 1;

    return (
      <React.Fragment>
        <Helmet>
          <title>{this.getTitle(postcode)}</title>
        </Helmet>
        <ProcessingModal show={processing} text="Searching..." />
        <Container fluid="md">
          <Form onSubmit={(e) => this.findByPostcode(e, 0)}>
            <Row>
              <Col>
                <h1>{this.getTitle()}</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextEdit
                  id="postcode"
                  label=""
                  placeholder="Postcode"
                  value={postcode}
                  helpText="Enter a postcode to find your club"
                  onChange={(e) => this.handleSearchDataChange(e, false)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectEdit
                  id="radius"
                  label="Distance"
                  value={radius}
                  items={{ 1: "1 mile", 3: "3 miles", 10: "10 miles" }}
                  onChange={(e) => this.handleSearchDataChange(e, true)}
                />
              </Col>
              <Col>
                <SelectEdit
                  id="courtType"
                  label="Courts"
                  value={courtType}
                  items={{ 0: "Any", 1: "Floodlit", 2: "Indoor" }}
                  onChange={(e) => this.handleSearchDataChange(e, true)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button className="mt-3" variant="primary" size="lg" type="submit" disabled={!postcode}>
                  Find
                </Button>
              </Col>
            </Row>
          </Form>
          {activeLeagues.length > 0 && (
            <Row className="mt-3">
              <Col>
                <h2>Leagues starting soon:</h2>
                <ListGroup>
                  {activeLeagues.map((activeLeague) => (
                    <ListGroupItem key={activeLeague.clubId}>
                      <SearchResult data={activeLeague} />
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          )}
          {searchResults.length > 0 && (
            <Row className="mt-3 pt-2 align-items-center sticky-top bg-white">
              <Col>
                <h5>Found {totalResults} Clubs</h5>
              </Col>
              {totalPages > 1 && (
                <Col>
                  <Pagination className="justify-content-end">
                    <Pagination.First disabled={prevPageDisabled} onClick={(e) => this.findByPostcode(e, 0)} />
                    <Pagination.Prev disabled={prevPageDisabled} onClick={(e) => this.findByPostcode(e, page - 1)} />
                    <Pagination.Item active>
                      {page + 1}&nbsp;/&nbsp;{totalPages}
                    </Pagination.Item>
                    <Pagination.Next disabled={nextPageDisabled} onClick={(e) => this.findByPostcode(e, page + 1)} />
                    <Pagination.Last disabled={nextPageDisabled} onClick={(e) => this.findByPostcode(e, totalPages - 1)} />
                  </Pagination>
                </Col>
              )}
            </Row>
          )}
          <Row className="mt-3">
            <Col>
              <ListGroup>
                {searchResults.map((searchResult) => (
                  <ListGroupItem key={searchResult.clubId}>
                    <SearchResult data={searchResult} />
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Find;
