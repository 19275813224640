import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Stack from "react-bootstrap/Stack";

class Shot extends React.Component {
  state = {
    hand: "",
    type: "",
    placement: "",
    error: "",
  };

  setHand = (hand) => {
    this.setState({ hand });
  };

  setType = (type) => {
    this.setState({ type });
  };

  setPlacement = (placement) => {
    this.setState({ placement });
  };

  setError = (error) => {
    this.setState({ error });
  };

  handleShotClick = async (outcome) => {
    const { onShotClick } = this.props;
    const { hand, type, placement, error } = this.state;

    await onShotClick({ hand: hand || "unknown", type: type || "unknown", placement: placement || "unknown", error: error || "unknown", outcome });
    this.setState({ hand: "", type: "", placement: "", error: "" });
  };

  render() {
    const { disabled } = this.props;
    const { hand, type, placement, error } = this.state;

    return (
      <Stack directon="vertical" gap="1">
        <ButtonGroup>
          <Button disabled={disabled} variant={hand === "forehand" ? "secondary" : "outline-secondary"} onClick={() => this.setHand("forehand")}>
            Forehand
          </Button>
          <Button disabled={disabled} variant={hand === "backhand" ? "secondary" : "outline-secondary"} onClick={() => this.setHand("backhand")}>
            Backhand
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button disabled={disabled} variant={type === "slice" ? "warning" : "outline-warning"} onClick={() => this.setType("slice")}>
            Slice
          </Button>
          <Button disabled={disabled} variant={type === "drop" ? "warning" : "outline-warning"} onClick={() => this.setType("dropshot")}>
            Drop
          </Button>
          <Button disabled={disabled} variant={type === "volley" ? "warning" : "outline-warning"} onClick={() => this.setType("volley")}>
            Volley
          </Button>
          <Button disabled={disabled} variant={type === "lob" ? "warning" : "outline-warning"} onClick={() => this.setType("lob")}>
            Lob
          </Button>
          <Button disabled={disabled} variant={type === "topspin" ? "warning" : "outline-warning"} onClick={() => this.setType("topspin")}>
            Top
          </Button>
          <Button disabled={disabled} variant={type === "flat" ? "warning" : "outline-warning"} onClick={() => this.setType("flat")}>
            Flat
          </Button>
          <Button disabled={disabled} variant={type === "smash" ? "warning" : "outline-warning"} onClick={() => this.setType("smash")}>
            Smash
          </Button>
          <Button disabled={disabled} variant={type === "approach" ? "warning" : "outline-warning"} onClick={() => this.setType("approach")}>
            Appr
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button disabled={disabled} variant={placement === "crosscourt" ? "secondary" : "outline-secondary"} onClick={() => this.setPlacement("crosscourt")}>
            Cross Court
          </Button>
          <Button disabled={disabled} variant={placement === "downmiddle" ? "secondary" : "outline-secondary"} onClick={() => this.setPlacement("downmiddle")}>
            Down The Middle
          </Button>
          <Button disabled={disabled} variant={placement === "downline" ? "secondary" : "outline-secondary"} onClick={() => this.setPlacement("downline")}>
            Down The Line
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button disabled={disabled} variant={error === "forced" ? "warning" : "outline-warning"} onClick={() => this.setError("forced")}>
            Forced error
          </Button>
          <Button disabled={disabled} variant={error === "unforced" ? "warning" : "outline-warning"} onClick={() => this.setError("unforced")}>
            Unforced error
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button disabled={disabled} variant="outline-danger" onClick={() => this.handleShotClick("net")}>
            Net
          </Button>
          <Button disabled={disabled} variant="outline-danger" onClick={() => this.handleShotClick("outwide")}>
            Wide
          </Button>
          <Button disabled={disabled} variant="outline-danger" onClick={() => this.handleShotClick("outlong")}>
            Long
          </Button>
          <Button disabled={disabled} variant="outline-success" onClick={() => this.handleShotClick("winner")}>
            Winner
          </Button>
        </ButtonGroup>
      </Stack>
    );
  }
}

export default Shot;
